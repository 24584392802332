import React from "react";
// Customizable Area Start
import ContentFlagMenuController, { Props } from "./ContentFlagMenuController.web";
import { Box, Button, Divider, IconButton, styled, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { BlockIcon, ReportIcon, StarIcon } from "../assets";
import { CommonButton, ResolveCondition } from "../../../../blocks/utilities/src/ResolveCondition.web";
import { sideArrowGrey } from "../../../../blocks/settings2/src/assets";
import ReportContent from "./ReportContent.web";
import ConfirmBlockModal from "../../../../blocks/blockedusers/src/components/ConfirmBlockModal.web";

type TOption = "REPORT" | "BLOCK" | "DEFAULT"

const Menus = [
    {
        title: "Report Content",
        icon: ReportIcon,
        value: "REPORT"
    },
    {
        title: "Block Account",
        icon: BlockIcon,
        value: "BLOCK"
    }
]
const Title: Record<"REPORT" | "BLOCK", string> = {
    "REPORT": "Report Content",
    "BLOCK": "Block Account"
}
// Customizable Area End

export default class ContentFlagMenu extends ContentFlagMenuController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderTitle = () => {
        return (
            <>
                {this.state.currentOption !== "DEFAULT"
                    ? (<>
                        <TitleBox>
                            {Title[this.state.currentOption]}
                        </TitleBox>
                        <Box flex={1} /></>)
                    : <></>
                }
            </>
        )
    }
    renderContent = () => {
        const { currentOption } = this.state

        switch (currentOption) {
            case "REPORT":
                return this.renderReportUI()
            case "DEFAULT":
                return this.renderDefaultUI()
        }
    }

    renderReportUI = () => {
        return (
            <>
                <ReportContent
                    handleClose={this.props.handleCloseMenu}
                    data-testid="report-content"
                />
            </>
        )
    }
    renderBlockUI = () => {
        return (
            <>
                <ConfirmBlockModal
                    open={this.state.openBlockModal}
                    handleClose={this.closeBlockModal}
                    data-testid="block-account"
                />
            </>
        )
    }
    renderDefaultUI = () => {
        return (
            <ContentBox>
                <ContentWrapper>
                    <FavouriteBtn
                        label="Favorite"
                        leftIcon={<img src={StarIcon} />}
                    />
                    <MenuBox>
                        {Menus.map((item, index) => {
                            const borderStyle = ResolveCondition(Menus.length == index + 1, "", "1px solid #E4E6EA")
                            return (
                                <StyledMenu onClick={() => this.setOption(item.value as TOption)}
                                    sx={{ borderBottom: borderStyle }}
                                    data-testid="menu-item"
                                    key={index}
                                >
                                    <Box display={"flex"} flexDirection={"row"} gap={"9px"} alignItems={"center"}>
                                        <img src={item.icon} className="menu-icon" />
                                        <Typography className="menu-font">{item.title}</Typography>
                                    </Box>
                                    <IconButton className="icon-button">
                                        <img src={sideArrowGrey} />
                                    </IconButton>
                                </StyledMenu>
                            )
                        })}
                    </MenuBox>
                </ContentWrapper>
            </ContentBox>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            <>
                <SlidingBox data-testid="main-wrapper" sx={{ maxWidth: "372px" }}>
                    <Box>
                        <PullerBox>
                            <StyledPuller />
                        </PullerBox>
                        <CloseBtnBox>
                            <StyledCloseBtn disableRipple
                                onClick={this.props.handleCloseMenu}
                                data-testid="close-button"
                            >
                                <Close className="styled-close-icon" />
                                Cancel
                            </StyledCloseBtn>
                            {this.renderTitle()}
                        </CloseBtnBox>
                        <StyledHR />
                    </Box>
                    {this.renderContent()}
                    {this.renderBlockUI()}
                </SlidingBox>
            </>
        )
    }
}
// Customizable Area Start
const SlidingBox = styled(Box)({
    zIndex: "200",
    position: "absolute",
    bottom: 0,
    left: 0,
    backgroundColor: "#F4F4F4",
    width: "100%",
    
    boxShadow: "0px -4px 24px 0px #AEAEC066",
    border: "2px solid rgba(113, 124, 144, 0.1)",
    borderRadius: "24px 24px 0 0",
    transform: "translateY(0)",
    transition: "transform 0.3s ease-in-out",
});
const CloseBtnBox = styled(Box)({
    padding: "0 8px 16px 8px",
    display: "flex"
})
const PullerBox = styled(Box)({
    padding: "8px 0",
    display: "flex",
    justifyContent: "center"
})
const StyledPuller = styled(Divider)({
    height: "2px",
    width: "48px",
    backgroundColor: "#717C90",
    opacity: 0.25,
    borderRadius: "32px"
})
const StyledCloseBtn = styled(Button)({
    flex: "1",
    justifyContent: "start",
    padding: 0,
    textTransform: "none",
    minWidth: "unset",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "16px",
    fontFamily: "roobert-regular",
    color: "#717C90",
    "&:hover": {
        background: "none !important"
    },
    "& .styled-close-icon": {
        height: "16px",
        width: "16px",
        color: "#717C90",
        marginRight: "4px"
    }
})
const TitleBox = styled(Box)({
    flex: 1,
    textAlign: "center",
    fontWeight: 600,
    fontSize: "16px",
    fontFamily: "roobert-regular",
    color: "#717C90",
})
const StyledHR = styled(Divider)({
    borderColor: "#E4E6EA",
})
const ContentBox = styled(Box)({
    display: "flex",
    justifyContent: "center",
    margin: "16px 0 40px 0",
    padding: "0 16px"
})
const ContentWrapper = styled(Box)({
    maxWidth: "279px",
    width: "100%"
})
const FavouriteBtn = styled(CommonButton)({
    marginBottom: "20px",
    padding: "0 16px",
    width: "100%",
    height: "48px",
    backgroundColor: "#F4F4F4",
    color: "#2F2F2F",
    borderRadius: "24px",
    boxShadow: "2.18px 2.18px 6.54px 0px #AEAEC066, -2.18px -2.18px 6.54px 0px #FFFFFF",
    textTransform: 'none',
    fontWeight: '600',
    fontSize: "16px",
    lineHeight: '100%',
    fontFamily: "roobert-regular",
    "&:hover": {
        backgroundColor: "#F4F4F4",
        color: "#2F2F2F",
    }
})
const MenuBox = styled(Box)({
    boxShadow: "2.18px 2.18px 6.54px 0px #AEAEC066 inset, -2.18px -2.18px 6.54px 0px #FFFFFF inset",
    borderRadius: "24px",
    display: "flex",
    flexDirection: "column",
    padding: "0 16px"
})
const StyledMenu = styled(Box)({
    cursor: "pointer",
    padding: "16px 0",
    display: "flex",
    justifyContent: "space-between",
    "& .menu-icon": {
        height: "13px",
        width: "13px",
    },
    "& .menu-font": {
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "16px",
        fontFamily: "roobert-regular",
        color: "#717C90",
    },
    "& .icon-button": {
        padding: 0,
        marginRight: "-4px",
    }
})
// Customizable Area End
