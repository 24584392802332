Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.acceptAll = "/";
exports.getReasonApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.postFlagAPiMethodType = "POST";
exports.contentFlagApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ContentFlag";
exports.labelBodyText = "ContentFlag Body";
exports.emptyLableError = "Select Valid reason";
exports.successFlagMessage = "Post Flagged Sucessfully";
exports.errorFlagMessage = "Unable to Flag the content Please Try Again";
exports.emptyReasonMessage = "No Reason Found";
exports.getPostsApiEndPoint = "bx_block_posts/posts";
exports.getAllFlagReasonsEndPoint = "bx_block_contentflag/flag_reasons";
exports.flagPostEndPoint = "bx_block_contentflag/contents";
exports.btnExampleTitle = "CLICK ME";

exports.REPORT_OPTIONS = [
  {
    title: "Hate Speech",
    value: "Hate Speech"
  },
  {
    title: "Harassment",
    value: "Harassment"
  },
  {
    title: "Misinformation",
    value: "Misinformation"
  },
  {
    title: "Nudity or explicit content",
    value: "Nudity or explicit content"
  },
  {
    title: "Spam or fraudulent content",
    value: "Spam or fraudulent content"
  },
  {
    title: "Other",
    value: "Other"
  }
]

exports.ENUM = {
  REPORT_MODAL_TITLE: "Report Content",
  SUCCESS_REPORT_MODAL_MSG: "Thank you for your report. Our team will review this content",
  SUCCESSFUL: "Successful!"
}
// Customizable Area End