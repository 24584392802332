export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgVisbility = require("../assets/visibility.svg");
export const imgVisbilityOff = require("../assets/visibilityOff.svg");

export const Logo = require("../assets/header_logo.svg").default;

export const homeBlack = require("../assets/home_black.svg").default;
export const homeOrange = require("../assets/home_orange.svg").default;
export const discoverBlack = require("../assets/discover_black.svg").default;
export const discoverOrange = require("../assets/discover_orange.svg").default;
export const settingBlack = require("../assets/setting_black.svg").default;
export const settingOrange = require("../assets/setting_orange.svg").default;
export const sidebarQR = require("../assets/sidebar_qr.svg").default;


export const accountBlack = require("../assets/account_black.svg").default;
export const accountGrey = require("../assets/account_grey.svg").default;
export const notificationsBlack = require("../assets/notifications_black.svg").default;
export const notificationsGrey = require("../assets/notifications_grey.svg").default;

export const blockGrey = require("../assets/block_grey.svg").default;
export const blockBlack = require("../assets/block_black.svg").default;

export const faqBlack = require("../assets/faq_black.svg").default;
export const faqGrey = require("../assets/faq_grey.svg").default;
export const contactUsBlack = require("../assets/contactus_black.svg").default;
export const contactUsGrey = require("../assets/contactus_grey.svg").default;
export const termsPolicyBlack = require("../assets/terms_policy_black.svg").default;
export const termsPolicyGrey = require("../assets/terms_policy_grey.svg").default;
export const sideArrowBlack = require("../assets/sidearrow_black.svg").default;
export const sideArrowGrey = require("../assets/sidearrow_grey.svg").default;

export const logoutBlack = require("../assets/logout_black.svg").default;
export const seeYouSoon = require("../assets/see_you_soon_label.svg").default;