import React from "react";
// Customizable Area Start
import AccountSettingsController, { Props, configJSON } from "./AccountSettingsController.web";
import Sidebar from "../../../settings2/src/components/Sidebar.web";
import { checkLogin, CommonButton, CustomAlert, ScreenTitleBox } from "../../../../blocks/utilities/src/ResolveCondition.web";
import { Box, InputAdornment, styled, TextField, Typography } from '@mui/material';
import { checkBlack, checkGrey, crossRed, deleteOrange } from "../assets";
import { Formik, Form } from 'formik';
import * as Yup from "yup";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ChangePassword from "./ChangePassword.web";
const outsetShadow = "2.18px 2.18px 6.54px 0px #AEAEC066,-2.18px -2.18px 6.54px 0px #FFFFFF"

const validationSchema = Yup.object().shape({
    first_name: Yup.string().trim().required("First name is required"),
    last_name: Yup.string(),
    username: Yup.string().trim().required("Username is required"),
    password: Yup.string().trim(),
    birth_date: Yup.string().trim().required("Birth date is required"),
    email: Yup.string().required("Email is required").email("Invalid email format"),
});
// Customizable Area End

export default class AccountSettings extends AccountSettingsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderCustomError = (errorMsg: string | undefined) => {
        return (
            <>
                {errorMsg ?
                    <ErrorBox display={"flex"} justifyContent={"space-between"} gap={"4px"}>
                        <Typography className="error-font">{errorMsg}</Typography>
                        <CrossRedImg src={crossRed} />
                    </ErrorBox> : <></>}
            </>
        )
    }

    renderCustomMessage = (helperMsg: string) => {
        return (
            <ErrorBox display={"flex"} justifyContent={"space-between"} gap={"13px"}>
                <Typography className="helper-font">{helperMsg}</Typography>
                <img src={checkGrey} />
            </ErrorBox>
        )
    }
    renderUserNameError = (formError: string | undefined, newValue: string) => {
        if (formError) {
            return this.renderCustomError(formError)
        }
        if (this.state.isUsernameValid === false) {
            return this.renderCustomError("Looks like that username is already in use. How about trying something else?")
        }
        if (this.state.isUsernameValid === true && newValue !== this.state.formValues.username) {
            return this.renderCustomMessage("This Username is available")
        }
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        checkLogin(this.props)
        // Customizable Area End
        return (
            <>
                <Sidebar props={this.props} selectedScreen={"AccountSettings"} showSettingsSidebar>
                    <ScreenTitleBox title={configJSON.ENUM.ACCOUNT_SETTINGS_SCREEN_TITLE} />
                    {this.state.showChangePasswordPage ? 
                        <ChangePassword
                            id="ChangePassword"
                            navigation={this.props.navigation}
                            goToScreen={this.goToScreen}
                            handleClose={this.closeChangePasswordPage}
                        /> : 
                    <Wrapper>
                        <CenterBox>
                            <FormBox>
                                <Formik
                                    initialValues={this.state.formValues}
                                    onSubmit={(values) => this.updateAccountDetails(values)}
                                    validationSchema={validationSchema}
                                    enableReinitialize
                                >
                                    {({ values, errors, handleChange, setFieldValue, dirty }) => {
                                        return (
                                            <>
                                                <Form>
                                                    <Box>
                                                        <SectionFont>Name</SectionFont>
                                                        <CustomTextField
                                                            autoComplete="off"
                                                            label="First Name"
                                                            name="first_name"
                                                            value={values.first_name}
                                                            onChange={handleChange}
                                                        />
                                                        {this.renderCustomError(errors?.first_name)}
                                                        <CustomTextField
                                                            autoComplete="off"
                                                            label="Last Name (optional)"
                                                            name="last_name"
                                                            value={values.last_name}
                                                            onChange={handleChange}
                                                        />
                                                        {this.renderCustomError(errors?.last_name)}
                                                    </Box>
                                                    <Box marginTop={"24px"}>
                                                        <SectionFont>Log In Info</SectionFont>
                                                        <CustomTextField
                                                            autoComplete="off"
                                                            label="Username"
                                                            name="username"
                                                            value={values.username}
                                                            onChange={(event) => {
                                                                setFieldValue("username", event.target.value.trim())
                                                                this.validateUsername(event.target.value.trim())
                                                            }}
                                                            inputProps={{ maxLength: 30 }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <CharLengthFont>{`${values.username.length}/${30}`}</CharLengthFont>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            data-testid="username-field"
                                                        />
                                                        {this.renderUserNameError(errors?.username, values.username)}

                                                        <CustomTextField
                                                            disabled
                                                            label="Password"
                                                            name="password"
                                                            value={values.password}
                                                            type={"password"}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <ChangeFont onClick={this.openChangePasswordPage} data-testid="change-password">
                                                                            Change
                                                                        </ChangeFont>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            data-testid="change-password-field"
                                                        />
                                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                                            <DatePicker
                                                                data-testid="birthdate-field"
                                                                showToolbar={false}
                                                                closeOnSelect
                                                                views={['year', 'month', 'day']}
                                                                inputFormat="DD MMMM YYYY"
                                                                value={values.birth_date}
                                                                onChange={(newDate) => setFieldValue("birth_date", moment(newDate).format("YYYY-MM-DD"))}
                                                                maxDate={moment()}
                                                                renderInput={(params) =>
                                                                    <CustomTextField {...params} placeholder="Date of birth" error={false}
                                                                        className="date-picker-input" />
                                                                }
                                                            />
                                                        </LocalizationProvider>
                                                    </Box>
                                                    <Box marginTop={"24px"}>
                                                        <SectionFont>Contact Info</SectionFont>
                                                        <CustomTextField
                                                            label="Email"
                                                            name="email"
                                                            value={values.email}
                                                            onChange={handleChange}
                                                            disabled
                                                        />
                                                        {this.renderCustomError(errors?.email)}
                                                    </Box>
                                                    <Box display={"flex"} flexDirection={"column"} gap={"16px"} marginTop={"59px"}>
                                                        {dirty &&
                                                            <SaveButton
                                                                isLoading={this.state.updatedApiLoading}
                                                                loadingStyle={loadingStyle}
                                                                label="Save Changes"
                                                                rightIcon={<img src={checkBlack} />}
                                                                type="submit"
                                                            />
                                                        }

                                                        <DeleteAccountButton
                                                            onClick={() => this.goToScreen("DeleteAccount")}
                                                            label="Delete Account"
                                                            rightIcon={<img src={deleteOrange} />}
                                                            data-testid="delete-account-btn"
                                                        />
                                                    </Box>
                                                </Form>
                                            </>
                                        )
                                    }}
                                </Formik>
                            </FormBox>
                        </CenterBox>
                        <CustomAlert
                            data-testid="custom-alert"
                            open={!!this.state.alertMsg}
                            message={this.state.alertMsg}
                            handleClose={() => this.toggleAlert("")}
                        />
                    </Wrapper>
                    }
                </Sidebar>
            </>
        )
    }
}
// Customizable Area Start
const Wrapper = styled(Box)({
    padding: "48px",
    userSelect: "none",
    "@media (min-width: 480px) and (max-width: 767px)": {
        padding: "48px 32px",
    },
    "@media (max-width: 479px)": {
        padding: "48px 24px",
    }
})
const CenterBox = styled(Box)({
    display: "flex",
    justifyContent: "center",
})
const FormBox = styled(Box)({
    maxWidth: "279px",
    width: "100%",
    display: "flex",
    flexDirection: "column"
})
const SectionFont = styled(Box)({
    fontWeight: '600',
    fontSize: "16px",
    lineHeight: "17.57px",
    fontFamily: "roobert-regular",
    color: "#2F2F2F"
})
const CustomTextField = styled(TextField)({
    marginTop: "16px",
    borderRadius: "24px",
    boxShadow: "2.18px 2.18px 6.54px 0px #AEAEC066 inset, -2.18px -2.18px 6.54px 0px #FFFFFF inset",
    background: "#F4F4F4",
    width: "100%",
    height: "48px",
    "&.date-picker-input": {
        "& input": {
            cursor: "pointer !important"
        }
    },
    "& .MuiOutlinedInput-root": {
        borderRadius: "24px",
        fontFamily: "roobert-regular",
        fontWeight: "600",
        color: "#717C90",
        height: "48px",
        width: "100%",
        fontSize: "14px",
        "& .Mui-disabled": {
            color: "#717C90 !important",
            "-webkit-text-fill-color": "unset",
        },
        "&:hover fieldset": {
            border: "none",
        },
        "& fieldset": {
            border: "none",
        },
        "&.Mui-focused fieldset": {
            border: "none",
        },
    },
    "& .MuiFormLabel-root": {
        color: "#ACACAC",
        fontWeight: "600",
        fontSize: "14px",
        LineWeight: "16px",
        fontFamily: "roobert-regular",
        top: -2,
    },
    "& .MuiInputLabel-root-MuiInputLabel-root.Mui-focused": {
        visibility: "hidden",
    },
    "& .MuiInputLabel-shrink": {
        transform: "translate(14px, -6px) scale(0.85)",
        visibility: "hidden",
    },
})

const SaveButton = styled(CommonButton)({
    width: "100%",
    height: "48px",
    padding: "0 16px",
    borderRadius: "24px",
    backgroundColor: "#F4F4F4",
    boxShadow: outsetShadow,
    textTransform: 'none',
    fontWeight: '600',
    fontSize: "16px",
    lineHeight: "16px",
    fontFamily: "roobert-regular",
    color: "#2F2F2F",
    "&:hover": {
        backgroundColor: "#F4F4F4"
    }
})

const DeleteAccountButton = styled(CommonButton)({
    width: "100%",
    height: "48px",
    padding: "0 16px",
    borderRadius: "24px",
    backgroundColor: "#F4F4F4",
    textTransform: 'none',
    boxShadow: outsetShadow,
    color: "#F27C66",
    fontWeight: '600',
    fontSize: "16px",
    lineHeight: "16px",
    fontFamily: "roobert-regular",
    "&:hover": {
        backgroundColor: "#F4F4F4"
    }
})
const CharLengthFont = styled(Typography)({
    fontSize: "10px",
    lineHeight: "10.98px",
    color: "#ACACAC",
    fontWeight: "600",
    fontFamily: "roobert-regular"
})
const ChangeFont = styled(Typography)({
    fontSize: "10px",
    lineHeight: "100%",
    color: "#717C90",
    fontWeight: "400",
    fontFamily: "roobert-regular",
    textDecoration: "underline",
    cursor: "pointer"
})
const ErrorBox = styled(Box)({
    padding: "8px 16px 0 16px",
    "& .error-font": {
        color: "#EA4335",
        fontWeight: "400",
        fontSize: "12px",
        lineHeight: "13.18px",
        fontFamily: 'roobert-regular',
    },
    "& .helper-font": {
        color: "#717C90",
        fontWeight: "400",
        fontSize: "12px",
        lineHeight: "13.18px",
        fontFamily: 'roobert-regular',
    }
})
const CrossRedImg = styled('img')({
    width: "12px",
    height: "12px",
    marginTop: "2px"
})
const loadingStyle = {
    color: "#2F2F2F",
    height: "24px",
    width: "24px"
} as React.CSSProperties
// Customizable Area End
