import React from "react";
import { Box, styled, Button, ButtonProps, CircularProgress, Snackbar,SnackbarProps, IconButton } from '@mui/material';
import MergeEngineUtilities from "./MergeEngineUtilities";
import { Close } from '@mui/icons-material';

export const ResolveCondition = (condition: boolean, truthy: any, falsy: any) => {
    return condition ? truthy : falsy
}

export const getTruthyString = (string: any): string => {
    return typeof string === "string" && string ? string : "";
};

export const ScreenTitleBox = ({ title }: { title: string }) => {
    return (
        <HeaderBox>{title}</HeaderBox>
    )
}
const HeaderBox = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "128px",
    boxShadow: "0px 2px 4px 0px #717C901A",
    color: "#2F2F2F",
    fontFamily: "roobert-regular",
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "22px",
    textAlign: "center",
})

interface CommonButtonProps extends ButtonProps {
    isLoading?: boolean;
    iconSize?: string;
    leftIcon?: React.ReactNode;
    rightIcon?: React.ReactNode;
    label: string;
    loadingStyle?: React.CSSProperties
}

export const CommonButton: React.FC<CommonButtonProps> = ({ loadingStyle, isLoading, iconSize, leftIcon, rightIcon, label, ...props }) => {
    return (
        <Button {...props} disabled={isLoading}>
            {isLoading ? <CircularProgress style={loadingStyle ? loadingStyle : {}} /> :
                <Box width={"100%"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                    <Box width={iconSize ? iconSize : "16px"}>
                        {leftIcon && leftIcon}
                    </Box>
                    <Box>{label}</Box>
                    <Box width={iconSize ? iconSize : "16px"}>
                        {rightIcon && rightIcon}
                    </Box>
                </Box>
            }
        </Button>
    );
};

export const checkLogin = (props: any) => {
    const token = getTruthyString(localStorage.getItem("token"))
    if (!token) {
        return MergeEngineUtilities.navigateToScreen("EmailAccountLoginBlockWeb", props)
    }
}

interface CustomAlertProps extends SnackbarProps {
    open: boolean
    message: string
    handleClose: () => void
}

export const CustomAlert: React.FC<CustomAlertProps> = ({ open, message, handleClose }) => {
    return (
        <>
            {open ?
                <StyledSnackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={open}
                    onClose={handleClose}
                    message={message}
                    autoHideDuration={5000}
                    action={(<IconButton onClick={handleClose}><Close /></IconButton>)}
                />
                : <></>}
        </>
    )
}
const StyledSnackbar = styled(Snackbar)({
    "& .MuiSnackbarContent-root": {
        color: "#2F2F2F",
        backgroundColor: "#fff",
        fontSize: "16px",
        fontWeight: "400",
        borderRadius: "8px",
        fontFamily: "roobert-regular",
    }
});


export function debounce<T extends (...args: any[]) => void>(func: T, delay: number) {
    let timer: ReturnType<typeof setTimeout>; // Works in both Node.js and Browser

    return (...args: Parameters<T>) => {
        clearTimeout(timer);
        timer = setTimeout(() => func(...args), delay);
    };
}

export const removeStoreKey = (removeAll?: "all") => {
    if (removeAll == "all") {
        localStorage.clear()
    } else {
        Object.keys(localStorage).forEach((key) => {
            if (!key.startsWith("loginUserInfo")) {
                localStorage.removeItem(key);
            }
        });
    }
}