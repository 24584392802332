import React from "react";
// Customizable Area Start
import SuccessReportModalController, { Props, configJSON } from "./SuccessReportModalController.web";
import { Box, Button, Dialog, Divider, styled, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
// Customizable Area End

export default class SuccessReportModal extends SuccessReportModalController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            <>
                <StyledDialog fullWidth maxWidth={"sm"}
                    open={this.props.open}
                >
                    <TitleBox>
                        <StyledCloseBtn disableRipple onClick={this.props.handleClose}>
                            <Close className="close__icon" />
                            Cancel
                        </StyledCloseBtn>
                        <Box className="modal__title">
                            {configJSON.ENUM.REPORT_MODAL_TITLE}
                        </Box>
                        <Box flex={1} />
                    </TitleBox>
                    <StyledHR />

                    <ContentWrapper>
                        <Typography className="desc__font">
                            {configJSON.ENUM.SUCCESS_REPORT_MODAL_MSG}
                        </Typography>
                        <CenterBox>
                            <SectionTitle>
                                {configJSON.ENUM.SUCCESSFUL}
                            </SectionTitle>
                        </CenterBox>
                    </ContentWrapper>
                </StyledDialog>
            </>
        )
    }
}
// Customizable Area Start
const StyledDialog = styled(Dialog)({
    userSelect: "none",
    "& .MuiBackdrop-root": {
        background: "linear-gradient(346.67deg, rgba(41, 76, 166, 0.15) 0%, rgba(228, 230, 234, 0.1) 110.58%)",
        backdropFilter: "blur(48px)",
    },
    "& .MuiDialog-paper": {
        backgroundColor: "#F4F4F4",
        borderRadius: "24px",
        maxWidth: "387px",
    },
})
const TitleBox = styled(Box)({
    padding: "16px 10px",
    display: "flex",
    "& .modal__title": {
        flex: 1,
        textAlign: "center",
        fontWeight: 600,
        fontSize: "16px",
        fontFamily: "roobert-regular",
        color: "#717C90",
    }
})

const StyledCloseBtn = styled(Button)({
    flex: "1",
    padding: 0,
    justifyContent: "start",
    textTransform: "none",
    minWidth: "unset",
    color: "#717C90",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "16px",
    fontFamily: "roobert-regular",
    "& .close__icon": {
        height: "16px",
        width: "16px",
        color: "#717C90",
        marginRight: "4px"
    },
    "&:hover": {
        background: "none !important"
    },
})
const StyledHR = styled(Divider)({
    borderColor: "#E4E6EA",
})
const ContentWrapper = styled(Box)({
    padding: "20px 30px 68px 30px",
    "& .desc__font": {
        textAlign: "center",
        fontWeight: 400,
        fontSize: "16px",
        fontFamily: "roobert-regular",
        lineHeight: "100%",
        color: "#2F2F2F",
    }
})
const SectionTitle = styled(Typography)({
    marginTop: "34px",
    fontFamily: "olten",
    background: "linear-gradient(308.32deg, #F27C66 32.08%, #FECD8F 55.96%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    display: "inline-block",
    lineHeight: "100%",
    fontWeight: "400",
    fontSize: "30px"
})
const CenterBox = styled(Box)({
    display: "flex",
    justifyContent: "center"
})
// Customizable Area End
