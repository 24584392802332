// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import * as Yup from "yup";
export const configJSON = require("../config");

export interface Props {
    navigation: any;
    id?: string;
}

interface S {
    apiLoading: boolean,
    isSuccess: null | boolean
}

interface SS { }

type FormValues = {
    name: string;
    email: string;
    subject: string;
    message: string;
    attachment: File | null;
};

interface IApiResp {
    message?: string,
    errors?: string[]
}
// Customizable Area End

export default class GetInTouchFormController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    postGetInTouchApiCallId: string = ""

    initialFormvalues: FormValues = {
        name: "",
        email: "",
        subject: "",
        message: "",
        attachment: null
    }
    validationSchema = Yup.object().shape({
        name: Yup.string().trim().required("Name is required"),
        email: Yup.string().trim().email("Please enter a valid email address").required("Email is required"),
        subject: Yup.string().trim().required("Subject is required"),
        message: Yup.string().trim().max(500, "Message cannot exceed 500 characters").required("Message is required"),
        attachment: Yup.mixed()
            .test("fileType", "Allowed file format: .jpg, .png, .pdf", (file) => {
                if (!file) return true;
                return ["image/jpeg", "image/png", "application/pdf"].includes(file.type);
            })
    });

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceDataMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        ];

        this.state = {
            apiLoading: false,
            isSuccess: null
        }
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId && responseJson) {
                if (apiRequestCallId == this.postGetInTouchApiCallId) {
                    this.postGetInTouchApiResp(responseJson)
                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start

    postGetInTouchApiResp = (responseJson: IApiResp) => {
        this.setState({ apiLoading: false })

        if (responseJson?.message) {
            this.setState({ isSuccess: true })
        }
        if (responseJson?.errors && responseJson.errors.length) {
            this.setState({ isSuccess: false })
        }

    }
    onSubmit = (values: FormValues) => {
        
        this.setState({ apiLoading: true, isSuccess: null })

        const bodyData = new FormData()
        bodyData.append("data[name]", values.name);
        bodyData.append("data[email_address]", values.email);
        bodyData.append("data[subject]", values.subject);
        bodyData.append("data[message]", values.message);
        if (values.attachment) {
            bodyData.append("data[attachment]", values.attachment as Blob);
        }

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.postGetInTouchApiCallId = requestMessage.messageId;

        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), bodyData);
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.ENDPOINTS.POST_GET_IN_TOUCH);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.API_METHOD.POST);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), {});

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    goToScreen = (screenName: string) => {
        this.props.navigation?.navigate(screenName)
    }
    // Customizable Area End
}