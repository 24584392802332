import React from "react";

// Customizable Area Start
import { Box, CircularProgress, Typography, styled } from '@mui/material';
import HeaderSimple from "./components/HeaderSimple.web";
// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderLoader = () => {
    return (
      <Box display={"flex"} justifyContent={'center'}>
        <StyledLoader />
      </Box>
    )
  }
  renderContent = () => {
    const { termsConditionsDescription, apiLoading } = this.state
    const sanitizedDescription = { __html: this.state.termsConditionsDescription };

    if (apiLoading && !termsConditionsDescription) {
      return this.renderLoader()
    }
    if (!apiLoading && termsConditionsDescription) {
      return <TextWrapper dangerouslySetInnerHTML={sanitizedDescription} />
    }
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        <StyledWrapper>
          <HeaderSimple thisprop={this.props}/>
          <Wrapper>
            <SubWrapper>
              <LeftBox>
                <StyledScreenTitle noWrap>Terms & Policies</StyledScreenTitle>
              </LeftBox>
              <RightBox>
                {this.renderContent()}
              </RightBox>
            </SubWrapper>
          </Wrapper>
        </StyledWrapper>
      </>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const StyledWrapper = styled("div")({
  background: "#F4F4F4",
  minHeight: "100vh",
});

const Wrapper = styled(Box)({
  background: "#F4F4F4",
  padding: "48px 88px",
  "@media (min-width: 1024px) and (max-width: 1439px)": {
    padding: "48px 66px",
  },
  "@media (min-width: 768px) and (max-width: 1023px)": {
    padding: "48px",
  },
  "@media (min-width: 480px) and (max-width: 767px)": {
    padding: "48px 32px",
  },
  "@media (max-width: 479px)": {
    padding: "48px 24px",
  }
})
const SubWrapper = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  columnGap: "88px",
  "@media (max-width: 1024px)": {
    flexWrap: "wrap"
  }
})
const LeftBox = styled(Box)({
  flex: 1,
  padding: "100px 0",
  "@media (max-width: 1024px)": {
    padding: "0 0 50px 0",
  }
})
const RightBox = styled(Box)({
  maxWidth: "747px",
  width: "100%",
  padding: "0 8px",
  maxHeight: "60vh",
  overflowY: "auto",
  "@media (max-width: 1024px)": {
    maxHeight: "unset",
    maxWidth: "unset"
  }
})
const TextWrapper = styled(Box)({
  fontFamily: "roobert-regular",
  color: "#717C90"
})

const StyledScreenTitle = styled(Typography)({
  color: "#2F2F2F",
  fontWeight: "bold",
  fontSize: "30px",
  lineHeight: "32px",
  fontFamily: "roobert-regular",
  "@media (max-width: 480px)": {
    fontSize: "24px",
  }
})
const StyledLoader = styled(CircularProgress)({
  color: "#717C90",
  height: "32px",
  width: "32px",
})
// Customizable Area End
