import React from "react";
// Customizable Area Start
import ReportContentController, { Props, configJSON } from "./ReportContentController.web";
import { Box, styled, TextField, Typography } from '@mui/material';
import { CommonButton, ResolveCondition } from "../../../../blocks/utilities/src/ResolveCondition.web";
import SuccessReportModal from "./SuccessReportModal.web";

interface IMenu {
    title: string,
    value: string
}
// Customizable Area End

export default class ReportContent extends ReportContentController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderSuccessModal = () => {
        const { showSuccessModal } = this.state
        return (
            <>
                {showSuccessModal ?
                    <SuccessReportModal
                        open={showSuccessModal}
                        handleClose={this.closeSuccessModal}
                        data-testid="success-report-modal"
                    /> : <></>
                }
            </>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            <>
                <Wrapper>
                    <ContentWrapper>
                        <Title>Reason for Report:</Title>
                        <MenuBox>
                            {configJSON.REPORT_OPTIONS.map((item: IMenu, itemIndex: number) => {
                                const { selectedReason } = this.state
                                const selected = selectedReason == item.value
                                const isLast = (configJSON.REPORT_OPTIONS as IMenu[]).length === itemIndex + 1
                                return (
                                    <>
                                        <RowBox onClick={() => this.handleSelectReason(item.value)}
                                            sx={{
                                                color: ResolveCondition(selected, "#F27C66", "#717C90"),
                                                borderBottom: ResolveCondition(isLast, "", "1px solid #E4E6EA")
                                            }}
                                            data-testid="report-menu"
                                        >
                                            {item.title}
                                        </RowBox>
                                    </>
                                )
                            })}
                        </MenuBox>
                        <CustomTextArea
                            multiline
                            maxRows={2}
                            placeholder="Please provide details"
                        />
                        <ReportButton
                            label="Report"
                            onClick={this.openSuccessModal}
                            data-testid="report-button"
                        />
                    </ContentWrapper>
                    {this.renderSuccessModal()}

                </Wrapper>

            </>
        )
    }
}
// Customizable Area Start
const Wrapper = styled(Box)({
    margin: "16.82px 0 20px 0",
    display: "flex",
    justifyContent: "center"
})
const ContentWrapper = styled(Box)({
    width: "100%",
    maxWidth: "279px"
})
const Title = styled(Typography)({
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "100%",
    fontFamily: "roobert-regular",
    color: "#2F2F2F",
})

const MenuBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    boxShadow: "2.18px 2.18px 6.54px 0px #AEAEC066 inset, -2.18px -2.18px 6.54px 0px #FFFFFF inset",
    borderRadius: "24px",
    margin: "16.5px 0 16px 0"
})
const RowBox = styled(Typography)({
    padding: "16px",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "100%",
    fontFamily: "roobert-regular",
    cursor: "pointer"
})
const CustomTextArea = styled(TextField)({
    borderRadius: "24px",
    width: "100%",
    boxShadow: "2.18px 2.18px 6.54px 0px #AEAEC066 inset, -2.18px -2.18px 6.54px 0px #FFFFFF inset",
    background: "#F4F4F4",
    height: "70px",
    "& .MuiInputBase-input": {
        fontWeight: 600,
        color: "#2F2F2F",
        width: "100%",
        fontSize: "14px",
        LineWeight: "16px",
        fontFamily: "roobert-regular",
        "&::placeholder": {
            color: "#ACACAC",
            opacity: 1,
            fontSize: "14px",
            LineWeight: "16px",
            fontFamily: "roobert-regular",
        },
    },
    "& .MuiOutlinedInput-root": {
        borderRadius: "24px",
        fontSize: "14px",
        fontFamily: "roobert-regular",

        "&:hover fieldset": {
            border: "none",
        },
        "& fieldset": {
            border: "none",
        },
        "&.Mui-focused fieldset": {
            border: "none",
        },
    },
})
const ReportButton = styled(CommonButton)({
    marginTop: "28px",
    width: "100%",
    height: "48px",
    textTransform: 'none',
    backgroundColor: "#F4F4F4",
    color: "#F27C66",
    padding: "10px 20px",
    borderRadius: "30px",
    boxShadow: "2.18px 2.18px 6.54px 0px #AEAEC066, -2.18px -2.18px 6.54px 0px #FFFFFF",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    fontFamily: "roobert-regular",
    fontWeight: '600',
    fontSize: "16px",
    "&:hover": {
        backgroundColor: "#F4F4F4",
        color: "#F27C66",
    }
})
// Customizable Area End
