// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

export const configJSON = require("../config");

export interface Props {
    navigation: any;
    id: string;
}

interface S {
    apiLoading: boolean,
    faqData: IFaq[],
    formattedFaqData: FormattedFaq[],
}

interface SS { }

interface IFaqResponse {
    success: boolean
    message: string
    data: IData
    meta: []
    errors: []
}

interface IData {
    faqs: IFaq[]
}

interface IFaq {
    id: number
    question: string
    answer: string
    created_at: string
    updated_at: string
    title: string
}
type FormattedFaq = {
    title: string;
    questions: Omit<IFaq, "title">[];
};
// Customizable Area End

export default class FAQPageController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    faqDataApiCallId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceDataMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        ];

        this.state = {
            apiLoading: false,
            faqData: [],
            formattedFaqData: [],
        }
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId && responseJson) {
                if (apiRequestCallId == this.faqDataApiCallId) {
                    this.faqDataApiResp(responseJson)
                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    faqDataApiResp = (responseJson: IFaqResponse) => {
        this.setState({ apiLoading: false })
        if (responseJson && responseJson.data?.faqs?.length) {
            this.formateFaqs(responseJson.data.faqs)
        }
    }
    async componentDidMount() {
        this.getFaqs()
    }

    getFaqs = () => {
        this.setState({
            apiLoading: true
        })
        const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        const endpoint = configJSON.ENDPOINTS.GET_FAQ
        this.faqDataApiCallId = reqMessage.messageId;
        reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.API_METHOD.GET);
        runEngine.sendMessage(reqMessage.id, reqMessage);
    }
    formateFaqs = (faqs: IFaq[]) => {
        const result: FormattedFaq[] = Object.values(
            faqs.reduce<Record<string, FormattedFaq>>((acc, faq) => {
                if (!acc[faq.title]) {
                    acc[faq.title] = { title: faq.title, questions: [] };
                }
                const { title, ...faqData } = faq;
                acc[faq.title].questions.push(faqData);
                return acc;
            }, {})
        );

        this.setState({
            faqData: faqs,
            formattedFaqData: result
        })
    }
    // Customizable Area End
}