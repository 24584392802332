import React from "react";
// Customizable Area Start
import BlockedUsersListController, { Props, configJSON } from "./BlockedUsersListController.web";
import Sidebar from "../../../../blocks/settings2/src/components/Sidebar.web";
import { CommonButton, getTruthyString, ScreenTitleBox } from "../../../../blocks/utilities/src/ResolveCondition.web";
import { Box, Avatar, styled, Typography, CircularProgress } from '@mui/material';
// Customizable Area End

export default class BlockedUsersList extends BlockedUsersListController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderLoading = () => {
        return (
            <>
                {this.state.getUsersListLoading && !this.state.blockedUsersList.length ?
                    <LoaderBox>
                        <CircularProgress className="styled-loader" />
                    </LoaderBox> : <></>
                }
            </>
        )
    }
    renderNoDataFound = () => {
        return (
            <>
                {!this.state.getUsersListLoading && !this.state.blockedUsersList.length ?
                    <NoUserBox>No Blocked User</NoUserBox>
                    : <></>
                }
            </>
        )
    }
    renderList = () => {
        return (
            <>
                {this.state.blockedUsersList.length && !this.state.getUsersListLoading ?
                    (
                        this.state.blockedUsersList.map((item, index) => {
                            const firstName = getTruthyString(item.blockee_account?.first_name)
                            const lastName = getTruthyString(item.blockee_account?.last_name)
                            const fullName = `${firstName} ${lastName}`;
                            const userName = getTruthyString(item.blockee_account?.user_name)
                            const avatarLetter = firstName.charAt(0)
                            return (
                                <RowBox key={index}>
                                    <Box display={"flex"} alignItems={"center"} gap={"8px"}>
                                        <UserAvatar src={""}>{avatarLetter}</UserAvatar>
                                        <Box maxWidth={"160px"}>
                                            <StyledName noWrap title={fullName}>{fullName}</StyledName>
                                            <StyledUserName noWrap title={`@${userName}`}>@{userName}</StyledUserName>
                                        </Box>
                                    </Box>
                                    <StyledButton
                                        loadingStyle={styleBtnLoading}
                                        disableRipple
                                        onClick={() => this.handleUnBlock(item)}
                                        label="Unblock"
                                        isLoading={this.state.unBlockUserToBe?.id === item?.id}
                                        data-testid={`unblock-btn-${index}`}
                                    />
                                </RowBox>
                            )
                        })

                    ) : <></>

                }
            </>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            <>
                <Sidebar props={this.props} selectedScreen={"BlockedUsersList"} showSettingsSidebar>
                    <ScreenTitleBox title={configJSON.ENUM.BLOCKED_USER_SCREEN_TITLE} />
                    <Wrapper>
                        <ContentBox>
                            {this.renderLoading()}
                            {this.renderNoDataFound()}
                            {this.renderList()}
                        </ContentBox>
                    </Wrapper>
                </Sidebar>
            </>
        )
    }
}
// Customizable Area Start
const Wrapper = styled(Box)({
    userSelect: "none",
    padding: "48px",
    display: "flex",
    justifyContent: "center",
    "@media (max-width: 479px)": {
        padding: "48px 0px",
    }
})
const ContentBox = styled(Box)({
    width: "100%",
    maxWidth: "319px",
    maxHeight: "544px",
    overflowY: "auto",
    padding: "0 32px",
    display: "flex",
    flexDirection: "column",
    gap: "16px"
})
const RowBox = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "8px",
    borderBottom: "1px solid  #E4E6EA",
    "@media (max-width: 479px)": {
        flexDirection: "column"
    }
})

const UserAvatar = styled(Avatar)({
    height: "40px",
    width: "40px",
    borderRadius: "20px",
    color: "#2F2F2F",
    fontFamily: "roobert-regular",
    textTransform: "capitalize"
})
const StyledName = styled(Typography)({
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#2F2F2F",
    fontFamily: "roobert-regular",
})
const StyledUserName = styled(Typography)({
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "16px",
    color: "#ACACAC",
    fontFamily: "roobert-regular",
})
const StyledButton = styled(CommonButton)({
    padding: 0,
    height: "15px",
    minWidth: "88px",
    marginTop: "12px",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "100%",
    color: "#717C90",
    fontFamily: "roobert-regular",
    textTransform: "none"
})
const LoaderBox = styled(Box)({
    minHeight: "100px",
    display: "flex",
    justifyContent: "center",
    "& .styled-loader": {
        color: "#2F2F2F"
    }
})

const NoUserBox = styled(Box)({
    marginTop: "212px",
    minHeight: "100px",
    display: "flex",
    justifyContent: "center",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "12px",
    color: "#2F2F2F",
    fontFamily: "roobert-regular",
})

const styleBtnLoading = {
    height: "18px",
    width: "18px",
    color: "#2F2F2F"
} as React.CSSProperties
// Customizable Area End
