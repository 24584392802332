import React from 'react'
import MergeEngineUtilities from '../../../utilities/src/MergeEngineUtilities';
import { Box, styled } from '@mui/material';
import { Close } from '@mui/icons-material';
import { HeaderLogo } from '../assets';
import { CommonButton } from '../../../utilities/src/ResolveCondition.web';

type HeaderBoxProps = {
    thisprop: any;
};

const HeaderSimple: React.FC<HeaderBoxProps> = ({ thisprop }) => {
    return (
        <HeaderBox>
            <HeaderImage src={HeaderLogo} alt='logo'
                data-test-id="offspace-logo"
                onClick={() => MergeEngineUtilities.navigateToScreen("Home", thisprop)}
            />
            <StyledCloseBtn
                label='Close'
                rightIcon={<CloseIcon />}
                data-test-id="close-btn"
                onClick={() => thisprop?.navigation?.goBack()}
            />
        </HeaderBox>
    );
}
const HeaderImage = styled(`img`)({
    cursor: "pointer",
    userSelect: "none"
})
const HeaderBox = styled(Box)({
    height: "128px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 88px",
    "@media (min-width: 1024px) and (max-width: 1439px)": {
        padding: "0px 66px",
    },
    "@media (min-width: 768px) and (max-width: 1023px)": {
        padding: "0px 48px",
    },
    "@media (min-width: 480px) and (max-width: 767px)": {
        padding: "0px 32px",
    },
    "@media (max-width: 479px)": {
        padding: "0 24px",
    }
})

const StyledCloseBtn = styled(CommonButton)({
    color: "#2F2F2F",
    textTransform: "capitalize",
    fontSize: "15px",
    lineHeight: "16.47px",
    fontWeight: "600",
    fontFamily: "roobert-regular",
    width: "100%",
    maxWidth: "180px",
    height: "48px",
    border: "1px solid #2F2F2F",
    borderRadius: '24px',
    backgroundColor: '#F4F4F4',
    boxShadow: "2.18px 2.18px 6.54px 0px #AEAEC066, -2.18px -2.18px 6.54px 0px #FFFFFF",
    display: "flex",
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: "6px 19px",
    "&:hover": {
        backgroundColor: "#F4F4F4",
        color: "#2F2F2F",
        boxShadow: "2.18px 2.18px 6.54px 0px #AEAEC066 inset, -2.18px -2.18px 6.54px 0px #FFFFFF inset",
    },
    "@media (max-width: 479px)": {
        width: "unset",
    }
})
const CloseIcon = styled(Close)({
    width: "15px",
    height: "15px",
    color: '#2F2F2F',
})

export default HeaderSimple