// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { debounce, getTruthyString } from "../../../../blocks/utilities/src/ResolveCondition.web";
import MergeEngineUtilities from "../../../../blocks/utilities/src/MergeEngineUtilities";

export const configJSON = require("../config");

export interface Props {
    navigation: any;
    id: string;
}

interface S {
    token: string,
    apiLoading: boolean,
    alertOpen: boolean,
    alertMsg: string,
}

interface SS { }

interface IAPIResp {
    message: [{
        account: string
    }]
}
// Customizable Area End

export default class DeleteAccountController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    deleteAccountApiCallId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceDataMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        ];

        this.state = {
            token: getTruthyString(localStorage.getItem("token")),
            apiLoading: false,
            alertOpen: false,
            alertMsg: "",
        }
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId && responseJson) {
                if (apiRequestCallId == this.deleteAccountApiCallId) {
                    this.deleteAccountResp(responseJson)
                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    deleteAccountResp = (responseJson: IAPIResp) => {
        this.setState({ apiLoading: false })
        if (responseJson.message && responseJson.message.length && responseJson.message[0]?.account) {
            this.openAlert(responseJson.message[0]?.account)
            const debouncedGoToScreen = debounce(() => this.goToScreen("EmailAccountLoginBlockWeb"), 2000)
            debouncedGoToScreen()
        } else {
            this.openAlert("Something went wrong")
        }
    }

    deleteAccount = () => {
        const userId = localStorage.getItem('userId')
        if (!userId) return

        this.setState({ apiLoading: true })

        const header = {
            token: this.state.token
        }

        const endpoint = `${configJSON.ENDPOINTS.DELETE_ACCOUNT}/${userId}`
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.deleteAccountApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.API_METHOD.DELETE);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    openAlert = (message: string) => {
        this.setState({
            alertOpen: true,
            alertMsg: message
        })
    }

    closeAlert = () => {
        this.setState({
            alertOpen: false,
            alertMsg: ""
        })
    }
    goToScreen = (screenName: string) => {
        MergeEngineUtilities.navigateToScreen(screenName, this.props)
    }
    // Customizable Area End
}