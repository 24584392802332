// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

export const configJSON = require("../config");

export interface Props {
    handleClose: () => void
}

interface S {
    selectedReason: string,
    details: string,
    showSuccessModal: boolean
}

interface SS { }
// Customizable Area End

export default class ReportContentController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceDataMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        ];

        this.state = {
            selectedReason: "",
            details: "",
            showSuccessModal: false
        }
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // Customizable Area Start
    handleSelectReason = (Reason: string) => {
        this.setState({
            selectedReason: Reason
        })
    }
    openSuccessModal = () => {
        this.setState({
            showSuccessModal: true
        });
    };
    closeSuccessModal = () => {
        this.setState({
            showSuccessModal: false
        });
        this.props.handleClose()
    }
    // Customizable Area End
}