import React from "react";
// Customizable Area Start
import ViewFeedController, { Props, configJSON } from "./ViewFeedController.web";
import Sidebar from "../../../../blocks/settings2/src/components/Sidebar.web";
import { Avatar, Box, IconButton, styled, Typography } from '@mui/material';
import { CommonButton } from "../../../../blocks/utilities/src/ResolveCondition.web";
import { Close } from "@mui/icons-material";
import { blockBlack, favouritePostBlack, flagBlack, userProfile, viewFeed, viewPostBlack } from "../assets";
// Customizable Area End

export default class ViewFeed extends ViewFeedController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderUserDetails = () => {
        return (
            <UserBox>
                <Box maxWidth={"295px"}>
                    <Box display={"flex"} flexDirection={"row"} gap={"8px"} alignItems={"center"}>
                        <UserAvatar src={userProfile} />
                        <UserName>Matthew McClure</UserName>
                        <IconBtn style={{ marginLeft: "6px" }}>
                            <img src={flagBlack} />
                        </IconBtn>
                        <IconBtn style={{ marginLeft: "12px" }}>
                            <img src={blockBlack} />
                        </IconBtn>
                    </Box>
                    <Box marginTop={"9px"}>
                        <PostTitle>{configJSON.ENUM.POST_TITLE}</PostTitle>
                    </Box>
                    <Box marginTop={"4px"}>
                        <PostDesc>{configJSON.ENUM.POST_DESC}</PostDesc>
                    </Box>
                    <ChipBox>
                        {["Flowers", "Paris", "Craft"].map((item, index) => <StyledChip key={index}>{item}</StyledChip>)}
                    </ChipBox>
                </Box>

                <Box>
                    <ColumnBox>
                        <StyledFont>1K</StyledFont>
                        <IconBtn>
                            <img src={viewPostBlack} />
                        </IconBtn>
                    </ColumnBox>
                    <ColumnBox marginTop={"6px"}>
                        <StyledFont>120</StyledFont>
                        <IconBtn>
                            <img src={favouritePostBlack} />
                        </IconBtn>
                    </ColumnBox>
                </Box>
            </UserBox>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            <>
                <Sidebar props={this.props} selectedScreen={"Discover"}>
                    <Wrapper>
                        <Box maxWidth={"149px"} width={"100%"} />
                        <Box maxHeight={"100vh"} minHeight={"716px"} overflow={"auto"} padding={"0 24px"} minWidth={"375px"}>
                            <img src={viewFeed} />
                        </Box>
                        <DetailsBox>
                            <Box display={"flex"} justifyContent={"end"}>
                                <StyledCloseBtn
                                    label="Close"
                                    rightIcon={<CloseIcon />}
                                    onClick={this.handleClose}
                                />
                            </Box>
                            {this.renderUserDetails()}
                        </DetailsBox>

                    </Wrapper>
                </Sidebar >
            </>
        )
    }
}
// Customizable Area Start
const Wrapper = styled(Box)({
    userSelect: "none",
    padding: "48px 88px 36px 88px",
    display: "flex",
    gap: "76px",
    justifyContent: "space-between"
})
const UserBox = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "end"
})
const DetailsBox = styled(Box)({
    width: "344px",
    height: "696px",
    marginBottom: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
})
const StyledCloseBtn = styled(CommonButton)({
    padding: "16px",
    width: "100%",
    maxWidth: "180px",
    height: "48px",
    border: "1px solid #2F2F2F",
    borderRadius: '24px',
    backgroundColor: '#F4F4F4',
    color: "#2F2F2F",
    boxShadow: "2.18px 2.18px 6.54px 0px #AEAEC066, -2.18px -2.18px 6.54px 0px #FFFFFF",
    display: "flex",
    justifyContent: 'space-between',
    textTransform: "none",
    alignItems: 'center',
    fontWeight: 600,
    fontSize: "15px",
    lineHeight: "16.47px",
    fontFamily: "roobert-regular",
    "&:hover": {
        backgroundColor: "#F4F4F4",
        color: "#2F2F2F",
        boxShadow: "2.18px 2.18px 6.54px 0px #AEAEC066 inset, -2.18px -2.18px 6.54px 0px #FFFFFF inset",
    },
    "@media (max-width: 479px)": {
        width: "unset",
    }
})
const CloseIcon = styled(Close)({
    width: "16px",
    height: "16px",
    color: '#2F2F2F',
})
const ColumnBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
})
const UserAvatar = styled(Avatar)({
    height: "32px",
    width: "32px"
})
const UserName = styled(Typography)({
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "24px",
    fontFamily: "roobert-regular",
    color: "#2F2F2F"
})
const PostTitle = styled(Typography)({
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "20px",
    fontFamily: "roobert-regular",
    color: "#2F2F2F"
})
const StyledFont = styled(Typography)({
    color: "#2F2F2F",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "14px",
    fontFamily: "roobert-regular",
})
const PostDesc = styled(Typography)({
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "14px",
    fontFamily: "roobert-regular",
    color: "#2F2F2F"
})
const IconBtn = styled(IconButton)({
    padding: 0
})
const ChipBox = styled(Box)({
    marginTop: "16px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "8px",
})
const StyledChip = styled(Box)({
    borderRadius: "24px",
    border: "1px solid #2F2F2F",
    height: "30px",
    width: "72px",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "24px",
    fontFamily: "roobert-regular",
    color: "#2F2F2F",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
})
// Customizable Area End
