// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { getTruthyString } from "../../../../blocks/utilities/src/ResolveCondition.web";
import MergeEngineUtilities from "../../../../blocks/utilities/src/MergeEngineUtilities";

export const configJSON = require("../config");

const initialFormValue: IFormValues = {
    first_name: "",
    last_name: "",
    username: "",
    password: configJSON.ENUM.PASS_INITIAL_VALUE,
    birth_date: "",
    email: "",
}
export interface Props {
    navigation: any;
    id: string;
}

interface S {
    token: string,
    formValues: IFormValues,
    isUsernameValid: boolean | null,
    accountDetailsLoading: boolean,
    updatedApiLoading: boolean,
    alertMsg: string,
    showChangePasswordPage: boolean
}

interface SS { }

interface IFormValues {
    first_name: string,
    last_name: string,
    username: string,
    password: string,
    birth_date: string,
    email: string,
}
interface IAccountDetailsResp {
    data: IAccountDetails
}

interface IAccountDetails {
    id: string
    type: string
    attributes: Attributes
}

interface Attributes {
    activated: boolean
    user_name: string
    password: null | string
    country_code: null | string
    email: string
    first_name: string
    full_phone_number: string
    last_name: string
    date_of_birth: string
    phone_number: null | string
    type: string
    created_at: string
    updated_at: string
    device_id: null | string
    unique_auth_id: string
}

interface IValidateUsernameResp {
    message: string,
    available: boolean
}

// Customizable Area End

export default class AccountSettingsController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getAccountDetailsApiCallId: string = ""
    updateAccountDetailsApiCallId: string = ""
    validateUsernameApiCallId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceDataMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        ];

        this.state = {
            token: getTruthyString(localStorage.getItem("token")),
            formValues: initialFormValue,
            isUsernameValid: true,
            accountDetailsLoading: false,
            updatedApiLoading: false,
            alertMsg: "",
            showChangePasswordPage: false
        }
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId && responseJson) {
                if (apiRequestCallId == this.getAccountDetailsApiCallId) {
                    this.getAccountDetailsResp(responseJson)
                }
                if (apiRequestCallId == this.validateUsernameApiCallId) {
                    this.validateUsernameResp(responseJson)
                }
                if (apiRequestCallId == this.updateAccountDetailsApiCallId) {
                    this.updateAccountDetailsResp(responseJson)
                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    getAccountDetailsResp = (responseJson: IAccountDetailsResp) => {
        this.setState({ accountDetailsLoading: false })
        if (responseJson.data) {
            const userData = responseJson.data
            this.setState({
                formValues: {
                    first_name: getTruthyString(userData.attributes.first_name),
                    last_name: getTruthyString(userData.attributes.last_name),
                    username: getTruthyString(userData.attributes.user_name),
                    password: configJSON.ENUM.PASS_INITIAL_VALUE,
                    birth_date: getTruthyString(userData.attributes.date_of_birth),
                    email: getTruthyString(userData.attributes.email)
                }
            })
        }
    }
    validateUsernameResp = (responseJson: IValidateUsernameResp) => {
        this.setState({
            isUsernameValid: responseJson?.available
        })
    }

    updateAccountDetailsResp = (responseJson: IAccountDetailsResp) => {
        this.setState({ updatedApiLoading: false })
        if (responseJson.data && responseJson.data.id) {
            this.getAccountDetails()
            this.toggleAlert("Profile updated Successfully.")
        }
    }
    async componentDidMount() {
        this.getAccountDetails()
    }

    getAccountDetails = () => {
        const userId = localStorage.getItem('userId')
        if (!userId) return

        this.setState({ accountDetailsLoading: true })
        const header = {
            token: this.state.token
        }
        const endpoint = `${configJSON.ENDPOINTS.GET_ACCOUNT_DETAILS}/${userId}`

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getAccountDetailsApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.API_METHOD.GET);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    validateUsername = (username: string) => {
        if (!username.length || username.trim() === this.state.formValues.username) return
        const endpoint = `${configJSON.ENDPOINTS.VALIDATE_USERNAME}?user_name=${username}`

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.validateUsernameApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.API_METHOD.GET);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    goToScreen = (screenName: string) => {
        MergeEngineUtilities.navigateToScreen(screenName, this.props)
    }
    updateAccountDetails = (values: IFormValues) => {
        const userId = localStorage.getItem('userId')
        if (!userId || this.state.isUsernameValid !== true) return

        this.setState({ updatedApiLoading: true })
        const header = {
            token: this.state.token,
        }
        const formData = new FormData();
        formData.append("first_name", values.first_name);
        formData.append("last_name", values.last_name);
        formData.append("user_name", values.username);
        formData.append("date_of_birth", values.birth_date);
        const endpoint = `${configJSON.ENDPOINTS.GET_ACCOUNT_DETAILS}/${userId}`

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.updateAccountDetailsApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.API_METHOD.PATCH);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
        runEngine.sendMessage(requestMessage.id, requestMessage);

    }

    toggleAlert = (msg: string) => {
        this.setState({ alertMsg: msg })
    }
    openChangePasswordPage = () => {
        this.setState({
            showChangePasswordPage: true
        })
    }
    closeChangePasswordPage = () => {
        this.setState({
            showChangePasswordPage: false
        })
    }
    // Customizable Area End
}