// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

export const configJSON = require("../config");

export interface Props {
    navigation: any;
    id: string;
    handleCloseMenu: () => void
}

type TOption = "REPORT" | "BLOCK" | "DEFAULT"
interface S {
    currentOption: TOption,
    openBlockModal: boolean
}

interface SS { }
// Customizable Area End

export default class ContentFlagMenuController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceDataMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        ];

        this.state = {
            currentOption: "DEFAULT",
            openBlockModal: false
        }
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // Customizable Area Start

    setOption = (value: TOption) => {
        if (value == "BLOCK") {
            this.setState({
                openBlockModal: true
            })
            return
        }
        this.setState({
            currentOption: value
        })
    }
    closeBlockModal = () => {
        this.setState({
            openBlockModal: false
        })
        this.props.handleCloseMenu()
    }
    // Customizable Area End
}