Object.defineProperty(exports, "__esModule", {
  value: true,
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.errorEmailNotValid = "Email not valid.";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log in";
exports.forgotPassword = "Forgot password?";

exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
exports.loginApiEndPoint = "/bx_block_login/logins";
exports.ENUM = {
  SCREEN_SUBTITLE: "Effortlessly build personalised micro-websites that stand out",
  HAVE_ACCOUNT: "I have an account",
  DONT_HAVE_ACCOUNT: "Don’t have an account?",
  ACCOUNT_NOT_FOUND: "Account not found",
  USERNAME_ERROR: "Please enter a valid input.",
  INCORRECT_PASSWORD: "Incorrect password.",
  PASSWORD_ERROR: "Password is incorrect.",
  SAVE_CHECKBOX_LABEL: "Save Login Info on your device",
  LOGIN_REDIRECTION_SCREEN: "Discover"
};
// Customizable Area End
