// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import * as Yup from "yup";
import { debounce, getTruthyString, removeStoreKey } from "../../../../blocks/utilities/src/ResolveCondition.web";

export const configJSON = require("../config");

interface IInitialValues {
    old_password: string,
    new_password: string
}
const initialValues: IInitialValues = {
    old_password: "",
    new_password: ""
}

export interface Props {
    navigation: any;
    id: string;
    handleClose: () => void
    goToScreen: (screenName: string) => void
}

interface S {
    token: string,
    formValues: IInitialValues,
    apiLoading: boolean,
    errorOldPassword: string,
    alertMsg: string
}

interface SS { }

interface IApiResp {
    errors?: string[],
    data: object
}
// Customizable Area End

export default class ChangePasswordController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    updatePasswordApiCallId: string = ""

    validationSchema = Yup.object().shape({
        old_password: Yup.string().trim()
            .required("Please enter old password"),
        new_password: Yup.string().trim()
            .min(8, "Password must be at least 8 characters")
            .required("Please enter new password"),
    });
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceDataMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        ];

        this.state = {
            token: getTruthyString(localStorage.getItem("token")),
            formValues: initialValues,
            apiLoading: false,
            errorOldPassword: "",
            alertMsg: ""

        }
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId && responseJson) {
                if (apiRequestCallId == this.updatePasswordApiCallId) {
                    this.updatePasswordResp(responseJson)
                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    updatePasswordResp = (responseJson: IApiResp) => {
        this.setState({ apiLoading: false })
        if (responseJson?.errors?.length && responseJson.errors[0]?.includes("Invalid current password")) {
            this.setOldPasswordError(configJSON.ENUM.INVALID_OLD_PASSWORD)
        }
        if (responseJson?.data) {
            this.toggleAlert(configJSON.ENUM.SUCCESS_PASSWORD_ALERT)
            const debouncedGoToScreen = debounce(this.successRedirection, 2000)
            debouncedGoToScreen()
        }
    }
    successRedirection = () => {
        removeStoreKey("all")
        this.props.goToScreen(configJSON.ENUM.SUCCESS_PASSWORD_REDIRECTION)
    }

    setOldPasswordError = (error: string) => {
        this.setState({
            errorOldPassword: error
        })
    }
    toggleAlert = (msg: string) => {
        this.setState({ alertMsg: msg })
    }

    onSubmit = (values: IInitialValues) => {
        this.setState({ apiLoading: true })
        const header = {
            token: this.state.token,
        }
        const formData = new FormData();
        formData.append("password", values.old_password);
        formData.append("new_password", values.new_password);

        const endpoint = configJSON.ENDPOINTS.CHANGE_PASSWORD

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.updatePasswordApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.API_METHOD.POST);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End
}