export const spacesGrey = require("../assets/space_grey.svg").default;
export const favouritesBlack = require("../assets/favourites_black.svg").default;
export const profileGrey = require("../assets/profile_grey.svg").default;
export const favouritePostWhite = require("../assets/favourite_post_white.svg").default;
export const viewPostWhite = require("../assets/view_post_white.svg").default;

export const favouritePostBlack = require("../assets/favourite_post_black.svg").default;
export const viewPostBlack = require("../assets/view_post_black.svg").default;

export const flagBlack = require("../assets/flag_black.svg").default;
export const flagWhite = require("../assets/flag_white.svg").default;
export const blockBlack = require("../assets/block_black.svg").default;
export const blockWhite = require("../assets/block_white.svg").default;

export const userProfile = require("../assets/user_profile.svg").default;
export const searchPost = require("../assets/search_post.svg").default;

export const viewFeed = require("../assets/view_feed.svg").default;
export const favouriteCard = require("../assets/favourite_card.jpeg");
export const user2 = require("../assets/user_2.jpeg");

