import React from "react";
// Customizable Area Start
import HomeFavouritesController, { Props, configJSON } from "./HomeFavouritesController.web";
import Sidebar from "../../../../blocks/settings2/src/components/Sidebar.web";
import { Box, styled, Typography, Tab, Tabs, Avatar } from '@mui/material';
import { favouriteCard, favouritesBlack, profileGrey, spacesGrey, user2 } from "../assets";
// Customizable Area End

export default class HomeFavourites extends HomeFavouritesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderNavbar = () => {
        return (
            <NavigationBox>
                <TabBox>
                    <Box display={"flex"} justifyContent={"space-around"} marginBottom={"16px"}>
                        <img src={spacesGrey} />
                        <img src={favouritesBlack} />
                        <img src={profileGrey} />
                    </Box>
                    <TabsWrapper variant="fullWidth"
                        value={this.state.tabValue}
                    >
                        <StyledTab label="Spaces" value={1} />
                        <StyledTab
                            label={
                                <>
                                    <Box display={"flex"} width={"100%"} justifyContent={"space-around"} alignItems={"center"}>
                                        <Box />
                                        <Box>Favourites</Box>
                                        <FavouriteCount>9</FavouriteCount>
                                    </Box>
                                </>
                            }
                            value={2} />


                        <StyledTab label="Profile" value={3} />
                    </TabsWrapper>
                </TabBox>
            </NavigationBox>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const boxes = Array.from({ length: 14 });
        // Customizable Area End
        return (
            <>
                <Sidebar props={this.props} selectedScreen={"HomeFavourites"}>

                    {this.renderNavbar()}
                    <Wrapper>
                        <ContentBox>
                            <CardsWrapper>
                                {boxes.map((item) => {
                                    return (
                                        <StyledCard>
                                            <TopBox>
                                                <CardTitle title={configJSON.ENUM.CARD_TITLE}>{configJSON.ENUM.CARD_TITLE}</CardTitle>
                                                <StyledAvatar src={user2} />
                                            </TopBox>
                                            <ImageBox>
                                                <img src={favouriteCard} />
                                            </ImageBox>
                                        </StyledCard>
                                    )
                                })}
                            </CardsWrapper>
                        </ContentBox>
                    </Wrapper>
                </Sidebar>
            </>
        )
    }
}
// Customizable Area Start
const NavigationBox = styled(Box)({
    height: "128px",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#F4F4F4",
    boxShadow: "0px 2px 4px 0px #717C901A",
})
const Wrapper = styled(Box)({
    padding: "40px 32px"
})
const TabBox = styled(Box)({
    maxWidth: "343px",
    width: "100%",
    padding: "28px 0",
})
const TabsWrapper = styled(Tabs)({
    width: "100%",
    borderRadius: "24px",
    boxShadow: "2.18px 2.18px 6.54px 0px #AEAEC066 inset, -2.18px -2.18px 6.54px 0px #FFFFFF inset",
    padding: "4px",
    backgroundColor: "#F4F4F4",
    minHeight: "unset",
    ".MuiTabs-indicator": {
        display: "none"
    }
})
const StyledTab = styled(Tab)({
    padding: "8px",
    textTransform: "none",
    transition: "background-color 0.3s ease-in-out",
    color: "#ACACAC",
    fontWeight: '600',
    fontSize: "14px",
    lineHeight: "16px",
    fontFamily: "roobert-regular",
    minHeight: "unset",
    height: "32px",
    borderRadius: "16px",
    "&.Mui-selected": {
        backgroundColor: "#2F2F2F",
        color: "#F4F4F4"
    } as React.CSSProperties
})
const FavouriteCount = styled(Box)({
    fontWeight: '600',
    fontSize: "10px",
    lineHeight: "16px",
    fontFamily: "roobert-regular",
})

const ContentBox = styled(Box)({
    display: "flex",
    justifyContent: "center"
})

const CardsWrapper = styled(Box)({
    display: "flex",
    flexWrap: "wrap",
    gap: "24px",
    justifyContent: "center",
    padding: "24px",
    maxWidth: "560px",
    maxHeight: "800px",
    overflowY: "auto"
})
const StyledCard = styled(Box)({
    userSelect: "none",
    width: "167px",
    height: "305px",
    backgroundColor: "#FFFFFF",
    borderRadius: "24px",
    boxShadow: "4px 4px 24px 0px #AEAEC066"
})
const TopBox = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px"
})
const CardTitle = styled(Typography)({
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "pre-wrap",
    color: "#262626",
    fontWeight: '600',
    fontSize: "12px",
    lineHeight: "12px",
    fontFamily: "roobert-regular",
    maxWidth: "107px",
    marginLeft: "4px"
})
const StyledAvatar = styled(Avatar)({
    height: "32px",
    width: "32px",
    borderRadius: "53.5px"
})
const ImageBox = styled(Box)({
    marginTop: "1px",
    padding: "4px",
    borderRadius: "20px",
    height: "248px",
    "& img": {
        objectFit: "cover",
        width: "100%",
        height: "100%",
        borderRadius: "20px",
    }
})
// Customizable Area End
