Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.searchApiContentType = "application/json";
exports.getSearchApiEndPoint = "search/users";
exports.errorTitle = "Error";
exports.getCustomizableProfileEndPoint = "bx_block_profile/profile/current_user_profile";
exports.updateCustomizableProfileEndPoint = "bx_block_profile/profile/update_user_profile";
exports.getCustomizableProfileFieldsEndPoint =
  "bx_block_profile/profile/custom_user_profile_fields";

exports.ENUM = {
  ACCOUNT_SETTINGS_SCREEN_TITLE: "Account Settings",
  DELETE_ACCOUNT_DESC: "Deleting your account is permanent.<br> All information associated with your account will be removed. ",
  PASS_INITIAL_VALUE: "1234123412341234",
  INVALID_OLD_PASSWORD: "This password doesn’t match the old password",
  SUCCESS_PASSWORD_ALERT: "Password changed Successfully!",
  SUCCESS_PASSWORD_REDIRECTION: "EmailAccountLoginBlockWeb"
}
exports.ENDPOINTS = {
  GET_ACCOUNT_DETAILS: "account_block/accounts",
  VALIDATE_USERNAME: "account_block/validate/username",
  DELETE_ACCOUNT: "account_block/accounts",
  CHANGE_PASSWORD: "account_block/accounts/update_password"
}
exports.API_METHOD = {
  GET: "GET",
  POST: "POST",
  DELETE: "DELETE",
  PATCH: "PATCH",
  PUT: "PUT"
}
// Customizable Area End
