// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

export const configJSON = require("../config");

export interface Props {
    navigation: any;
    id: string;
}

interface S {
    loadedImages: Set<number>,
    showMenu: boolean
}

interface SS { }
// Customizable Area End

export default class DiscoverController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceDataMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        ];

        this.state = {
            loadedImages: new Set<number>(),
            showMenu: false
        }
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // Customizable Area Start
    handleInView = (inView: boolean, index: number) => {
        this.handleCloseMenu()
        if (inView) {
            this.setState((prevState) => ({
                loadedImages: new Set(prevState.loadedImages).add(index),
            }));
        }
    };

    handleOpenMenu = () => {
        this.setState({ 
            showMenu: true
        });
    };
    
    handleCloseMenu = () => {
        this.setState({
            showMenu: false
        })
    }
    // Customizable Area End
}