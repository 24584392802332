import React from 'react'
import { Box, styled, Typography } from '@mui/material';
import MergeEngineUtilities from "../../blocks/utilities/src/MergeEngineUtilities";

export default function Footer({ prop }: any) {
    return (
        <Wrapper>
            <Box display={"flex"} alignItems={"center"}>
                <StyledFont style={{ textDecoration: "underline" }}
                  onClick={() => MergeEngineUtilities.navigateToScreen('TermsConditionsWeb', prop)}
                >
                    Terms & Policies
                </StyledFont>
                <StyledFont style={{ textAlign: "center" }} className='copyright-text-large'>
                    © 2025 OffSpace, All Rights Reserved
                </StyledFont>
                <StyledFont style={{ cursor: "pointer", textAlign: "end", textDecoration: "underline" }}
                  onClick={() => MergeEngineUtilities.navigateToScreen('FAQPage', prop)}
                  >
                    FAQs
                </StyledFont>
            </Box>
            <StyledFont className='copyright-text-small'>
                © 2025 OffSpace, All Rights Reserved
            </StyledFont>
        </Wrapper>
    )
}

const Wrapper = styled(Box)({
    backgroundColor: "#F4F4F4",
    padding: "24px 88px",
    "@media (min-width: 1024px) and (max-width: 1439px)": {
        padding: "24px 66px",
    },
    "@media (min-width: 768px) and (max-width: 1023px)": {
        padding: "24px 48px",
    },
    "@media (min-width: 480px) and (max-width: 767px)": {
        padding: "24px 32px",
    },
    "@media (max-width: 479px)": {
        padding: "24px",
    }
})
const StyledFont = styled(Typography)({
    userSelect: "none",
    cursor: "pointer",
    fontFamily: "roobert-regular",
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: 400,
    flex: 1,
    "&.copyright-text-large": {
        "@media (max-width: 768px)": {
            display: "none"
        }
    },
    "&.copyright-text-small": {
        display: "none",
        "@media (max-width: 768px)": {
            display: "block",
            textAlign: "center",
            marginTop: "24px"
        }
    }
})
