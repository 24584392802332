// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import MergeEngineUtilities from "../../../../blocks/utilities/src/MergeEngineUtilities";
import * as Yup from "yup";

export const configJSON = require("../config");

export interface Props {
    navigation: any;
    id: string;
}

interface S {
    sendEmailApiLoading: boolean,
    isEmailSent: boolean | null,
    alertMsg: string,
    emailFieldError: string
}

interface SS { }

interface IApiResp {
    error?: string
    message?: string
}
// Customizable Area End

export default class ForgotPasswordPageController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    sendEmailApiCallId: string = ''

    initialFormValues = {
        email: ""
    }

    validationSchema = Yup.object().shape({
        email: Yup.string()
            .email("Please enter a valid email address")
            .required("Email is required").trim(),
    });
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceDataMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        ];

        this.state = {
            sendEmailApiLoading: false,
            isEmailSent: null,
            alertMsg: "",
            emailFieldError: ""
        }
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId && responseJson) {
                if (apiRequestCallId === this.sendEmailApiCallId) {
                    this.sendEmailApiResp(responseJson)
                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    sendEmailApiResp = (responseJson: IApiResp) => {
        this.setState({ sendEmailApiLoading: false })
        if (responseJson.error?.includes("Account not found")) {
            const errorMsg = "Please enter a valid input."
            this.setFieldError(errorMsg)
            return
        }
        if (responseJson.message) {
            this.triggerAlert(responseJson.message)
            this.setState({ isEmailSent: true })
            return
        }
        if (responseJson.error) {
            this.triggerAlert(responseJson.error)
        }
    }

    goToScreen = (screenName: string) => {
        MergeEngineUtilities.navigateToScreen(screenName, this.props)
    }

    sendEmailApi = (emailValue: string) => {
        this.setState({ sendEmailApiLoading: true })

        const header = {
            "content-type": "application/json",
        };
        const body = {
            "data": {
                "email": emailValue
            }
        }
        const endpoint = configJSON.ENDPOINTS.FORGOT_PASSWORD

        const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.sendEmailApiCallId = reqMessage.messageId;
        reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
        reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.API_METHOD.POST);
        runEngine.sendMessage(reqMessage.id, reqMessage);
    }
    setFieldError = (msg: string) => {
        this.setState({
            emailFieldError: msg
        })
    }
    triggerAlert = (msg: string) => {
        this.setState({
            alertMsg: msg
        })
    }
    // Customizable Area End
}