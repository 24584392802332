import React from "react";
// Customizable Area Start
import SidebarController, { Props, configJSON } from "./SidebarController.web";
import { ResolveCondition } from "../../../../blocks/utilities/src/ResolveCondition.web";
import { Box, Button, Drawer, IconButton, styled, SxProps, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import {
    discoverBlack, discoverOrange, homeBlack, homeOrange, Logo, settingBlack, settingOrange, sidebarQR,
    accountBlack, accountGrey, notificationsBlack, notificationsGrey, faqBlack, faqGrey, contactUsBlack,
    contactUsGrey, termsPolicyBlack, termsPolicyGrey, sideArrowBlack, sideArrowGrey,
    logoutBlack, blockGrey, blockBlack,
} from "../assets";
const outsetShadow = "2.18px 2.18px 6.54px 0px #AEAEC066,-2.18px -2.18px 6.54px 0px #FFFFFF"
const insetShadow = "2.18px 2.18px 6.54px 0px #AEAEC066 inset, -2.18px -2.18px 6.54px 0px #FFFFFF inset"

const MainHeaderLinks = [
    {
        title: "Home",
        screenName: "HomeFavourites",
        route: "/home/favourites",
        icon: homeBlack,
        selectedIcon: homeOrange
    },
    {
        title: "Discover",
        screenName: "Discover",
        route: "/discover",
        icon: discoverBlack,
        selectedIcon: discoverOrange
    },
]

const SecondryHeaderLinks = [
    {
        section: "Account",
        links: [
            {
                title: "Account Settings",
                screenName: "AccountSettings",
                route: "/settings/account-settings",
                icon: accountGrey,
                selectedIcon: accountBlack
            },
            {
                title: "Notifications",
                screenName: "Notifications",
                route: "/settings/notifications",
                icon: notificationsGrey,
                selectedIcon: notificationsBlack
            }
        ]
    },
    {
        section: "Blocked",
        links: [
            {
                title: "Blocked Users",
                screenName: "BlockedUsersList",
                route: "/settings/blocked-users",
                icon: blockGrey,
                selectedIcon: blockBlack
            }]
    },
    {
        section: "Support",
        links: [
            {
                title: "FAQ",
                screenName: "FaqInterActiveFaq",
                route: "/settings/faq",
                icon: faqGrey,
                selectedIcon: faqBlack
            },
            {
                title: "Contact Us",
                screenName: "ContactUs",
                route: "/settings/contact-us",
                icon: contactUsGrey,
                selectedIcon: contactUsBlack
            },
            {
                title: "Terms & Policies",
                screenName: "TermsPolicies",
                route: "/settings/terms-and-policies",
                icon: termsPolicyGrey,
                selectedIcon: termsPolicyBlack
            }
        ]
    }
]
// Customizable Area End

export default class Sidebar extends SidebarController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderMainSidebar = () => {
        return (
            <>
                <ContentBox>
                    <Box marginTop={"18px"}>
                        <Box display={"flex"} flexDirection={"column"} gap={"16px"}>
                            {MainHeaderLinks.map((item, index) => {
                                const currentSelected = !this.props.showSettingsSidebar && item.screenName === this.props.selectedScreen
                                const icon = ResolveCondition(currentSelected, item.selectedIcon, item.icon)
                                return (
                                    <LinkBox selected={currentSelected} onClick={() => this.goToScreen(item.screenName)} key={index} data-testid="header-link">
                                        <Box className='icon-box'>
                                            <img src={icon} />
                                        </Box>
                                        <Box className='font-box'>{item.title}</Box>
                                        <Box className="last-box" />
                                    </LinkBox>
                                )
                            })}
                            <ResponsiveSettingBox>
                                {this.renderSettingButton()}
                                {this.props.showSettingsSidebar ? this.renderSublinks() : <></>}
                            </ResponsiveSettingBox>
                        </Box>
                    </Box>
                    <Box>
                        <QRBox>
                            <img src={sidebarQR} />
                            <QRfont>{configJSON.ENUM.QR_TEXT}</QRfont>
                        </QRBox>
                        <SettingLinkBox>
                            {this.renderSettingButton()}
                        </SettingLinkBox>
                        <ResponsiveLogoutBox>
                            {this.renderLogoutButton()}
                        </ResponsiveLogoutBox>

                        <CopyRightfont>{configJSON.ENUM.COPYRIGHT_TEXT}</CopyRightfont>
                    </Box>
                </ContentBox>
            </>
        )
    }
    renderSettingButton = () => {
        const settingIcon = ResolveCondition(!!this.props.showSettingsSidebar, settingOrange, settingBlack)
        return (
            <LinkBox selected={this.props.showSettingsSidebar} onClick={() => this.goToScreen(configJSON.ENUM.SETTING_REDIRECTION)} data-testid="settings-button">
                <Box className='icon-box'>
                    <img src={settingIcon} />
                </Box>
                <Box className='font-box'>Settings</Box>
                <Box className="last-box" />
            </LinkBox>
        )
    }
    renderSecondrySidebar = () => {
        return (
            <>
                <ContentBox>
                    {this.renderSublinks()}
                    <Box marginBottom={"40px"}>
                        {this.renderLogoutButton()}
                    </Box>
                </ContentBox>
            </>
        )
    }
    renderSublinks = () => {
        return (
            <>
                <Box marginTop={"32px"}>
                    {SecondryHeaderLinks.map((record, index) => {
                        return (
                            <Box marginBottom={"13px"} key={index}>
                                <Sectionfont>{record.section}</Sectionfont>
                                <SublinkWrapper>
                                    {record.links.map((sublink, index) => {
                                        const selected = this.props.selectedScreen == sublink.screenName
                                        const icon = ResolveCondition(selected, sublink.selectedIcon, sublink.icon)
                                        const sideArrowIcon = ResolveCondition(selected, sideArrowBlack, sideArrowGrey)
                                        return (
                                            <SublinkBox key={index} onClick={() => this.goToScreen(sublink.screenName)} data-testid={`sub-link-${index}`}
                                                sx={{ borderBottom: ResolveCondition(record.links.length === 1, "", "1px solid #E4E6EA") }}>
                                                <Box display={"flex"} gap={"8px"} flexDirection={"row"}>
                                                    <img src={icon} />
                                                    <Sublinkfont selected={selected}>{sublink.title}</Sublinkfont>
                                                </Box>
                                                <Box>
                                                    <img src={sideArrowIcon} />
                                                </Box>
                                            </SublinkBox>
                                        )
                                    })}
                                </SublinkWrapper>
                            </Box>
                        )
                    })}
                </Box>
            </>
        )
    }
    renderLogoutButton = () => {
        return (
            <LogoutButton onClick={() => this.goToScreen("Logout")} data-testid="logout-button">
                <Box className="button-content">
                    <img src={logoutBlack} />
                    <Box>Log out</Box>
                    <Box width={"16px"} />
                </Box>
            </LogoutButton>
        )
    }
    renderResponsiveLogo = () => {
        return (
            <TitleBox position={"relative"}>
                <img src={Logo} alt="logo" />
                <IconButton sx={sxStyle.closeButton} onClick={this.closeDrawer}><CloseIcon /></IconButton>
            </TitleBox>
        )
    }
    responsiveDrawer = () => {
        return (
            <>
                <ResponsiveBox>
                    <IconButton onClick={this.openDrawer} data-testid="drawer-button"><MenuIcon /></IconButton>
                </ResponsiveBox>
                <Drawer anchor="left" open={this.state.openDrawer} onClose={this.closeDrawer} data-testid="drawer-wrapper">
                    <Box width={"250px"}>
                        {this.renderResponsiveLogo()}
                        {this.renderMainSidebar()}
                    </Box>
                </Drawer>
            </>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            <>
                <Box display={"flex"} minHeight={"100vh"}>
                    {this.responsiveDrawer()}

                    <FirstBox>
                        <TitleBox>
                            <img src={Logo} alt="logo" />
                        </TitleBox>
                        {this.renderMainSidebar()}
                    </FirstBox>


                    {this.props.showSettingsSidebar ?
                        <SecondBox>
                            <TitleBox>
                                <SecondBoxTitle>{configJSON.ENUM.SECONDRY_SIDEBAR_TITLE}</SecondBoxTitle>
                            </TitleBox>
                            {this.renderSecondrySidebar()}
                        </SecondBox>
                        : <></>}

                    <Box id="children" flex={1} overflow={"auto"} sx={{ backgroundColor: "#F4F4F4" }}>
                        {this.props.children}
                    </Box>
                </Box>

            </>
        )
    }
}
// Customizable Area Start
const FirstBox = styled(Box)({
    userSelect: "none",
    zIndex: 2000,
    backgroundColor: "#F4F4F4",
    boxShadow: "12px 0px 24px 0px #717C901A",
    backdropFilter: "blur(24px)",
    maxWidth: "195px",
    width: "100%",
    "@media (max-width: 1280px)": {
        maxWidth: "250px",
    },
    "@media (max-width: 768px)": {
        display: "none",
    },
})
const SecondBox = styled(Box)({
    userSelect: "none",
    zIndex: 1000,
    backgroundColor: "#F4F4F4",
    boxShadow: "12px 0px 24px 0px #717C901A",
    backdropFilter: "blur(24px)",
    maxWidth: "304px",
    width: "100%",
    "@media (max-width: 1280px)": {
        display: "none",
    },
})
const TitleBox = styled(Box)({
    backgroundColor: "#F4F4F4",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "128px",
    boxShadow: "0px 2px 4px 0px #717C901A",
    borderBottom: "2px solid rgba(113, 124, 144, 0.1)",
    "& img": {
        cursor: "pointer"
    }
})
const ContentBox = styled(Box)({
    backgroundColor: "#F4F4F4",
    minHeight: "672px",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    padding: "16px"
})
const LinkBox = styled(Box)(({ selected }: { selected?: boolean }) => ({
    cursor: "pointer",
    userSelect: "none",
    gap: "8px",
    height: "48px",
    padding: "0 16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "24px",
    boxShadow: selected ? insetShadow : outsetShadow,
    "& .font-box": {
        color: selected ? "#F27C66" : "#2F2F2F",
        fontFamily: "roobert-regular",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "18px",
        textAlign: "center",
    },
    "& .last-box": {
        width: "16px"
    }
}));

const QRBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    gap: "24px",
    marginBottom: "24px",
    "@media (max-width: 1280px)": {
        display: "none",
    },
})
const QRfont = styled(Typography)({
    fontFamily: "roobert-regular",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "13.18px",
    color: "#717C90"
})
const CopyRightfont = styled(Typography)({
    marginTop: "24px",
    fontFamily: "roobert-regular",
    fontWeight: 400,
    fontSize: "9px",
    lineHeight: "16px",
    color: "#717C90",
    textDecoration: "underline"
})
const SecondBoxTitle = styled(Typography)({
    fontFamily: "roobert-regular",
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "21.96px",
    color: "#2F2F2F",
})

const Sectionfont = styled(Typography)({
    fontFamily: "roobert-regular",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "17.57px",
    color: "#2F2F2F",
    paddingLeft: "16px",
    marginBottom: "8px"
})
const SublinkWrapper = styled(Box)({
    display: "flex",
    flexDirection: "column",
    boxShadow: insetShadow,
    borderRadius: "24px",
    padding: "0 16px",
})
const SublinkBox = styled(Box)({
    cursor: "pointer",
    padding: "16px 0",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
})
const Sublinkfont = styled(Typography)(({ selected }: { selected?: boolean }) => ({
    fontFamily: "roobert-regular",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "15.37px",
    textDecoration: selected ? "underline" : "none",
    color: selected ? "#2F2F2F" : "#717C90"
}))

const LogoutButton = styled(Button)({
    width: "100%",
    height: "48px",
    padding: "0 16px",
    borderRadius: "24px",
    backgroundColor: "#F4F4F4",
    boxShadow: outsetShadow,
    textTransform: 'none',
    fontWeight: '600',
    fontSize: "16px",
    color: "#2F2F2F",
    fontFamily: "roobert-regular",
    "&:hover": {
        backgroundColor: "#F4F4F4",
        color: "#2F2F2F",
    },
    "& .button-content": {
        width: "100%",
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "center"
    }
})

const ResponsiveBox = styled(Box)({
    display: "none",
    "@media (max-width: 768px)": {
        display: "block",
        position: "absolute"
    },
})
const SettingLinkBox = styled(Box)({
    display: "block",
    "@media (max-width: 1280px)": {
        display: "none",
    },
})
const ResponsiveSettingBox = styled(Box)({
    display: "none",
    "@media (max-width: 1280px)": {
        display: "block",
    },
})
const ResponsiveLogoutBox = styled(Box)({
    display: "none",
    "@media (max-width: 1280px)": {
        display: "block",
    },
})
const sxStyle = {
    closeButton: {
        position: "absolute",
        top: 0,
        right: 0
    } as SxProps
}
// Customizable Area End
