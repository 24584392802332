// Customizable Area Start
import React, { ReactNode } from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import MergeEngineUtilities from "../../../../blocks/utilities/src/MergeEngineUtilities";

export const configJSON = require("../config");

export interface Props {
    props: any;
    id?: string;
    showSettingsSidebar?: boolean;
    selectedScreen?: string
    children: ReactNode
}

interface S {
    openDrawer: boolean
}

interface SS { }
// Customizable Area End

export default class SidebarController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [];

        this.state = {
            openDrawer: false
        }
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    // Customizable Area Start
    openDrawer = () => {
        this.setState({
            openDrawer: true
        })
    }
    closeDrawer = () => {
        this.setState({
            openDrawer: false
        })
    }
    goToScreen = (screenName: string) => {
        window.scroll(0, 0) // Scroll To Top 
        MergeEngineUtilities.navigateToScreen(screenName, this.props.props)
    }
    // Customizable Area End
}