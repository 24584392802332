import React from "react";
// Customizable Area Start
import TermsPoliciesController, { Props, configJSON } from "./TermsPoliciesController.web";
import Sidebar from "./Sidebar.web";
import { ScreenTitleBox } from "../../../../blocks/utilities/src/ResolveCondition.web";
import { Box, styled, CircularProgress, Typography } from '@mui/material';
// Customizable Area End

export default class TermsPolicies extends TermsPoliciesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderLoader = () => {
        return (
            <Box display={"flex"} justifyContent={'center'}>
                <StyledLoader />
            </Box>
        )
    }
    renderContent = () => {
        const { termsPolicyData, apiLoading } = this.state
        const sanitizedDescription = { __html: this.state.termsPolicyData };

        if (apiLoading && !termsPolicyData) {
            return this.renderLoader()
        }

        if (!apiLoading && termsPolicyData) {
            return (
                <>
                    <Title>Terms of Service</Title>
                    <HTMLWrapper dangerouslySetInnerHTML={sanitizedDescription} data-testid="html-box" />
                </>
            )
        }
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            <>
                <Sidebar props={this.props} selectedScreen={"TermsPolicies"} showSettingsSidebar>
                    <ScreenTitleBox title={configJSON.ENUM.TERMS_POLICY_SCREEN_TITLE} />
                    <Wrapper>
                        {this.renderContent()}
                    </Wrapper>
                </Sidebar>
            </>
        )
    }
}
// Customizable Area Start
const Wrapper = styled(Box)({
    userSelect: "none",
    padding: "48px 88px 48px 106px",
    "@media (max-width: 1024px)": {
        padding: "48px",
    },
    "@media (max-width: 480px)": {
        padding: "48px 32px",
    },
})
const StyledLoader = styled(CircularProgress)({
    color: "#717C90",
    height: "32px",
    width: "32px",
})
const HTMLWrapper = styled(Box)({
    fontFamily: "roobert-regular",
    color: "#717C90",
    fontWeight: '400',
    fontSize: "16px",
    lineHeight: "17.57px",
    marginTop: "37px"
})
const Title = styled(Typography)({
    fontFamily: "roobert-regular",
    color: "#717C90",
    fontWeight: '600',
    fontSize: "24px",
    lineHeight: "26.35px",
})
// Customizable Area End
