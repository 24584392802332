Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiMethod = "POST";
exports.blockeduserApiApiContentType = "application/json";
exports.labelTitleText = "blockedusers";
exports.labelBodyText = "blockedusers Body";
exports.BlockeduserApiEndPoint="/block_users/block_users";
exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";
exports.textInputPlaceHolder = "Enter Text";
exports.contentPlaceHolder = "Enter Content";
exports.configError = "Error";
exports.configErrorTitle = "Please enter a title";
exports.configErrorContent = "Please enter some content";

exports.ENUM = {
  BLOCKED_USER_SCREEN_TITLE: "Blocked Users",
  CONFIRM_BLOCK_MODAL_TITLE: "Block Account",
  CONFIRM_BLOCK_MODAL_MSG: "Are you sure you want to block this account?",
  SUCCESS_BLOCK_MODAL_MSG: "Thank you for your report. Our team will review this account.",
  SUCCESS_MSG: "Successful!",
}
exports.ENDPOINTS = {
  GET_BLOCK_USER_LIST: "account_block/black_list_users",
  UNBLOCK_USER: "account_block/black_list_users"
}
// Customizable Area End
