Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpDeleteMethod = "DELETE";
exports.httpPostMethod = "POST";

exports.faqApiApiContentType = "application/json";
exports.faqApiEndPoint = "interactive_faqs/interactive_faqs";
exports.textInputPlaceHolder = "Enter Text";
exports.contentPlaceHolder = "Enter Content";
exports.configError = "Error";
exports.configErrorTitle = "Please enter a title";
exports.configErrorContent = "Please enter some content";
exports.apiEndPointGetfaq ="bx_block_interactive_faqs/interactive_faqs"
exports.ENUM = {
  FAQ_SCREEN_TITLE:"FAQ",
  FAQ_SCREEN_DESCRIPTION:"We’re a new platform dedicated to helping you create, customize, and share meaningful content easily. As we<br/> continue to grow and improve, we’re always working hard to deliver the best experience possible.<br/> Below, you’ll find answers to common questions, but if you need more help, don’t hesitate to reach out—your<br/> feedback helps us make OffSpace even better!"
}
// Customizable Area End