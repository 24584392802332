import React from "react";
// Customizable Area Start
import LogoutController, { Props, configJSON } from "./LogoutController.web";
import Sidebar from "../../../../blocks/settings2/src/components/Sidebar.web";
import { Box, Button, styled, Typography } from '@mui/material';
import { ScreenTitleBox } from "../../../../blocks/utilities/src/ResolveCondition.web";
import { logoutBlack, seeYouSoon } from "../assets";
// Customizable Area End

export default class Logout extends LogoutController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            <>
                <Sidebar props={this.props} selectedScreen={"Logout"} showSettingsSidebar>
                    <Wrapper>
                        <ScreenTitleBox title={configJSON.ENUM.LOGOUT_SCREEN_TITLE} />
                        <ContentBox>
                            <Box maxWidth={"327px"} display={"flex"} flexDirection={"column"} alignItems={"center"} padding={"0 32px"}>
                                <DescriptionFont>{configJSON.ENUM.LOGOUT_DESCRIPTION}</DescriptionFont>
                                <LabelImg src={seeYouSoon} />
                                <LogoutButton onClick={this.handleLogout} data-testid="logout-button">
                                    <Box className="content-box">
                                        <img src={logoutBlack} />
                                        <Box>Log out</Box>
                                        <Box width={"16px"} />
                                    </Box>
                                </LogoutButton>
                            </Box>
                        </ContentBox>
                    </Wrapper>
                </Sidebar>
            </>
        )
    }
}
// Customizable Area Start
const Wrapper = styled(Box)({
    userSelect: "none"
})
const ContentBox = styled(Box)({
    display: "flex",
    marginTop: "236px",
    justifyContent: "center",
    "@media (max-width: 1280px)": {
        margin: "150px 0"
    }
})
const DescriptionFont = styled(Typography)({
    fontFamily: "roobert-regular",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "17.57px",
    color: "#2F2F2F",
    textAlign: "center"
})
const LabelImg = styled('img')({
    marginTop: "34px"
})
const LogoutButton = styled(Button)({
    marginTop: "40px",
    width: "100%",
    maxWidth: "272px",
    height: "48px",
    padding: "0 16px",
    textTransform: 'none',
    borderRadius: "24px",
    backgroundColor: "#F4F4F4",
    boxShadow: "2.18px 2.18px 6.54px 0px #AEAEC066,-2.18px -2.18px 6.54px 0px #FFFFFF",
    color: "#2F2F2F",
    fontWeight: '600',
    fontSize: "16px",
    fontFamily: "roobert-regular",
    "&:hover": {
        color: "#2F2F2F",
        backgroundColor: "#F4F4F4",
    },
    "& .content-box": {
        width: "100%",
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "center"
    }
})
// Customizable Area End
