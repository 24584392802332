import React from "react";
// Customizable Area Start
import ContactSuccessController, { Props, configJSON } from "./ContactSuccessController.web";
import Sidebar from "../../../settings2/src/components/Sidebar.web";
import { ScreenTitleBox } from "../../../utilities/src/ResolveCondition.web";
import { Box, styled, Typography } from '@mui/material';

// Customizable Area End

export default class ContactSuccess extends ContactSuccessController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    const contactSuccessMsg = { __html: configJSON.ENUM.CONTACT_PAGE_SUB_MSG_TITLE }
    const contactSuccessMsg2 = { __html: configJSON.ENUM.CONTACT_PAGE_SUBTITLETWO }
    // Customizable Area End
    return (
      <>
        <Sidebar props={this.props} selectedScreen={"ContactSuccess"} showSettingsSidebar>
          <ScreenTitleBox title={configJSON.ENUM.CONTACTUS_SCREEN_TITLE} />
          <WrapperMsg>
              <FlexBoxMsg>
                <ContentBoxMsg>
                  <Box>
                    <Box display={"flex"} width={327} justifyContent={"space-between"}>
                      <Typography className="contact-msg" variant="h2">
                        {configJSON.ENUM.CONTACT_PAGE_TITLE}
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <div className="contact-msgdtail" dangerouslySetInnerHTML={contactSuccessMsg} />
                  </Box>
                  <Box marginTop={"25px"}>
                    <div className="contact-msgdtail" dangerouslySetInnerHTML={contactSuccessMsg2} />
                  </Box>
                </ContentBoxMsg>
              </FlexBoxMsg>
            </WrapperMsg>
        </Sidebar>
      </>
    )
  }
}
// Customizable Area Start
const WrapperMsg = styled(Box)({
  userSelect: "none",
  padding: "48px 32px"
})
const FlexBoxMsg = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
})
const ContentBoxMsg = styled(Box)({
  maxWidth: "279px",
  width: "100%",
  "& .contact-msg": {
    width:"327px",
    "@media (max-width: 1023px)": {
      width:"100%",
    },
    fontFamily: "roobert-regular",
    fontWeight: 600,
    fontSize: "24px",
    marginBottom: "48px"
  }, "& .contact-msgdtail": {
    fontFamily: "roobert-regular",
    fontWeight: 400,
    fontSize: "16px",
    width:"327px",
    "@media (max-width: 1023px)": {
      width:"100%",
    },
  }
})


// Customizable Area End
