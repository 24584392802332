import React from "react";

// Customizable Area Start
import { Accordion, AccordionDetails, AccordionSummary, Box, CircularProgress, Typography, styled } from '@mui/material';
import Sidebar from "../../../../blocks/settings2/src/components/Sidebar.web";
import { ScreenTitleBox } from "../../../../blocks/utilities/src/ResolveCondition.web";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// Customizable Area End

import FaqController, {
  Props,
  configJSON,
} from "./FaqController.web";

export default class Faq extends FaqController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    
    const groupedFaqs = this.state.faqData.reduce((acc:any, faq:any) => {
      if (!acc[faq.title]) {
        acc[faq.title] = [];
      }
      acc[faq.title].push(faq);
      return acc;
    }, {});
    return (
      <>
        <Sidebar props={this.props} selectedScreen={"FaqInterActiveFaq"} showSettingsSidebar>
                  <ScreenTitleBox title={configJSON.ENUM.FAQ_SCREEN_TITLE} />
                   <Wrapper>
                    <ContentBox>
                        <Typography className="titleHead" variant="h2">Welcome to our FAQ!</Typography>
                        <Typography className="textFaq" variant="body1"><div dangerouslySetInnerHTML={{__html:configJSON.ENUM.FAQ_SCREEN_DESCRIPTION}}/></Typography>
                    </ContentBox>
                 
       {Object.entries(groupedFaqs).map(([title, faqList]:any) => (
          <Accordion 
          key={title} 
          expanded={this.state.expanded === title} 
          onChange={this.handleChange(title)}
          className="accordion"
        >
          <AccordionSummary className="accordion-summary" expandIcon={<ExpandMoreIcon />}>
            <Typography className="textQuestion">{title}</Typography>
          </AccordionSummary>
          <AccordionDetails className="accordion-details">
            <div>
              {faqList.map((faq:any) => (
                <div key={faq.id}>
                  <Typography>
                    <div
                      className="textQuestionSec"
                      dangerouslySetInnerHTML={{ __html: faq.question }}
                    />
                  </Typography>
                  <StyledFaqAnswer>
                    <div dangerouslySetInnerHTML={{ __html: faq.answer }} />
                  </StyledFaqAnswer>
                </div>
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
        ))}
                   </Wrapper>

        </Sidebar>
      </>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const Wrapper = styled(Box)({
    userSelect: "none",
    padding: "48px 88px 48px 106px",
    "@media (max-width: 1023px)": {
      padding: "43px",
    },
    "& .textQuestion":{
        color:"#717C90",
        fontWeight:600,
        fontSize:"24px",
        lineHeight:"26.35px",
        height:"16px"
    },
    "& .textQuestionSec":{
      color:"#717C90",
      fontSize:"14px",
      lineHeight:"15.37px",
      fontWeight:600,
    },
    "& .MuiAccordion-region":{
      display:"flex",
      justifyContent:"center"
    },
    "& .accordion":{
        boxShadow:"none",
        backgroundColor:"transparent",
        position: "relative" ,
        margin:"0px"
    },
    "& .accordion-summary": {
        marginTop: "14px !important",
        marginBottom: "16px !important",
        padding: "4px 0px !important", 
        minHeight: "unset" 
    },
    "& .MuiAccordionSummary-content":{
margin:"0px !important",
width:"744.69px",
"@media (max-width: 1023px)": {
  width:"auto",
}
    },
    
    "& .accordion-details": {
        margin: "0 !important",
        padding: "8px 0px !important", 
        width:"676px",
    },
   "& .accordion::before": {
        position: "absolute",
        left:" 0",
        top:"-1px",
        right:"0",
        height:"1px",
        content: '""',
        opacity:1,
        backgroundColor:"transparent"},
        "& .accordion::after": {
    position: "absolute",
    left: "0",
    bottom: "-1px", // Placed at the bottom instead of top
    right: "0",
    height: "1px",
    content: '""',
    opacity: 1,
    backgroundColor: "rgba(0, 0, 0, 0.12)"}
  })
const ContentBox = styled(Box)({
    "& .titleHead": {
      width:"785px",
      "@media (max-width: 1023px)": {
      width:"100%",
    },
        color:"#2F2F2F",
        fontSize:"30px",
        fontWeight:600,
        fontFamily: "roobert-regular",
        lineHeight:"32.94px",
        marginBottom:"14px"
    },
    "& .textFaq": {
      width:"826px",
      height:"73px",
        color:"#2F2F2F",
        fontSize:"16px",
        fontWeight:400,
        fontFamily: "roobert-regular",
        lineHeight:"17.57px",
        marginBottom:"36px",
        "@media (max-width: 1023px)": {
  width:"auto",
  height:"auto"
}
    }
})

const StyledFaqAnswer = styled(Box)({
    fontWeight: "400",
    color: "#717C90",
    fontSize: "14px",
    lineHeight: "15.37px",
    fontFamily: "roobert-regular",
    marginTop:"0 0 24px 0",
    "& ul":{
      padding:"0",
      listStyleType:"none",
    }
})
const StyledLoader = styled(CircularProgress)({
  color: "#717C90",
  height: "32px",
  width: "32px",
})
// Customizable Area End
