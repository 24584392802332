Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";

exports.btnExampleTitle = "CLICK ME";
exports.landingPageEndPoint = "/bx_block_content_management/landing_pages"

exports.HEADER_LINKS = [
  {
    id: "header1",
    title: "How it works",
    link: "how-it-works-sec",
  },
  {
    id: "header2",
    title: "Our Story",
    link: "why-sec",
  },
  {
    id: "header3",
    title: "Contact us",
    link: "contact-us-sec",
  }
]
exports.ENUM = {
  MAIN_TITLE: "Stories that Matter",
  TITLE_SECTION_2: "Made with OffSpace",
  TITLE_SECTION_3: "How it works",
  TITLE_SECTION_4: "WHY",
  FAQ_PAGE_TITLE: "Welcome to our FAQ!",
  FAQ_PAGE_SUBTITLE: "We’re a new platform dedicated to helping you create, customize, and share meaningful content easily. As we continue to grow and improve, we’re always working hard to deliver the best experience possible. <br>Here, you’ll find answers to common questions, but if you need more help, don’t hesitate to reach out—your feedback helps us make OffSpace even better!"
}

exports.ENDPOINTS = {
  GET_FAQ: "bx_block_interactive_faqs/interactive_faqs"
}
exports.API_METHOD = {
  GET: "GET",
  POST: "POST",
  DELETE: "DELETE",
  PATCH: "PATCH",
  PUT: "PUT"
}
// Customizable Area End