import React from "react";
// Customizable Area Start
import DeleteAccountController, { Props, configJSON } from "./DeleteAccountController.web";
import Sidebar from "../../../settings2/src/components/Sidebar.web";
import { CommonButton, CustomAlert, ScreenTitleBox } from "../../../../blocks/utilities/src/ResolveCondition.web";
import { Box, styled } from '@mui/material';
import { deleteOrange, goodBye } from "../assets";
// Customizable Area End

export default class DeleteAccount extends DeleteAccountController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            <>
                <Sidebar props={this.props} selectedScreen={"AccountSettings"} showSettingsSidebar>
                    <ScreenTitleBox title={configJSON.ENUM.ACCOUNT_SETTINGS_SCREEN_TITLE} />
                    <Wrapper>
                        <ContentBox>
                            <SubTitle dangerouslySetInnerHTML={{ __html: configJSON.ENUM.DELETE_ACCOUNT_DESC }} />
                            <LabelImg src={goodBye} />
                            <DeleteAccountButton
                                data-testid="delete-button"
                                disableRipple
                                onClick={this.deleteAccount}
                                loadingStyle={style.loadingStyle}
                                isLoading={this.state.apiLoading}
                                label="Delete Account"
                                rightIcon={<img src={deleteOrange} />}
                            />
                        </ContentBox>
                    </Wrapper>
                    <CustomAlert
                        data-testid="custom-alert"
                        open={this.state.alertOpen}
                        message={this.state.alertMsg}
                        handleClose={this.closeAlert}
                    />
                </Sidebar>
            </>
        )
    }
}
// Customizable Area Start
const Wrapper = styled(Box)({
    userSelect: "none",
    display: "flex",
    marginTop: "236px",
    justifyContent: "center",
    "@media (max-width: 1280px)": {
        margin: "150px 0"
    }
})
const ContentBox = styled(Box)({
    maxWidth: "327px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0 32px"
})
const SubTitle = styled(Box)({
    fontFamily: "roobert-regular",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "17.57px",
    color: "#2F2F2F",
    textAlign: "center"
})

const LabelImg = styled("img")({
    marginTop: "34px"
})

const DeleteAccountButton = styled(CommonButton)({
    marginTop: "40px",
    width: "100%",
    maxWidth: "279px",
    height: "48px",
    padding: "0 16px",
    borderRadius: "24px",
    backgroundColor: "#F4F4F4",
    boxShadow: "2.18px 2.18px 6.54px 0px #AEAEC066,-2.18px -2.18px 6.54px 0px #FFFFFF",
    textTransform: 'none',
    color: "#F27C66",
    fontWeight: '600',
    fontSize: "16px",
    lineHeight: "16px",
    fontFamily: "roobert-regular",
    "&:hover": {
        backgroundColor: "#F4F4F4"
    }
})

const style = {
    loadingStyle: {
        color: "#2F2F2F",
        height: "24px",
        width: "24px"
    }
}
// Customizable Area End
