import React from "react";
// Customizable Area Start
import ContactUsController, { Props, configJSON } from "./ContactUsController.web";
import Sidebar from "../../../../blocks/settings2/src/components/Sidebar.web";
import { KeyboardArrowDown } from '@mui/icons-material';
import { ScreenTitleBox } from "../../../../blocks/utilities/src/ResolveCondition.web";
import { Box, Button, Grid, MenuItem, styled, TextField, Typography } from '@mui/material';
import { Form, Formik } from "formik";
import { attachment_button } from "../assets";
import * as Yup from "yup";

// Customizable Area End

export default class ContactUs extends ContactUsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Invalid email address").required("Email is required"),
    subject: Yup.string().required("Subject is required"),
    message: Yup.string().max(500, "Message can't exceed 500 characters").required("Message is required"),
    attachment: Yup.mixed()
      .nullable()
      .test("fileSize", "File size too large", value => {
        if (!value) return true;
        return value && value.size <= 5 * 1024 * 1024;
      }),
  });
  // Customizable Area End

  render() {
    // Customizable Area Start
    const Description = { __html: configJSON.ENUM.CONTACT_PAGE_SUBTITLE };
    // Customizable Area End
    return (
      <>
        <Sidebar props={this.props} selectedScreen={"ContactUs"} showSettingsSidebar>
          <ScreenTitleBox title={configJSON.ENUM.CONTACTUS_SCREEN_TITLE} />
            <Wrapper>
              <FlexBox>
                <ContentBox>
                  <Grid container>
                    <Grid sm={12} md={7} item>
                      <Box className="contact-us-left-top">
                        <Box className="contact-us-left">
                          <Typography className="contact-us-left-h2" variant="h2">
                            We’re Here to Help!
                          </Typography>
                          <div className="contact-us-left-body1" dangerouslySetInnerHTML={Description} />
                          <Typography className="contact-us-left-h4" variant="h4">
                            We typically respond within 48 hours.
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid sm={12} md={5} item>
                      <Box className="contact-us-right">
                        <Box className="contactForm"
                        //  maxWidth={"305px"}
                        >
                          <Box marginTop={"24px"}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "1rem",
                            }}
                          >
                            <Formik
                              initialValues={{ name: "", email: "", subject: "", message: "", attachment: null }}
                              validationSchema={this.validationSchema}
                              onSubmit={this.customFunction}
                            >
                              {({ setFieldValue, setFieldTouched, handleChange, handleBlur, values, errors, touched, handleSubmit }) => (
                                <Form onSubmit={handleSubmit} data-test-id="contact-btn">
                                  <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }} >
                                    <CustomField
                                      type="text"
                                      data-testid="name"
                                      name="name"
                                      label="Name"
                                      value={values.name}
                                      onChange={(e) => {
                                        handleChange(e);
                                        setFieldTouched("name", true, false);
                                      }}
                                      onBlur={handleBlur}
                                      error={touched.name && Boolean(errors.name)}
                                    />
                                    {touched.name && errors.name && (
                                      <Typography sx={{ color: "#d32f2f", fontSize: "12px", mt: 0.5, paddingLeft: "10px" }}>
                                        {errors.name}
                                      </Typography>
                                    )}
                                    <CustomField
                                      type="email"
                                      name="email"
                                      data-testid="email"
                                      label="Email Address"
                                      value={values.email}
                                      onChange={(e) => {
                                        handleChange(e);
                                        setFieldTouched("email", true, false);
                                      }}
                                      onBlur={handleBlur}
                                      error={touched.email && Boolean(errors.email)}
                                    />
                                    {touched.email && errors.email && (
                                      <Typography sx={{ color: "#d32f2f", fontSize: "12px", mt: 0.5, paddingLeft: "10px" }}>
                                        {errors.email}
                                      </Typography>
                                    )}
                                    <CustomField
                                      name="subject"
                                      label="Subject"
                                      data-testid="subject"
                                      select
                                      SelectProps={{
                                        IconComponent: KeyboardArrowDown,
                                        MenuProps: {
                                          PaperProps: {
                                            sx: {
                                              backgroundColor: "#F4F4F4",
                                              boxShadow: "2px 2px 6px rgba(174, 174, 192, 0.4) inset",
                                              borderRadius: "24px",
                                              border: "#E4E6EA",
                                              width: "305px",
                                              overflow: "hidden",
                                              "& .MuiMenuItem-root": {
                                                fontSize: "14px",
                                                padding: "12px 16px",
                                                color: "#717C90",
                                                fontFamily: "roobert-regular",
                                                "&.Mui-selected": {
                                                  backgroundColor: "transparent !important",
                                                  fontWeight: 600
                                                },
                                                "&:hover": {
                                                  backgroundColor: "#EAEAEA",
                                                },
                                              },
                                            },
                                          },
                                        },
                                      }}
                                      onChange={(e) => {
                                        handleChange(e);
                                        setFieldTouched("subject", true, false);
                                      }}
                                      onBlur={handleBlur}
                                      error={touched.subject && Boolean(errors.subject)}
                                      InputProps={{
                                        sx: {
                                          cursor: "pointer"
                                        },
                                      }}
                                      style={{ position: "relative" }}
                                    >
                                      <MenuItem value="General Inquiry">General Inquiry</MenuItem>
                                      <MenuItem value="Technical Support">Technical Support</MenuItem>
                                      <MenuItem value="Feedback and Suggestions">Feedback and Suggestions</MenuItem>
                                      <MenuItem value="Just to say Hello">Just to say Hello</MenuItem>
                                    </CustomField>
                                    {touched.subject && errors.subject && (
                                      <Typography sx={{ color: "#d32f2f", fontSize: "12px", mt: 0.5, paddingLeft: "10px" }}>
                                        {errors.subject}
                                      </Typography>
                                    )}
                                    <Box style={{ position: "relative" }}>
                                      <TextField className="texMsg"
                                        InputProps={{
                                          style: {
                                            borderRadius: "24px",
                                            fontFamily: "roobert-regular",
                                          },
                                        }}
                                        sx={{
                                          "& .MuiOutlinedInput-root": {
                                            "& fieldset": {
                                              border: "none",
                                            },
                                            "&:hover fieldset": {
                                              border: "none",
                                            },
                                            "&.Mui-focused fieldset": {
                                              border: "none",
                                            },
                                          },
                                          "& .MuiFormLabel-root": {
                                            color: "#ACACAC",
                                            fontSize: "14px",
                                            fontFamily: "roobert-regular"
                                          },
                                          "& .MuiInputLabel-root-MuiInputLabel-root.Mui-focused": {
                                            visibility: "hidden",
                                          },
                                          "& .MuiInputLabel-shrink": {
                                            transform: "translate(14px, -6px) scale(0.85)",
                                            visibility: "hidden",
                                          },
                                        }}
                                        style={{
                                          boxShadow: "2.18px 2.18px 6.54px 0px #AEAEC066 inset, -2.18px -2.18px 6.54px 0px #FFFFFF inset",
                                          width: "279px",
                                          height: "140px",
                                          background: "#F4F4F4",
                                          borderRadius: "24px",
                                        }}
                                        data-testid="message"
                                        name="message"
                                        label="Message"
                                        multiline
                                        rows={4}
                                        value={values.message}
                                        onChange={(e) => {
                                          handleChange(e);
                                          setFieldTouched("message", true, false);
                                        }}
                                        onBlur={handleBlur}
                                        error={touched.message && Boolean(errors.message)}

                                      />
                                      <Typography style={{ position: "absolute", top: 16, width:"30px", right: 28, fontSize: "10px", color: "#ACACAC" }}>
                                        {values.message.length}/500
                                      </Typography>
                                      {touched.message && errors.message && (
                                        <Typography sx={{ color: "#d32f2f", fontSize: "12px", mt: 0.5, paddingLeft: "10px" }}>
                                          {errors.message}
                                        </Typography>
                                      )}
                                    </Box>
                                    <Box style={{ display: "flex", alignItems: "center" }}>
                                      <ContactImg width={"100%"} style={{display: "flex",
  justifyContent: "space-between"}}>
                                        <label className="textLabel" htmlFor="attachment" style={{ cursor: "pointer", color: "#ACACAC", textDecoration: "underline" }}>
                                          Attachment (optional)
                                        </label>
                                        <div className="mergeDiv">
                                          {this.state.fileName && (
                                            <span
                                            className="uploadImgText"
                                            >
                                              {this.state.fileName}
                                            </span>
                                          )}
                                          <img src={attachment_button} className="imgIconContact" style={{ color: "#ACACAC", }} />

                                        </div>
                                      </ContactImg>
                                      <input
                                        data-testid="image"
                                        id="attachment"
                                        name="attachment"
                                        type="file"
                                        style={{ display: "none" }}
                                        onChange={(e: any) => {
                                          let file = e.target.files[0]
                                          setFieldValue("attachment", file);
                                          this.setState({ fileName: file.name })
                                        }}
                                      />
                                    </Box>
                                    <ContactInfo>
                                      <Typography className="infoText" variant="body2" color="#ACACAC" style={{ fontSize: "12px", lineHeight: "13.18px", fontFamily: "roobert-regular" }}>
                                        Your information will only be used to respond to your inquiry. See our <span style={{ color: "#ACACAC", textDecoration: "underline" }}>Privacy Policy</span> for more details.
                                      </Typography>
                                    </ContactInfo>
                                    
                                  </Box>
                                  <SubmitButtonContactUs type="submit">
                                      Submit
                                    </SubmitButtonContactUs>
                                </Form>
                              )}
                            </Formik>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>

                </ContentBox>
              </FlexBox>
            </Wrapper>
        </Sidebar>
      </>
    )
  }
}
// Customizable Area Start
const SubmitButtonContactUs = styled(Button)({
  width: "311px",
  "@media (max-width: 1023px)": {
    width:"100%",
  },
  height: "48px",
  color: "F27C66",
  padding: "10px",
  borderRadius: "30px",
  boxShadow: "2.18px 2.18px 6.54px 0px #AEAEC066, -2.18px -2.18px 6.54px 0px #FFFFFF",
  textTransform: 'none',
  fontWeight: '600',
  fontFamily: "roobert-regular",
  "&:hover": {
    backgroundColor: "#2F2F2F",
    color: "#fff",
  },
})
const CustomField = styled(TextField)({
  boxShadow: "2.18px 2.18px 6.54px 0px #AEAEC066 inset, -2.18px -2.18px 6.54px 0px #FFFFFF inset",
  height: "48px",
  width: "279px",
  "@media (max-width: 1023px)": {
    width:"100%"
  },
  background: "#F4F4F4",
  borderRadius: "24px",
  "& .MuiOutlinedInput-root": {
    borderRadius: "24px",
    height: "48px",
    width: "100%",
    fontSize: "14px",
    fontFamily: "roobert-regular",
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "none",
    },
  },
  "& .MuiFormLabel-root": {
    color: "#ACACAC",
    fontSize: "14px",
    LineWeight: "16px",
    fontFamily: "roobert-regular",
  },
  "& .MuiInputLabel-root-MuiInputLabel-root.Mui-focused": {
    visibility: "hidden",
  },
  "& .MuiInputLabel-shrink": {
    transform: "translate(14px, -6px) scale(0.85)",
    visibility: "hidden",
  },
})
const Wrapper = styled(Box)({
  userSelect: "none",
  padding: "48px 32px"
})
const FlexBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
})
const ContactInfo = styled(Box)({
  "& .infoText":{
    width:"288px",
    fontSize:"12px",
    fontWeight:600,
    color: "#ACACAC",
    marginBottom:"26px",

    "@media (max-width: 1023px)": {
      width:"auto"
    },
  }
  
})
const ContactImg = styled(Box)({
  width:"279px",
  "@media (max-width: 1023px)": {
    width:"100%"
  },
  "& .mergeDiv":{
width:"80px",
display:"flex",
justifyContent:"flex-end"
  },
"& .textLabel":{
  fontSize:"12px",
  marginLeft:"16px",
  fontWeight:600,
  fontFamily: "roobert-regular",
  "@media (max-width: 1023px)": {
    width:"auto",
    marginLeft:"0px",
  },
},
"& .imgIconContact":{
    width:"16px",
    height:"16px",
    marginLeft:"15px"
  },
  "& .uploadImgText":{
    color: "#717C90",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "45px",
    height:"13px",
    fontSize:"12px",
    fontWeight:600,
    display:"block",
    lineHeight:"13.18px"

  }
})
const ContentBox = styled(Box)({
  width: "100%",
  "& .contact-us-right":{
 width:"311px", display:"flex", justifyContent:"right",
 "@media (max-width: 1023px)": {width:"100%",justifyContent:"center"}
  },
  "& .contactForm": {
    maxWidth: "295px",
    width:"295px"
  },
  "& .texMsg":{
"@media (max-width: 1023px)": {
    width:"100% !important",
  },},
  "@media (max-width: 1023px)": {
    width:"100%",
    "& .contactForm": {
      maxWidth: "100%",
      width:"100%"
    },
  },
  "& .contact-us-left-top": {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    height: "100%"
  },
  "& .contact-us-left": {
    width:"327px",
    maxWidth: "327px",
    "@media (max-width: 1023px)": {
      width:"100%",
    }
  },
  "& .contact-us-left-h2 ": {
    width: "100%",
    lineHeight: "26.35px",
    fontSize: "24px",
    fontFamily: "roobert-regular",
    fontWeight: 600,
  },
  "& .contact-us-left-body1": {
    fontSize: "16px",
    marginTop: "16px",
    fontWeight: 400,
    color: "#2F2F2F",
    fontFamily: "roobert-regular",
  },
  "& .contact-us-left-h4": {
    fontSize: "14px",
    marginTop: "16px",
    fontWeight: 400,
    color: "#ACACAC",
    fontFamily: "roobert-regular",
  }
})

// Customizable Area End
