Object.defineProperty(exports, "__esModule", {
  value: true,
});

//APi Methods
exports.apiMethod = "GET";
exports.apiContentType = "application/json";
exports.apiEndPoint = "activity_feed/activity_feeds";
exports.apiContentType = "application/json";

// Customizable Area Start
exports.loadLabel = "Load more...";
exports.headerLabel = "Recent Activity";
exports.done = "Done";

exports.ENUM = {
  CARD_TITLE: "My top 10 flower shops in Paris is amazing",
  POST_TITLE: "My top 10 flower shops in Paris",
  POST_DESC: "Here is a space about all my favourite flower shops I visited when I travelled to paris this ..."
}
// Customizable Area End
