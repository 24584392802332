// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

export const configJSON = require("../config");

export interface Props {
    navigation: any;
    id: string;
}

interface S {
    apiLoading: boolean,
    termsPolicyData: string
}

interface SS { }

interface ITermsPolicyResponse {
    data: ITandCResponseData
}

interface ITandCResponseData {
    id: number
    account_id: null | string
    created_at: string
    updated_at: string
    description: string
}
// Customizable Area End

export default class TermsPoliciesController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    termsPolicyApiCallId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceDataMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        ];

        this.state = {
            apiLoading: false,
            termsPolicyData: ""
        }
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId && responseJson) {
                if (apiRequestCallId == this.termsPolicyApiCallId) {
                    this.termsPolicyApiResp(responseJson)
                }
            }
        }
        // Customizable Area End
    }
    // Customizable Area Start
    termsPolicyApiResp = (responseJson: ITermsPolicyResponse) => {
        this.setState({ apiLoading: false })
        if (responseJson && responseJson.data && responseJson.data.description) {
            const description = responseJson.data.description
            this.setState({ termsPolicyData: description });
        } else {
            this.setState({ termsPolicyData: "" });
        }
    }

    async componentDidMount() {
        this.getTermsPolicy()
    }

    getTermsPolicy = () => {
        this.setState({ apiLoading: true })

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.termsPolicyApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.ENDPOINTS.GET_TERMS_POLICY);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.API_METHOD.GET);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End
}