// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import * as Yup from "yup";
import { getTruthyString } from "../../../../blocks/utilities/src/ResolveCondition.web";
import MergeEngineUtilities from "../../../../blocks/utilities/src/MergeEngineUtilities";
export const configJSON = require("../config");

export interface Props {
    navigation: any;
    id: string;
}

interface S {
    showPassword: boolean,
    showConfirmPassword: boolean,
    apiLoading: boolean,
    alertMsg: string
}

interface SS { }

interface IApiResp {
    error?: string,
    data: {}
}
// Customizable Area End

export default class SetNewPasswordController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    setNewPasswordApiCallId: string = ""

    initialFormValues = {
        password: "",
        confirm_password: ""
    }
    validationSchema = Yup.object().shape({
        password: Yup.string().min(8, "Password must be at least 8 characters long.").required("Password is required").trim(),
        confirm_password: Yup.string().min(8, "Password must be at least 8 characters long.")
            .oneOf([Yup.ref("password"), null], "Passwords must match")
            .required("Confirm Password is required").trim(),
    });
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceDataMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        ];

        this.state = {
            showPassword: false,
            showConfirmPassword: false,
            apiLoading: false,
            alertMsg: ""
        }
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId && responseJson) {
                if (apiRequestCallId === this.setNewPasswordApiCallId) {
                    this.setNewPasswordResp(responseJson)
                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    setNewPasswordResp = (responseJson: IApiResp) => {
        this.setState({ apiLoading: false })
        if (responseJson.error) {
            this.toggleAlert("Something went wrong!")
            return
        }
        if (responseJson.data) {
            this.toggleAlert("Password updated Successfully!")
            MergeEngineUtilities.navigateToScreen("EmailAccountLoginBlockWeb", this.props)
        }
    }

    togglePassword = () => {
        this.setState((prevState) => ({
            showPassword: !prevState.showPassword,
        }));
    };
    toggleConfirmPassword = () => {
        this.setState((prevState) => ({
            showConfirmPassword: !prevState.showConfirmPassword,
        }));
    };

    setNewPasswordApi = (values: typeof this.initialFormValues) => {
        const queryParams = new URLSearchParams(window.location.search);
        const token = getTruthyString(queryParams.get("token"));
        const email = getTruthyString(queryParams.get("email"));

        this.setState({ apiLoading: true })
        const header = {
            "content-type": "application/json",
        };
        const body = {
            "data": {
                "email": email,
                "token": token,
                "new_password": values.password,
            }
        }

        const endpoint = configJSON.ENDPOINTS.SET_NEW_PASSWORD

        const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.setNewPasswordApiCallId = reqMessage.messageId;
        reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
        reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.API_METHOD.POST);
        runEngine.sendMessage(reqMessage.id, reqMessage);
    }
    toggleAlert = (msg: string) => {
        this.setState({
            alertMsg: msg
        })
    }
    // Customizable Area End
}