import React from "react";
// Customizable Area Start
import FAQPageController, { Props, configJSON } from "./FAQPageController.web";
import { Box, CircularProgress, Typography, styled } from '@mui/material';
import HeaderSimple from "../../../../blocks/termsconditions/src/components/HeaderSimple.web";
// Customizable Area End

export default class FAQPage extends FAQPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderLoader = () => {
        return (
            <LoaderBox><StyledLoader /></LoaderBox>
        )
    }
    renderContent = () => {
        const { apiLoading, formattedFaqData } = this.state

        if (apiLoading && !formattedFaqData.length) {
            return this.renderLoader()
        }
        if (!apiLoading && formattedFaqData.length) {
            return (
                <>
                    {formattedFaqData.map((item) => {
                        return (
                            <Box>
                                <StyledFaqTitle>{item.title}</StyledFaqTitle>
                                <Box margin={"24px 0 40px 16px"}>
                                    {item.questions?.map((record) => {
                                        const questionHtml = { __html: record.question }
                                        const answerHtml = { __html: record.answer }
                                        return (
                                            <Box>
                                                <StyledFaqQuestion dangerouslySetInnerHTML={questionHtml} />
                                                <StyledFaqAnswer dangerouslySetInnerHTML={answerHtml} />
                                            </Box>
                                        )
                                    })}
                                </Box>
                            </Box>
                        )
                    })}
                </>

            )
        }
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const sanitizedDescription = { __html: configJSON.ENUM.FAQ_PAGE_SUBTITLE };
        // Customizable Area End
        return (
            <>
                <StyledWrapper>
                    <HeaderSimple thisprop={this.props} />
                    <Wrapper>
                        <SubWrapper>
                            <LeftBox>
                                <StyledTitle>{configJSON.ENUM.FAQ_PAGE_TITLE}</StyledTitle>
                                <StyledSubTitle dangerouslySetInnerHTML={sanitizedDescription} />
                            </LeftBox>
                            <RightBox>
                                {this.renderContent()}
                            </RightBox>
                        </SubWrapper>
                    </Wrapper>
                </StyledWrapper>
            </>
        )
    }
}
// Customizable Area Start

const StyledWrapper = styled(Box)({
    background: "#F4F4F4",
    minHeight: "100vh",
    userSelect: "none"
});
const StyledTitle = styled(Typography)({
    color: "#2F2F2F",
    fontWeight: "600",
    fontSize: "30px",
    lineHeight: "32px",
    fontFamily: "roobert-regular",
    "@media (max-width: 480px)": {
        fontSize: "24px",
        lineHeight: "normal"
    }
})

const StyledSubTitle = styled(Box)({
    marginTop: "16px",
    maxWidth: "304px",
    width: "100%",
    fontWeight: "400",
    color: "#2F2F2F",
    fontSize: "14px",
    lineHeight: "15.37px",
    fontFamily: "roobert-regular",
    "@media (max-width: 1024px)": {
        maxWidth: "unset",
    }
})

const Wrapper = styled(Box)({
    background: "#F4F4F4",
    padding: "48px 88px",
    "@media (min-width: 1024px) and (max-width: 1439px)": {
        padding: "48px 66px",
    },
    "@media (min-width: 768px) and (max-width: 1023px)": {
        padding: "48px",
    },
    "@media (min-width: 480px) and (max-width: 767px)": {
        padding: "48px 32px",
    },
    "@media (max-width: 479px)": {
        padding: "48px 24px",
    }
})
const SubWrapper = styled(Box)({
    columnGap: "88px",
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width: 1024px)": {
        flexWrap: "wrap"
    }
})

const LeftBox = styled(Box)({
    minWidth: "304px",
    flex: 1,
    padding: "100px 0",
    "@media (max-width: 1024px)": {
        padding: "0 0 50px 0",
        minWidth: "unset",
    }
})

const RightBox = styled(Box)({
    width: "100%",
    maxWidth: "747px",
    padding: "0 8px",
    maxHeight: "60vh",
    overflowY: "auto",
    "@media (max-width: 1024px)": {
        maxHeight: "unset",
        maxWidth: "unset",
        overflowY: "hidden",
        padding: "0",
    }
})
const LoaderBox = styled(Box)({
    display: "flex",
    justifyContent: 'center'
})

const StyledLoader = styled(CircularProgress)({
    color: "#717C90",
    height: "32px",
    width: "32px",
})

const StyledFaqTitle = styled(Typography)({
    fontWeight: "600",
    color: "#717C90",
    fontSize: "24px",
    lineHeight: "26.35px",
    fontFamily: "roobert-regular",
})
const StyledFaqQuestion = styled(Box)({
    fontWeight: "600",
    color: "#717C90",
    fontSize: "16px",
    lineHeight: "17.57px",
    fontFamily: "roobert-regular",
})
const StyledFaqAnswer = styled(Box)({
    fontWeight: "400",
    color: "#717C90",
    fontSize: "16px",
    lineHeight: "17.57px",
    fontFamily: "roobert-regular",
})
// Customizable Area End