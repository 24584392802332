import React from "react";
// Customizable Area Start
import ForgotPasswordPageController, { Props, configJSON } from "./ForgotPasswordPageController.web";
import HeaderSimple from "../../../../blocks/termsconditions/src/components/HeaderSimple.web";
import Footer from "../../../../components/src/Footer.web";
import { AppQR } from "../assets";
import { Apple } from '@mui/icons-material';
import { Box, Typography, Button, styled, TextField } from '@mui/material';
import { Formik, Form } from "formik";
import { CommonButton, CustomAlert, ResolveCondition } from "../../../../blocks/utilities/src/ResolveCondition.web";
// Customizable Area End

export default class ForgotPasswordPage extends ForgotPasswordPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            <>
                <StyledWrapper>
                    <HeaderSimple thisprop={this.props} />
                    <ContentWrapper>
                        <LeftBox>
                            <ScreenTitle>Stories that{" "}<span>Matter</span></ScreenTitle>
                            <ScreenSubTitle>{configJSON.ENUM.SUBTITLE}</ScreenSubTitle>
                        </LeftBox>

                        <MiddleBox>
                            <Box className="middle-box-wrapper">
                                <BoxTitleFont marginBottom={"48px"} textAlign={"center"}>{configJSON.ENUM.HAVE_ACCOUNT}</BoxTitleFont>
                                <Formik
                                    initialValues={this.initialFormValues}
                                    validationSchema={this.validationSchema}
                                    onSubmit={(values) => { this.sendEmailApi(values.email) }}
                                >
                                    {({ values, errors, handleChange }) => (
                                        <Form>
                                            <CustomField
                                                name="email"
                                                label="Email"
                                                inputProps={{ maxLength: 30 }}
                                                data-testid="email-field"
                                                value={values.email}
                                                onChange={(event) => {
                                                    this.setFieldError("")
                                                    handleChange(event)
                                                }}
                                                autoComplete="off"
                                            />
                                            {ResolveCondition(!!errors?.email, <ErrorMsg>{errors?.email}</ErrorMsg>, <></>)}
                                            {ResolveCondition(!!this.state.emailFieldError, <ErrorMsg>{this.state.emailFieldError}</ErrorMsg>, <></>)}

                                            <StyledBlackButton variant="contained" sx={{ marginTop: "224px" }}
                                                type="submit"
                                                loadingStyle={loadingStyle}
                                                isLoading={this.state.sendEmailApiLoading}
                                                label={ResolveCondition(!!this.state.isEmailSent, "Sent", "Send")}
                                            />
                                        </Form>
                                    )}
                                </Formik>
                            </Box>
                        </MiddleBox>

                        <RightBox>
                            <Box className="right-box-wrapper">
                                <BoxTitleFont marginBottom={"24px"}>{configJSON.ENUM.DONT_HAVE_ACCOUNT}</BoxTitleFont>
                                <StyledButton
                                    data-testid="signup-btn"
                                    variant="contained"
                                    onClick={() => this.goToScreen("EmailAccountRegistration")}
                                >
                                    Sign Up
                                </StyledButton>
                                <BoxTitleFont style={{ fontSize: "14px" }}>or</BoxTitleFont>
                                <Box marginTop={"13px"} marginBottom={"4px"}>
                                    <img src={AppQR} alt="app_qr" />
                                </Box>

                                <StyledBlackButton
                                    variant="contained"
                                    label="Download the App"
                                    leftIcon={<Apple className="icon" />}>
                                </StyledBlackButton>
                            </Box>
                        </RightBox>

                    </ContentWrapper>
                    <Footer prop={this.props} />
                </StyledWrapper >
                <CustomAlert data-testid="custom-alert"
                    open={!!this.state.alertMsg}
                    message={this.state.alertMsg}
                    handleClose={() => this.triggerAlert("")}
                />
            </>
        )
    }
}
// Customizable Area Start
const StyledWrapper = styled(Box)({
    background: "#F4F4F4"
})
const ContentWrapper = styled(Box)({
    display: "flex",
    minHeight: "calc(100vh - 145px)",
    gap: "50px",
    padding: "76px 88px",
    "@media (max-width: 1080px)": {
        flexWrap: "wrap",
    },
    "@media (min-width: 1024px) and (max-width: 1439px)": {
        padding: "66px",
    },
    "@media (min-width: 768px) and (max-width: 1023px)": {
        padding: "48px",
    },
    "@media (min-width: 480px) and (max-width: 767px)": {
        padding: "32px",
    },
    "@media (max-width: 479px)": {
        padding: "24px",
    },
    "@media (max-width: 600px)": {
        flexDirection: "column"
    },
})
const LeftBox = styled(Box)({
    minWidth: "279px",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    flex: 1,
    padding: "72px 0",
    "@media (max-width: 671px)": {
        minWidth: "unset",
        padding: "12px 0 48px 0",
    }
})
const MiddleBox = styled(Box)({
    flex: 1,
    display: "flex",
    justifyContent: "center",
    "& .middle-box-wrapper": {
        minWidth: "279px",
        maxWidth: "279px",
        "@media (max-width: 480px)": {
            width: "100%",
            minWidth: "unset",
        },
    }
})
const ScreenTitle = styled(Typography)({
    color: "#2F2F2F",
    fontWeight: "600",
    fontSize: "30px",
    lineHeight: "32px",
    fontFamily: 'roobert-regular',
    "@media (max-width: 480px)": {
        fontSize: "24px",
    },
    "& span": {
        background: "linear-gradient(308.32deg, #F27C66 32.08%, #FECD8F 55.96%)",
        backgroundClip: "text",
        WebkitBackgroundClip: "text",
        color: "transparent",
        fontFamily: "olten",
        fontSize: "30px",
        lineHeight: "36px",
        "@media (max-width: 480px)": {
            fontSize: "24px",
        },
    }
})
const ScreenSubTitle = styled(Typography)({
    color: "#2F2F2F",
    maxWidth: "240px",
    fontFamily: "roobert-regular",
    fontSize: "14px",
    lineHeight: "15.37px",
    fontWeight: "400",
    marginTop: "16px",
})

const RightBox = styled(Box)({
    flex: "1",
    "@media (max-width: 1080px)": {
        display: "flex",
        justifyContent: "center"
    },
    "& .right-box-wrapper": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "24px",
        flex: 1,
        maxWidth: "279px",
        minWidth: "279px",
        "@media (max-width: 480px)": {
            width: "100%",
            minWidth: "unset",
        },
    }
})
const BoxTitleFont = styled(Typography)({
    color: "#2F2F2F",
    fontSize: "20px",
    lineHeight: "24px",
    fontWeight: "600",
    fontFamily: "roobert-regular"
})
const StyledButton = styled(Button)({
    width: "100%",
    height: "48px",
    textTransform: "none",
    padding: "10px 20px",
    borderRadius: "24px",
    backgroundColor: "#F4F4F4",
    boxShadow: "2.18px 2.18px 6.54px 0px #AEAEC066, -2.18px -2.18px 6.54px 0px #FFFFFF",
    position: "relative",
    fontSize: "16px",
    fontWeight: "600",
    fontFamily: "roobert-regular",
    color: "#F27C66",
    "&:hover": {
        color: "#F27C66",
        backgroundColor: "#F4F4F4",
        boxShadow: "2.18px 2.18px 6.54px 0px #AEAEC066 inset, -2.18px -2.18px 6.54px 0px #FFFFFF inset",
    },
    "&::before": {
        content: '""',
        position: "absolute",
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        padding: "1px",
        borderRadius: "24px",
        pointerEvents: "none",
        background: "linear-gradient(122.98deg, #FECD8F 30.21%, #F27C66 72.67%)",
        WebkitMask: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
        WebkitMaskComposite: "xor",
        maskComposite: "exclude",
    },
})

const StyledBlackButton = styled(CommonButton)({
    width: "100%",
    height: "48px",
    padding: "10px 17px",
    backgroundColor: "#F4F4F4",
    color: "#2F2F2F",
    borderRadius: "30px",
    boxShadow: "2.18px 2.18px 6.54px 0px #AEAEC066, -2.18px -2.18px 6.54px 0px #FFFFFF",
    textTransform: 'none',
    fontWeight: '600',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    fontFamily: "roobert-regular",
    fontSize: "16px",
    "&:hover": {
        backgroundColor: "#2F2F2F",
        color: "#fff",
        "& .MuiSvgIcon-root": {
            color: "#FFFFFF",
        },
    },
    "& .icon": {
        width: "18px",
        height: "18px",
        color: "#2F2F2F",
    }
})

const CustomField = styled(TextField)({
    boxShadow: "2.18px 2.18px 6.54px 0px #AEAEC066 inset, -2.18px -2.18px 6.54px 0px #FFFFFF inset",
    height: "48px",
    background: "#F4F4F4",
    borderRadius: "24px",
    width: "100%",
    "& .MuiOutlinedInput-root": {
        borderRadius: "24px",
        color: "#2F2F2F",
        fontSize: "20px",
        lineHeight: "20px",
        fontWeight: "600",
        fontFamily: "roobert-regular",
        "& fieldset": {
            border: "none",
        },
        "&.Mui-focused fieldset": {
            border: "none",
        },
        "&:hover fieldset": {
            border: "none",
        },
    },
    "& .MuiInputLabel-root": {
        color: "#ACACAC",
        fontFamily: "roobert-regular",
        top: -2,
        fontSize: "14px",
        fontWeight: "600",
        "&.Mui-focused": {
            opacity: 0,
        },
    },
    "& .MuiInputLabel-root-MuiInputLabel-root.Mui-focused": {
        visibility: "hidden",
    },
    "& .MuiInputLabel-shrink": {
        transform: "translate(14px, -6px) scale(0.85)",
        visibility: "hidden",
    },
    "& .MuiInputBase-input": {
        boxSizing: "border-box",
        display: "flex",
        padding: "12px 16px",
        height: "48px",
        alignItems: "center",
        fontSize: "16px",
        lineHeight: "19.96px",
        fontWeight: "600",
    },
})
const ErrorMsg = styled(Typography)({
    padding: "8px 16px 0 16px",
    color: "#EA4335",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "13.18px",
    fontFamily: "roobert-regular"
})
const loadingStyle = {
    color: "#2F2F2F",
    height: "24px",
    width: "24px"
} as React.CSSProperties
// Customizable Area End
