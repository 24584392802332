import React from "react";

// Customizable Area Start
import { Box, Typography, Button, styled, TextField, Checkbox, FormControlLabel } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from "yup";
import { Apple } from '@mui/icons-material';
import { qr_1, tick } from "./assets";
import { ConditionJsx } from "../../utilities/src/ConditionJsx.web";
import Footer from "../../../components/src/Footer.web";
import HeaderSimple from "../../../blocks/termsconditions/src/components/HeaderSimple.web";
import EmailAccountLoginController, { Props, configJSON } from "./EmailAccountLoginController";
// Customizable Area End

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  validationSchema = Yup.object({
    user: Yup.string()
      .required("Username is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
  });

  renderFieldError = (formError: string | undefined | null, fieldName: string) => {
    const {loginFailed} = this.state
    let error = formError
    if(fieldName == "user" && loginFailed.includes(configJSON.ENUM.ACCOUNT_NOT_FOUND)){
      error = configJSON.ENUM.USERNAME_ERROR
    }
    if(fieldName == "password" && loginFailed.includes(configJSON.ENUM.INCORRECT_PASSWORD)){
      error = configJSON.ENUM.PASSWORD_ERROR
    }
    return ConditionJsx(!!error,
      <>
        <ErrorBox>
          <Typography>{error}</Typography>
        </ErrorBox>
      </>,
      <></>)
  }

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <>
        <StyledWrapper>
          <HeaderSimple thisprop={this.props} />

          <ContentWrapper>
            <FirstBox>
              <ScreenTitle>Stories that{" "}<span>Matter</span></ScreenTitle>
              <ScreenSubTitle>{configJSON.ENUM.SCREEN_SUBTITLE}</ScreenSubTitle>
            </FirstBox>

            <Formik
              initialValues={this.state.formValues}
              enableReinitialize={true}
              onSubmit={(values) => this.loginValuesSubmit(values)}
              validationSchema={this.validationSchema}
            >
              {({ values, errors, setFieldValue }) => (
                <>
                  <FormTag>
                    <BoxTitleFont marginBottom={"32px"}>{configJSON.ENUM.HAVE_ACCOUNT}</BoxTitleFont>
                    <Box>
                      <CustomField
                        name="user"
                        label="Username or email"
                        inputProps={{
                          maxLength: 30,
                        }}
                        onChange={(event) => {
                          setFieldValue("user", event.target.value)
                          this.setState({ loginFailed: ""})
                        }}
                        value={values.user}
                        data-testid="username-field"
                        autoComplete="off"
                      />
                      {this.renderFieldError(errors.user, "user")}
                    </Box>

                    <Box>
                      <CustomField
                        autoComplete="off"
                        name="password"
                        type="password"
                        label="Password"
                        onChange={(event) => {
                          setFieldValue("password", event.target.value)
                          this.setState({ loginFailed: ""})
                        }}
                        value={values.password}
                        data-testid="password-field"
                      />
                      {this.renderFieldError(errors.password, "password")}
                    </Box>

                    <Box display={"flex"} maxWidth={"279px"} width={"100%"}>
                      <StyledFormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.saveLoginInfo}
                            data-test-id="saveLoginInfo-checkbox"
                            onChange={() => this.setState({ saveLoginInfo: !this.state.saveLoginInfo })}
                            inputProps={{ 'aria-label': 'controlled', id: 'saveLoginInfo-checkbox-id' }}
                            className="save-login-checkbox"
                            checkedIcon={<img src={tick} alt="tick" />}
                          />
                        }
                        label={configJSON.ENUM.SAVE_CHECKBOX_LABEL}
                        data-testid="save-login-info"
                      />
                    </Box>
                    <ForgetPassFont onClick={() => this.goToScreen("ForgotPasswordPage")}>I forgot my password</ForgetPassFont>
                    <Button
                      variant="contained"
                      className="black-btn"
                      type="submit"
                    >
                      Log In
                    </Button>
                  </FormTag>

                  <ThirdBoxWrapper>
                    <ThirdBox>
                      <BoxTitleFont marginBottom={"24px"}>{configJSON.ENUM.DONT_HAVE_ACCOUNT}</BoxTitleFont>
                      <StyledButton
                        id="signup-btn"
                        variant="contained"
                        onClick={() => this.goToScreen("EmailAccountRegistration")}
                        sx={{ maxWidth: "279px" }}
                      >
                        Sign Up
                      </StyledButton>
                      <BoxTitleFont style={{ fontSize: "14px" }}>or</BoxTitleFont>
                      <Box marginTop={"13px"} marginBottom={"4px"}>
                        <img src={qr_1} alt="QR code" />
                      </Box>
                      <Button className="black-btn"
                        variant="contained"
                        sx={{ maxWidth: "279px" }}
                      >
                        <Apple sx={{ width: "18px", height: "18px", color: "#2F2F2F", position: "absolute", left: "20px" }} />Download the App
                      </Button>
                    </ThirdBox>
                  </ThirdBoxWrapper>
                </>
              )}
            </Formik>
          </ContentWrapper>
          <Footer prop={this.props} />
        </StyledWrapper>
      </>
      // Customizable Area End
    );
  }

}
const StyledWrapper = styled("div")({
  background: "#F4F4F4",
  "& .black-btn": {
    maxWidth: "279px",
    width: "100%",
    height: "48px",
    padding: "10px 20px",
    backgroundColor: "#F4F4F4",
    color: "#2F2F2F",
    borderRadius: "30px",
    boxShadow: "2.18px 2.18px 6.54px 0px #AEAEC066, -2.18px -2.18px 6.54px 0px #FFFFFF",
    textTransform: 'none',
    fontWeight: 'bold',
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    fontFamily: "roobert-regular",
    fontSize: "16px",
    "&:hover": {
      backgroundColor: "#2F2F2F",
      color: "#fff",
      "& .MuiSvgIcon-root": {
        color: "#FFFFFF",
      },
    },
  },
  "& .save-login-checkbox": {
    width: "24px",
    height: "24px",
    border: "0.5px solid #ACACAC",
    borderRadius: "4px",
    color: '#F4F4F4',
    "&.Mui-checked": {
      color: "#F4F4F4",
      backgroundColor: "#ACACAC",
      borderColor: "none"
    },
  }
});

const ContentWrapper = styled(Box)({
  display: "flex",
  marginBottom: "64px",
  gap: "50px",
  padding: "76px 88px",
  "@media (max-width: 1080px)": {
    flexWrap: "wrap",
  },
  "@media (min-width: 1024px) and (max-width: 1279px)": {
    padding: "66px",
  },
  "@media (min-width: 768px) and (max-width: 1023px)": {
    padding: "48px",
  },
  "@media (min-width: 480px) and (max-width: 767px)": {
    padding: "32px",
  },
  "@media (max-width: 479px)": {
    padding: "24px",
  },
  "@media (max-width: 600px)": {
    flexDirection: "column"
  },
})
const FirstBox = styled(Box)({
  minWidth: "279px",
  display: "flex",
  flexDirection: "column",
  height: "100%",
  flex: 1,
  padding: "72px 0",
  "@media (max-width: 671px)": {
    minWidth: "unset",
    padding: "12px 0 48px 0",
  }
})
const ScreenTitle = styled(Typography)({
  color: "#2F2F2F",
  fontWeight: "600",
  fontSize: "30px",
  lineHeight: "32px",
  fontFamily: 'roobert-regular',
  "@media (max-width: 480px)": {
    fontSize: "24px",
  },
  "& span": {
    background: "linear-gradient(308.32deg, #F27C66 32.08%, #FECD8F 55.96%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    color: "transparent",
    fontFamily: "olten",
    fontSize: "30px",
    lineHeight: "36px",
    "@media (max-width: 480px)": {
      fontSize: "24px",
    },
  }
})
const ScreenSubTitle = styled(Typography)({
  color: "#2F2F2F",
  fontFamily: "roobert-regular",
  maxWidth: "240px",
  fontSize: "14px",
  lineHeight: "15.37px",
  fontWeight: "400",
  marginTop: "16px"
})
const FormTag = styled(Form)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  height: "100%",
  gap: "16px",
  flex: 1
})
const StyledFormControlLabel = styled(FormControlLabel)({
  margin: "0",
  "& .MuiFormControlLabel-label": {
    color: "#ACACAC",
    fontSize: "14px",
    fontFamily: "roobert-regular",
    userSelect: "none",
    marginLeft: "8px"
  },
});

const ForgetPassFont = styled(Typography)({
  color: "#2F2F2F",
  fontSize: "12px",
  lineHeight: "16px",
  fontWeight: "400",
  fontFamily: "roobert-regular",
  textDecoration: "underline",
  cursor: "pointer",
  marginTop: "8px",
  marginBottom: "64px"
})
const ThirdBoxWrapper = styled(Box)({
  flex: "1",
  "@media (max-width: 1080px)": {
    display: "flex"
  },
})
const ThirdBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "24px",
  flex: 1,
  width: "279px",
  "@media (max-width: 480px)": {
    width: "100%",
  },
})

const BoxTitleFont = styled(Typography)({
  color: "#2F2F2F",
  fontSize: "20px",
  lineHeight: "24px",
  fontWeight: "600",
  fontFamily: "roobert-regular"
})

const CustomField = styled(TextField)({
  boxShadow: "2.18px 2.18px 6.54px 0px #AEAEC066 inset, -2.18px -2.18px 6.54px 0px #FFFFFF inset",
  height: "48px",
  background: "#F4F4F4",
  borderRadius: "24px",
  width: "279px",
  "@media (max-width: 320px)": {
    width: "unset",
  },
  "& .MuiOutlinedInput-root": {
    color: "#2F2F2F",
    fontSize: "20px",
    lineHeight: "20px",
    fontWeight: "600",
    fontFamily: "roobert-regular",
    borderRadius: "24px",
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "none",
    },
  },
  "& .MuiInputLabel-root": {
    top: -2,
    color: "#ACACAC",
    fontFamily: "roobert-regular",
    fontSize: "14px",
    fontWeight: "600",
    "&.Mui-focused": {
      opacity: 0,
    },
  },
  "& .MuiInputLabel-root-MuiInputLabel-root.Mui-focused": {
    visibility: "hidden",
  },
  "& .MuiInputLabel-shrink": {
    transform: "translate(14px, -6px) scale(0.85)",
    visibility: "hidden",
  },
  "& .MuiInputBase-input": {
    padding: "12px 16px",
    height: "48px",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    fontSize: "16px",
    lineHeight: "19.96px",
    fontWeight: "600",
  },
})
const ErrorBox = styled(Box)({
  padding: "8px 16px 0 16px",
  "& p": {
    color: "#EA4335",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "13.18px",
    fontFamily: "roobert-regular"
  }
})

const StyledButton = styled(Button)({
  width: "100%",
  height: "48px",
  fontSize: "16px",
  fontWeight: "600",
  fontFamily: "roobert-regular",
  textTransform: "none",
  color: "#F27C66",
  padding: "10px 20px",
  borderRadius: "24px",
  backgroundColor: "#F4F4F4",
  boxShadow: "2.18px 2.18px 6.54px 0px #AEAEC066, -2.18px -2.18px 6.54px 0px #FFFFFF",
  position: "relative",
  "&:hover": {
    color: "#F27C66",
    backgroundColor: "#F4F4F4",
    boxShadow: "2.18px 2.18px 6.54px 0px #AEAEC066 inset, -2.18px -2.18px 6.54px 0px #FFFFFF inset",
  },
  "&::before": {
      content: '""',
      position: "absolute",
      padding: "1px",
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      borderRadius: "24px",
      background: "linear-gradient(122.98deg, #FECD8F 30.21%, #F27C66 72.67%)",
      WebkitMask: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
      pointerEvents: "none",
      WebkitMaskComposite: "xor",
      maskComposite: "exclude",
  },
})