import React from "react";
// Customizable Area Start
import { Box, FormLabel, styled, TextField, Typography } from '@mui/material';
import ChangePasswordController, { Props } from "./ChangePasswordController.web";
import { CommonButton, CustomAlert, ResolveCondition } from "../../../../blocks/utilities/src/ResolveCondition.web";
import { checkBtnBlack, crossRed } from "../assets";
import { Close } from "@mui/icons-material";
import { Formik, Form } from 'formik';

// Customizable Area End

export default class ChangePassword extends ChangePasswordController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderCustomError = (errorMsg: string | undefined, showIcon: boolean = false) => {
        return (
            <>
                {errorMsg ?
                    <ErrorBox>
                        <Typography className="error-font">{errorMsg}</Typography>
                        {ResolveCondition(showIcon, <img src={crossRed} />, <></>)}
                    </ErrorBox>
                    : <></>}
            </>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            <>
                <Wrapper>
                    <CloseBtnBox>
                        <CloseButton
                            label="Close"
                            rightIcon={<CloseIcon />}
                            onClick={this.props.handleClose}
                        />
                    </CloseBtnBox>
                    <CenterBox>

                        <Formik
                            initialValues={this.state.formValues}
                            onSubmit={(values) => this.onSubmit(values)}
                            validationSchema={this.validationSchema}
                            enableReinitialize
                        >
                            {({ values, errors, handleChange }) => {
                                return (
                                    <>
                                        <Form>
                                            <FormBox>
                                                <Box>
                                                    <Box>
                                                        <StyledLabel>Old Password</StyledLabel>
                                                        <CustomField
                                                            label="Please enter your old password"
                                                            value={values.old_password}
                                                            name="old_password"
                                                            onChange={(event) => {
                                                                handleChange(event)
                                                                this.setOldPasswordError("")
                                                            }}
                                                            type="password"
                                                            data-testid="old-password-field"
                                                        />
                                                        {this.renderCustomError(errors.old_password)}
                                                        {this.renderCustomError(this.state.errorOldPassword, true)}

                                                    </Box>
                                                    <Box marginTop={"14px"}>
                                                        <StyledLabel>New Password</StyledLabel>
                                                        <CustomField
                                                            label="Please enter your new password"
                                                            value={values.new_password}
                                                            name="new_password"
                                                            onChange={handleChange}
                                                            type="password"
                                                            data-testid="new-password-field"
                                                        />
                                                        {this.renderCustomError(errors.new_password)}
                                                    </Box>
                                                </Box>
                                                <SubmitBox>
                                                    <SubmitButton
                                                        data-testid="submit-button"
                                                        type="submit"
                                                        loadingStyle={loadingStyle}
                                                        label="Confirm New Password"
                                                        rightIcon={<img src={checkBtnBlack} />}
                                                        isLoading={this.state.apiLoading}
                                                    />
                                                </SubmitBox>
                                            </FormBox>
                                        </Form>
                                    </>
                                )
                            }}
                        </Formik>
                    </CenterBox>
                    <CustomAlert
                        data-testid="custom-alert"
                        open={!!this.state.alertMsg}
                        handleClose={() => this.toggleAlert("")}
                        message={this.state.alertMsg}
                    />
                </Wrapper>
            </>
        )
    }
}
// Customizable Area Start
const Wrapper = styled(Box)({
    userSelect: "none",
    padding: "48px",
    "@media (min-width: 480px) and (max-width: 767px)": {
        padding: "48px 32px",
    },
    "@media (max-width: 479px)": {
        padding: "48px 24px",
    }
})
const CloseBtnBox = styled(Box)({
    display: "flex",
    justifyContent: "end"
})

const CenterBox = styled(Box)({
    display: "flex",
    justifyContent: "center",
    marginTop: "-18px",
    "@media (max-width: 767px)": {
        marginTop: "16px",
    }
})
const FormBox = styled(Box)({
    maxWidth: "279px",
    height: "463px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
})

const CustomField = styled(TextField)({
    marginTop: "16px",
    width: "100%",
    height: "48px",
    borderRadius: "24px",
    boxShadow: "2.18px 2.18px 6.54px 0px #AEAEC066 inset, -2.18px -2.18px 6.54px 0px #FFFFFF inset",
    background: "#F4F4F4",
    "&.date-picker-input": {
        "& input": {
            cursor: "pointer !important"
        }
    },
    "& .MuiOutlinedInput-root": {
        borderRadius: "24px",
        height: "48px",
        width: "100%",
        fontSize: "14px",
        fontFamily: "roobert-regular",
        fontWeight: "600",
        color: "#717C90",
        "& .Mui-disabled": {
            color: "#717C90 !important",
            "-webkit-text-fill-color": "unset",
        },
        "& fieldset": {
            border: "none",
        },
        "&:hover fieldset": {
            border: "none",
        },
        "&.Mui-focused fieldset": {
            border: "none",
        },
    },
    "& .MuiFormLabel-root": {
        top: -2,
        fontWeight: "600",
        fontSize: "14px",
        color: "#ACACAC",
        LineWeight: "16px",
        fontFamily: "roobert-regular",
    },
    "& .MuiInputLabel-root-MuiInputLabel-root.Mui-focused": {
        visibility: "hidden",
    },
    "& .MuiInputLabel-shrink": {
        transform: "translate(14px, -6px) scale(0.85)",
        visibility: "hidden",
    },
})
const StyledLabel = styled(FormLabel)({
    fontWeight: "600",
    fontSize: "16px",
    color: "#2F2F2F",
    LineWeight: "100%",
    fontFamily: "roobert-regular",
})

const SubmitBox = styled(Box)({
    marginTop: "197px"
})

const SubmitButton = styled(CommonButton)({
    width: "100%",
    height: "48px",
    padding: "4px 16px",
    textTransform: "none",
    backgroundColor: '#F4F4F4',
    color: "#2F2F2F",
    fontSize: "16px",
    lineHeight: "16px",
    fontWeight: "600",
    fontFamily: "roobert-regular",
    borderRadius: '24px',
    boxShadow: "2.18px 2.18px 6.54px 0px #AEAEC066, -2.18px -2.18px 6.54px 0px #FFFFFF",
    "&:hover": {
        backgroundColor: "#F4F4F4",
        color: "#2F2F2F",
    }
})

const CloseButton = styled(CommonButton)({
    padding: "6px 19px",
    width: "100%",
    maxWidth: "180px",
    height: "48px",
    border: "1px solid #2F2F2F",
    borderRadius: '24px',
    color: "#2F2F2F",
    textTransform: "capitalize",
    fontSize: "15px",
    lineHeight: "100%",
    fontWeight: "600",
    fontFamily: "roobert-regular",
    backgroundColor: '#F4F4F4',
    boxShadow: "2.18px 2.18px 6.54px 0px #AEAEC066, -2.18px -2.18px 6.54px 0px #FFFFFF",
    "&:hover": {
        color: "#2F2F2F",
        backgroundColor: "#F4F4F4",
        boxShadow: "2.18px 2.18px 6.54px 0px #AEAEC066 inset, -2.18px -2.18px 6.54px 0px #FFFFFF inset",
    },
    "@media (max-width: 479px)": {
        width: "unset",
    }
})
const CloseIcon = styled(Close)({
    width: "15px",
    height: "15px",
    color: '#2F2F2F',
})

const ErrorBox = styled(Box)({
    padding: "8px 16px 0 16px",
    display: "flex",
    justifyContent: "space-between",
    gap: "4px",
    "& .error-font": {
        color: "#EA4335",
        fontWeight: "400",
        fontSize: "12px",
        lineHeight: "13.18px",
        fontFamily: 'roobert-regular',
    },
    "& img": {
        width: "12px",
        height: "12px",
        marginTop: "2px"
    }
})
const loadingStyle = {
    color: "#2F2F2F",
    height: "24px",
    width: "24px"
} as React.CSSProperties

// Customizable Area End
