import React from "react";
// Customizable Area Start
import GetInTouchFormController, { Props, configJSON } from "./GetInTouchFormController.web";
import { Formik, Form } from 'formik';
import { Box, Typography, TextField, MenuItem, styled, SelectProps, MenuProps } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import { attachment_button } from "../../../../blocks/landingpage/src/assets";
import { CommonButton, ResolveCondition } from "../../../../blocks/utilities/src/ResolveCondition.web";

const CustomProps: Partial<SelectProps> = {
    IconComponent: KeyboardArrowDown,
    displayEmpty: true,
    MenuProps: {
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
        },
        transformOrigin: {
            vertical: "top",
            horizontal: "left",
        },
        getContentAnchorEl: null,
        PaperProps: {
            sx: {
                backgroundColor: "#F4F4F4",
                borderRadius: "24px",
                boxShadow: "2.18px 2.18px 6.54px 0px #AEAEC066 inset, -2.18px -2.18px 6.54px 0px #FFFFFF inset",
                overflow: "hidden",
                "& .MuiList-root": {
                    maxHeight: "400px",
                    overflow: "auto"
                },
            },
        },
    } as Partial<MenuProps>,
}

interface IMenu {
    label: string,
    value: string
}
// Customizable Area End

export default class GetInTouchForm extends GetInTouchFormController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderForm = () => {
        return (
            <Box maxWidth={"305px"} marginTop={"24px"}>
                <Formik
                    validationSchema={this.validationSchema}
                    initialValues={this.initialFormvalues}
                    onSubmit={(values) => this.onSubmit(values)}
                >
                    {({ values, handleChange, errors, setFieldValue }) => (
                        <Form>
                            <FormikBox>
                                <Box>
                                    <CustomField
                                        type="text"
                                        name="name"
                                        label="Name"
                                        onChange={handleChange}
                                        value={values.name}
                                        data-testid="name-id"
                                    />
                                    {this.renderError(errors?.name)}
                                </Box>
                                <Box>
                                    <CustomField
                                        type="email"
                                        name="email"
                                        label="Email Address"
                                        onChange={handleChange}
                                        value={values.email}
                                        data-testid="email-id"
                                    />
                                    {this.renderError(errors?.email)}
                                </Box>
                                <Box>
                                    <StyledSelectField
                                        select
                                        name="subject"
                                        SelectProps={CustomProps}
                                        onChange={handleChange}
                                        value={values.subject}
                                        className={ResolveCondition(!!values?.subject, "value-class", "placeholder-class")}
                                        data-testid="subject-id"
                                    >
                                        <StyledMenuItem disabled value="">Subject</StyledMenuItem>
                                        {(configJSON.MENUS_LIST as IMenu[]).map((item, index) => (
                                            <StyledMenuItem value={item.value} key={index}>{item.label}</StyledMenuItem>
                                        ))}
                                    </StyledSelectField>
                                    {this.renderError(errors?.subject)}
                                </Box>

                                <Box style={{ position: "relative" }}>
                                    <CustomTextArea
                                        name="message"
                                        label="Message"
                                        multiline
                                        rows={6}
                                        onChange={handleChange}
                                        value={values.message}
                                        inputProps={{ maxLength: 500 }}
                                        data-testid="message-id"
                                    />
                                    <FontLength>{values.message.length}/500</FontLength>
                                    {this.renderError(errors?.message)}
                                </Box>
                                <Box>
                                    <Box width={"100%"}>
                                        <AttachmentFont htmlFor="attachment">
                                            Attachment (optional)
                                            <Box display={"flex"} alignItems={"center"}>
                                                <DescFont maxWidth={"40px"} noWrap title={values?.attachment?.name}>{values?.attachment?.name}</DescFont>
                                                <img src={attachment_button} />
                                            </Box>

                                        </AttachmentFont>
                                    </Box>
                                    <input id="attachment" name="attachment" type="file" style={{ display: "none" }}
                                        accept=".jpg, .jpeg, .png, .pdf"
                                        onChange={(event) => {
                                            const file = event.target.files?.[0];
                                            if (file) setFieldValue("attachment", file, true);
                                        }}
                                        data-testid="attachment-id"
                                    />
                                    {this.renderError(errors?.attachment)}
                                </Box>
                                <Box>
                                    <DescFont>
                                        Your information will only be used to respond to your inquiry. See our
                                        <span onClick={() => this.goToScreen("TermsConditionsWeb")} data-testid="privacy-policy-id"> Privacy Policy </span>
                                        for more details.
                                    </DescFont>
                                </Box>

                                {ResolveCondition(this.state.isSuccess === false,
                                    <Box textAlign={"center"}>
                                        {this.renderError("Something went wrong")}
                                    </Box>
                                    , <></>
                                )}

                                <SubmitButtonContactUs
                                    type="submit"
                                    label="Submit"
                                    isLoading={this.state.apiLoading}
                                    loadingStyle={btnLoading}
                                />
                            </FormikBox>
                        </Form>
                    )}
                </Formik>
            </Box>
        )
    }
    renderError = (error: string | undefined) => {
        return ResolveCondition(!!error, <ErrorFont>{error}</ErrorFont>, <></>)
    }
    renderSuccess = () => {
        return (
            <Box maxWidth={"327px"} marginTop={"143px"} data-testid="success-box">
                <SuccessTitle>{configJSON.ENUM.SUCCESS_TITLE}</SuccessTitle>
                <Box marginTop={"48px"}>
                    <SuccessDesc>{configJSON.ENUM.SUCCESS_DESC1}</SuccessDesc>
                    <SuccessDesc marginTop={"24px"}>
                        In the meantime, feel free to check out our
                        <span onClick={() => this.goToScreen("FAQPage")} data-testid="faq-link"> FAQ</span>
                        for answers to common questions—you might find what you’re looking for!
                    </SuccessDesc>

                </Box>
            </Box>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            <>
                <Wrapper>
                    <SectionTitle>Get in touch</SectionTitle>
                    {ResolveCondition(this.state.isSuccess === true, this.renderSuccess(), this.renderForm())}
                </Wrapper>
            </>
        )
    }
}
// Customizable Area Start
const Wrapper = styled(Box)({
    userSelect: "none"
})
const SectionTitle = styled(Typography)({
    marginLeft: "9px",
    fontFamily: 'roobert-regular',
    lineHeight: "53px",
    fontWeight: "600",
    fontSize: "48px",
    background: "linear-gradient(308.32deg, #F27C66 32.08%, #FECD8F 55.96%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    display: "inline-block",
    "@media (min-width: 768px) and (max-width: 1023px)": {
        fontSize: "32px",
        lineHeight: "none",
    },
    "@media (max-width: 767px)": {
        fontSize: "28px",
        lineHeight: "none",
    }
})
const FormikBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    gap: "16px"
})
const CustomField = styled(TextField)({
    boxShadow: "2.18px 2.18px 6.54px 0px #AEAEC066 inset, -2.18px -2.18px 6.54px 0px #FFFFFF inset",
    height: "48px",
    background: "#F4F4F4",
    borderRadius: "24px",
    width: "100%",
    "& .MuiOutlinedInput-root": {
        borderRadius: "24px",
        height: "48px",
        width: "100%",
        fontSize: "14px",
        fontFamily: "roobert-regular",
        "& fieldset": {
            border: "none",
        },
        "&:hover fieldset": {
            border: "none",
        },
        "&.Mui-focused fieldset": {
            border: "none",
        },
    },
    "& .MuiFormLabel-root": {
        fontWeight: 600,
        color: "#ACACAC",
        fontSize: "14px",
        LineWeight: "16px",
        fontFamily: "roobert-regular",
    },
    "& .MuiInputLabel-root-MuiInputLabel-root.Mui-focused": {
        visibility: "hidden",
    },
    "& .MuiInputLabel-shrink": {
        transform: "translate(14px, -6px) scale(0.85)",
        visibility: "hidden",
    },
    "& .MuiInputBase-input": {
        fontWeight: 600,
        color: "#717C90",
        fontSize: "14px",
        LineWeight: "16px",
        fontFamily: "roobert-regular",
    }
})
const CustomTextArea = styled(TextField)({
    boxShadow: "2.18px 2.18px 6.54px 0px #AEAEC066 inset, -2.18px -2.18px 6.54px 0px #FFFFFF inset",
    background: "#F4F4F4",
    borderRadius: "24px",
    width: "100%",
    "& .MuiInputBase-input": {
        width: "100%",
        fontWeight: 600,
        color: "#717C90",
        fontSize: "14px",
        LineWeight: "16px",
        fontFamily: "roobert-regular",
    },
    "& .MuiOutlinedInput-root": {
        borderRadius: "24px",
        fontSize: "14px",
        fontFamily: "roobert-regular",
        padding: "20px 16px 16px 16px",
        "& fieldset": {
            border: "none",
        },
        "&:hover fieldset": {
            border: "none",
        },
        "&.Mui-focused fieldset": {
            border: "none",
        },
    },
    "& .MuiFormLabel-root": {
        fontWeight: 600,
        color: "#ACACAC",
        fontSize: "14px",
        LineWeight: "16px",
        fontFamily: "roobert-regular",
    },
    "& .MuiInputLabel-root-MuiInputLabel-root.Mui-focused": {
        visibility: "hidden",
    },
    "& .MuiInputLabel-shrink": {
        transform: "translate(14px, -6px) scale(0.85)",
        visibility: "hidden",
    }
})
const SubmitButtonContactUs = styled(CommonButton)({
    width: "100%",
    maxWidth: "279px",
    height: "48px",
    padding: "10px",
    borderRadius: "30px",
    boxShadow: "2.18px 2.18px 6.54px 0px #AEAEC066, -2.18px -2.18px 6.54px 0px #FFFFFF",
    textTransform: 'none',
    fontWeight: '600',
    backgroundColor: "#F4F4F4",
    color: "#2F2F2F",
    fontFamily: "roobert-regular",
    "&:hover": {
        backgroundColor: "#2F2F2F",
        color: "#fff",
    },
})
const FontLength = styled(Typography)({
    position: "absolute",
    top: 8,
    right: 16,
    fontWeight: '600',
    fontSize: "10px",
    color: "#ACACAC",
    fontFamily: "roobert-regular"
})
const AttachmentFont = styled('label')({
    cursor: "pointer",
    color: "#ACACAC",
    textDecoration: "underline",
    fontFamily: "roobert-regular",
    fontWeight: '600',
    fontSize: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& img": {
        margin: "0 20px"
    }
})
const DescFont = styled(Typography)({
    fontWeight: '600',
    fontSize: "12px",
    lineHeight: "13.18px",
    color: "#ACACAC",
    fontFamily: "roobert-regular",
    "& span": {
        cursor: "pointer",
        textDecoration: "underline"
    }
})
const StyledSelectField = styled(TextField)({
    width: "100%",
    height: "48px",
    borderRadius: "24px",
    backgroundColor: "#F4F4F4",
    boxShadow: "2.18px 2.18px 6.54px 0px #AEAEC066 inset, -2.18px -2.18px 6.54px 0px #FFFFFF inset",
    "&.value-class .MuiOutlinedInput-root": {
        "& > .MuiSelect-select": {
            color: "#717C90",
        }
    },
    "& .MuiOutlinedInput-root": {
        cursor: "pointer",
        height: "48px",
        borderRadius: "24px",
        "& > .MuiSelect-select": {
            fontFamily: "roobert-regular",
            fontWeight: "600",
            padding: "16px 24px 16px 16px !important",
            color: "#ACACAC",
            fontSize: "14px",
            lineHeight: "15.37px",
        },
        "&:hover fieldset": {
            border: "none",
        },
        "& fieldset": {
            border: "none",
        },
        "&.Mui-focused fieldset": {
            border: "none",
        },
    },
    "& .MuiFormLabel-root": {
        color: "#ACACAC",
        fontSize: "14px",
        fontFamily: "roobert-regular",
    },
    "& .MuiInputLabel-root-MuiInputLabel-root.Mui-focused": {
        visibility: "hidden",
    },
    "& .MuiInputLabel-shrink": {
        transform: "translate(14px, -6px) scale(0.85)",
        visibility: "hidden",
    },
})
const StyledMenuItem = styled(MenuItem)({
    fontFamily: "roobert-regular",
    fontWeight: "600",
    color: "#717C90",
    fontSize: "14px",
    lineHeight: "15.37px",
    border: "1px solid #E4E6EA",
    padding: "8px 16px 16px 16px",
    backgroundColor: "transparent !important",
    "&.Mui-selected": {
        color: "#2F2F2F",
    }
})
const ErrorFont = styled(Typography)({
    padding: "8px 16px 0 16px",
    fontSize: "12px",
    lineHeight: "13px",
    color: "#EA4335",
    fontWeight: "400",
    fontFamily: "roobert-regular"
})
const btnLoading = {
    height: "24px",
    width: "24px",
    color: "#2F2F2F"
} as React.CSSProperties

const SuccessTitle = styled(Typography)({
    textAlign: "center",
    fontSize: "24px",
    lineHeight: "26.35px",
    fontWeight: "600",
    fontFamily: "roobert-regular",
    color: "#2F2F2F",
})
const SuccessDesc = styled(Typography)({
    textAlign: "center",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "17.57px",
    fontFamily: "roobert-regular",
    color: "#2F2F2F",
    "& span": {
        cursor: "pointer",
        textDecoration: "underline"
    } as React.CSSProperties
})
// Customizable Area End
