Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpDeleteMethod = "DELETE";

exports.contactUsApiContentType = "application/json";
exports.getContactUsAPiEndPoint = "contact_us/contacts";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";
exports.errorEmailNotValid = "Email not valid.";
exports.phoneNumberError = "Phone number not valid"

exports.nameTitle = "Name";
exports.emailTitle = "Email";
exports.numberTitle = "Phone number";
exports.commentsTitle = "Comments";

exports.namePlaceHolder = "Enter Your Name";
exports.emailPlaceHolder = "Enter Your Email";
exports.numberPlaceHolder = "Enter Phone number";
exports.commentsPlaceHolder = "Enter Comments";

exports.labelTitleText = "contactus";
exports.labelBodyText = "contactus Body";

exports.btnExampleTitle = "CLICK ME";
exports.ENUM = {
  CONTACTUS_SCREEN_TITLE: "Contact Us",
  CONTACT_PAGE_SUBTITLE:"Have questions or need assistance?<br />Fill out the form below, and our team<br /> will get back to you as soon as possible.",
  CONTACT_PAGE_TITLE:"Thank you for reaching out!",
  CONTACT_PAGE_SUB_MSG_TITLE:"We’ve received your message and will get<br/> back to you soon. Check your inbox for<br/> confirmation, and feel free to explore more<br/> while you wait.",
  CONTACT_PAGE_SUBTITLETWO:"In the meantime, feel free to check out our<br/> <span style='text-decoration: underline;'>FAQ</span> for answers to common questions—you<br/> might find what you’re looking for!"
}
exports.formApiContentType = "multipart/form-data";
exports.contactUsAPiEndPoint="bx_block_contact_us/contacts"
// Customizable Area End