import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MergeEngineUtilities from "../../utilities/src/MergeEngineUtilities";

interface ISectionRecord {
  id: string
  type: string
  attributes: ISectionRecordAttributes
}

interface ISectionRecordAttributes {
  id: number
  headline: string
  body_text: string
  type: string
  position: string
  created_at: string
  media: IMedia
}

interface IMedia {
  type: string
  id: number
  url: string
  filename: string
}
interface IRecordSectionTwo {
  id: string
  type: string
  attributes: {
    id: number
    headline: string
    body_text: string
    type: string
    position: string
    created_at: string
    media: IMedia[]
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  sectionVisibility: {
    [key: string]: boolean;
  };
  isEmboss: boolean;
  subjectDropDown: boolean;
  contactUsSection: Array<ISectionRecord>;
  introSectionOne: Array<ISectionRecord>;
  introSectionTwo:  Array<IRecordSectionTwo>;
  howItWork: Array<ISectionRecord>;
  ourStory: Array<ISectionRecord>;
  isSideBarOpen: boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  landingPageCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      sectionVisibility: {
        'portfolio-section': false,
        'website-section': false,
        'CV-section': false,
        'WHY-section': false
      },
      isEmboss: false,
      subjectDropDown: false,
      contactUsSection: [],
      introSectionOne: [],
      introSectionTwo: [],
      howItWork: [],
      ourStory: [],
      isSideBarOpen: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    this.handleLandingPageResponse(from, message);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.getLandingPageContent();
  }
  async componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleScroll = () => {
    const sectionIds = ['portfolio-section', 'website-section', 'CV-section', 'WHY-section'];
    const visibility = { ...this.state.sectionVisibility };

    sectionIds.forEach(id => {
      const section = document.getElementById(id);
      if (section) {
        const rect = section.getBoundingClientRect();
        visibility[id] = rect.top < window.innerHeight && rect.bottom >= 0;
      }
    });
    this.setState({ sectionVisibility: visibility });
  };

  handleAccountButtonClick = () => {
    this.setState((prevState) => ({
      isEmboss: !prevState.isEmboss,
    }));
    MergeEngineUtilities.navigateToScreen("EmailAccountLoginBlockWeb", this.props);
  }

  getLandingPageContent = async () => {
    const header = {
      "content-type": "application/json"
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.landingPageCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.landingPageEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleLandingPageResponse = async (from: string, message: Message) => {
    if (this.landingPageCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        if (responseJson) {
          let data = responseJson.data;
          let transformedData = data.reduce((acc: any, item: any) => {
            const type = item.attributes.type;

            if (!acc[type]) {
              acc[type] = []; 
            }
            acc[type].push(item);

            return acc;
          }, {});
          
          this.setState({ contactUsSection: transformedData?.ContactUs || [], introSectionOne: transformedData.IntroSectionOne, introSectionTwo: transformedData.IntroSectionTwo, howItWork: transformedData.HowItWork, ourStory: transformedData.OurStory })
        }

    }
  };


  scrollToDiv = (elementId: string) => {
    this.setState({isSideBarOpen:false})
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  navigateToHome = () => {
    MergeEngineUtilities.navigateToScreen("Home", this.props)
  }
  toggleDrawer = (open: boolean) => () => {
    this.setState({ isSideBarOpen: open });
  };
  // Customizable Area End
}
