import React from "react";
// Customizable Area Start
import DiscoverController, { Props } from "./DiscoverController.web";
import Sidebar from "../../../../blocks/settings2/src/components/Sidebar.web";
import { Avatar, Box, IconButton, styled, Typography } from '@mui/material';
import { InView } from "react-intersection-observer";
import { favouritePostWhite, searchPost, userProfile, viewPostWhite } from "../assets";
import MergeEngineUtilities from "../../../../blocks/utilities/src/MergeEngineUtilities";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ContentFlagMenu from "../../../../blocks/contentflag/src/components/ContentFlagMenu.web";

const images: string[] = [
    "https://fastly.picsum.photos/id/1/5000/3333.jpg?hmac=Asv2DU3rA_5D1xSe22xZK47WEAN0wjWeFOhzd13ujW4",
    "https://fastly.picsum.photos/id/2/5000/3333.jpg?hmac=_KDkqQVttXw_nM-RyJfLImIbafFrqLsuGO5YuHqD-qQ",
    "https://fastly.picsum.photos/id/3/5000/3333.jpg?hmac=GDjZ2uNWE3V59PkdDaOzTOuV3tPWWxJSf4fNcxu4S2g",
    "https://fastly.picsum.photos/id/7/4728/3168.jpg?hmac=c5B5tfYFM9blHHMhuu4UKmhnbZoJqrzNOP9xjkV4w3o",
    "https://fastly.picsum.photos/id/77/1631/1102.jpg?hmac=sg0ArFCRjP1wlUg8vszg5RFfGiXZJkWEtqLLCRraeBw",
    "https://fastly.picsum.photos/id/84/1280/848.jpg?hmac=YFRYDI4UsfbeTzI8ZakNOR98wVU7a-9a2tGF542539s",
    "https://fastly.picsum.photos/id/103/2592/1936.jpg?hmac=aC1FT3vX9bCVMIT-KXjHLhP6vImAcsyGCH49vVkAjPQ"
];
// Customizable Area End

export default class Discover extends DiscoverController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderUserDetails = (index: number) => {
        return (
            <Box display={"flex"} justifyContent={"space-between"} padding={"12px"} alignItems={"end"} key={index}>
                <Box maxWidth={"295px"}>
                    <Box display={"flex"} flexDirection={"row"} gap={"8px"} alignItems={"center"}>
                        <UserAvatar src={userProfile} />
                        <UserName>Matthew McClure</UserName>
                    </Box>
                    <Box marginTop={"9px"}>
                        <PostTitle>My top 10 flower shops in Paris </PostTitle>
                    </Box>
                    <Box marginTop={"4px"}>
                        <PostDesc>Here is a space about all my favourite flower shops I visited when I travelled to paris this ... </PostDesc>
                    </Box>
                    <ChipBox>
                        <StyledChip>Flowers</StyledChip>
                        <StyledChip>Paris</StyledChip>
                        <StyledChip>Craft</StyledChip>
                    </ChipBox>

                </Box>

                <Box>
                    <StyledIconBtn sx={{ width: "32px", marginBottom: "10px", color: "#FFF" }} disableRipple
                        onClick={(event) => {
                            event.stopPropagation()
                            this.handleOpenMenu()
                        }}
                        data-testid="feed-report-menu"
                        >
                        <MoreVertIcon />
                    </StyledIconBtn>
                    <ColumnBox>
                        <StyledFont>1K</StyledFont>
                        <StyledIconBtn>
                            <img src={viewPostWhite} />
                        </StyledIconBtn>
                    </ColumnBox>
                    <ColumnBox marginTop={"6px"}>
                        <StyledFont>120</StyledFont>
                        <StyledIconBtn>
                            <img src={favouritePostWhite} />
                        </StyledIconBtn>
                    </ColumnBox>
                </Box>
            </Box>
        )
    }
    renderSearch = () => {
        return (
            <>
                <SearchBox>
                    <Typography>Search</Typography>
                    <img src={searchPost} />
                </SearchBox>
            </>
        )
    }
    renderMenu() {
        return (
            <>
                {this.state.showMenu ?
                    <ContentFlagMenu
                        navigation={undefined}
                        id="ContentFlagMenu"
                        handleCloseMenu={this.handleCloseMenu}
                    /> : <></>
                }
            </>
        );
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            <>
                <Sidebar props={this.props} selectedScreen={"Discover"}>
                    <Wrapper>
                        <BoxWrapper>
                            <Box>
                                {this.renderSearch()}
                            </Box>
                            <CenterBox>
                                <ImageWrapper>
                                    {images.map((src, index) => (
                                        <InView
                                            as="div"
                                            key={index}
                                            onChange={(inView) => this.handleInView(inView, index)}
                                            threshold={0.2}
                                            data-testid="inview-div"
                                        >
                                            <PostWrapper id={`id-${index}`} >
                                                {this.state.loadedImages.has(index) ? (
                                                    <>
                                                        <img
                                                            onClick={() => { MergeEngineUtilities.navigateToScreen("ViewFeed", this.props)}}
                                                            key={index}
                                                            src={src}
                                                            alt={`Feed ${index + 1}`}
                                                            style={{
                                                                width: "100%",
                                                                height: "100%",
                                                                objectFit: "cover"
                                                            }}
                                                            loading="lazy"
                                                            data-testid="feed-image"
                                                        />
                                                        <div style={{ position: "absolute", bottom: 0, maxWidth: "375px", width: "100%" }}>
                                                            {this.renderUserDetails(index)}
                                                        </div>
                                                        {this.renderMenu()}

                                                    </>
                                                ) : (
                                                    <p>Loading...</p>
                                                )}
                                            </PostWrapper>
                                        </InView>
                                    ))}
                                </ImageWrapper>
                            </CenterBox>
                            <Box width={"106px"} />
                        </BoxWrapper>
                    </Wrapper>
                </Sidebar>
            </>
        )
    }
}
// Customizable Area Start
const Wrapper = styled(Box)({
    padding: "44px 24px",
    userSelect: "none"
})

const BoxWrapper = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: "24px"
})

const SearchBox = styled(Box)({
    cursor: "pointer",
    boxShadow: "2.18px 2.18px 6.54px 0px #AEAEC066 inset, -2.18px -2.18px 6.54px 0px #FFFFFF inset",
    background: "#F4F4F4",
    borderRadius: "24px",
    width: "104px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    "& p": {
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "24px",
        fontFamily: "roobert-regular",
        color: "#717C90",
    },
    "& img": {
        height: "16px",
        width: "16px",
    }
})

const CenterBox = styled(Box)({
    minHeight: "716px",
    maxHeight: "100vh",
    overflowY: "auto",
    scrollSnapType: "y mandatory",
    scrollBehavior: "smooth",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    WebkitOverflowScrolling: "touch",
})
const ImageWrapper = styled(Box)({
    maxWidth: "375px",
    width: "100%",
    backgroundColor: "#f8f9fa",
    display: "flex",
    flexDirection: "column",
    gap: "16px"
})
const PostWrapper = styled(Box)({
    height: "716px",
    scrollSnapAlign: "start",
    position: "relative",
    "@media (max-width: 768px)": {
        height: "600px",
    },
    "@media (max-width: 480px)": {
        height: "500px",
    }
})
const UserAvatar = styled(Avatar)({
    height: "32px",
    width: "32px"
})
const UserName = styled(Typography)({
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "24px",
    fontFamily: "roobert-regular",
    color: "#FFFFFF"
})
const PostTitle = styled(Typography)({
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "20px",
    fontFamily: "roobert-regular",
    color: "#FFFFFF"
})
const PostDesc = styled(Typography)({
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "14px",
    fontFamily: "roobert-regular",
    color: "#FFFFFF"
})
const ColumnBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
})
const StyledFont = styled(Typography)({
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "14px",
    fontFamily: "roobert-regular",
    color: "#FFFFFF"
})
const StyledIconBtn = styled(IconButton)({
    padding: 0
})
const ChipBox = styled(Box)({
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "8px",
    marginTop: "16px"
})
const StyledChip = styled(Box)({
    borderRadius: "24px",
    border: "1px solid #FFFFFF",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "24px",
    fontFamily: "roobert-regular",
    color: "#FFFFFF",
    height: "32px",
    width: "72px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
})
// Customizable Area End
