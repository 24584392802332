// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
export const configJSON = require("../config");

export interface Props {
    navigation: any;
    id: string;
}

interface S {
    faqData:any;
    expanded:any;
}

interface SS { }
// Customizable Area End

export default class FaqController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    faqApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceDataMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        ];

        this.state = {
            faqData:[],
            expanded:false
        }
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // Customizable Area Start
    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Received", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId != null) {
                if (apiRequestCallId === this.faqApiCallId) {
                    this.handleGetFaq(responseJson);
                }
            }
        }
    }
    handleGetFaq = (responseJson: any) => {
        if(responseJson.data){
             this.setState({faqData:responseJson.data.faqs})
          }
    }
    async componentDidMount() {
        this.faqDataApi();
    }
    handleChange = (panel: number) => (_: React.ChangeEvent<{}>, isExpanded: boolean) => {
        this.setState({expanded:isExpanded ? panel : false})
      };
      faqDataApi = () => {
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );

        this.faqApiCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.apiEndPointGetfaq
        );
    
        const header = {
          "Content-Type": configJSON.faqApiApiContentType,
        };
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.httpGetMethod
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
      };
    // Customizable Area End
}