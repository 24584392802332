// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
export const configJSON = require("../config");

export interface Props {
    navigation: any;
    id: string;
}

interface S {
    successContactForm:boolean;
    fileName:string;
}

interface SS { }
// Customizable Area End

export default class ContactUsController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    contactUsApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceDataMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        ];

        this.state = {
            successContactForm:false,
            fileName:""
        }
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // Customizable Area Start
    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Received", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId != null) {
                if (apiRequestCallId === this.contactUsApiCallId) {
                    this.handleGetContactUs(responseJson);
                }
            }
        }
    }
    handleGetContactUs = (responseJson: any) => {
        if(!responseJson.errors){
            this.setState({successContactForm:true})
            this.props.navigation.navigate("ContactSuccess")
          }else{
            this.setState({successContactForm:false})
          }
    }
    customFunction = (values: any, { resetForm }: any) => {
        this.handleSubmitContinue(values)
        resetForm();
    };
    handleSubmitContinue = (values: any) => {
        const header = {
            "Token": localStorage.getItem("token"),
        };
        let formData = new FormData();
        formData.append("contact[name]", values.name);
        formData.append("contact[email]", values.email);
        formData.append("contact[subject]", values.subject);
        formData.append("contact[description]", values.message);
        if(values.attachment){
            formData.append("contact[contact_us_image]", values.attachment);
        }
        

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.contactUsApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.contactUsAPiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpPostMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End
}