import React from "react";
// Customizable Area Start
import { Box, Typography, Button, styled, Card, IconButton, Drawer } from '@mui/material';
import { Apple } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { imageLoop, logo } from "./assets";
import { getTruthyString, ResolveCondition } from "../../utilities/src/ResolveCondition.web";
import GetInTouchForm from "../../../blocks/customform/src/components/GetInTouchForm.web";
import Footer from "../../../components/src/Footer.web";
import ReactPlayer from 'react-player'
const configJSON = require("./config");

interface IHeaderLink {
  id: string,
  title: string,
  link: string,
}
// Customizable Area End
import LandingPageController, {
  Props
} from "./LandingPageController";

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderHeader = () => {
    return (
      <HaederWrapper>
        <Box flex={"1"} display={"flex"} alignItems={"center"} gap={"8px"}>
          <HeaderMenuBox>
            <IconButton onClick={this.toggleDrawer(true)}><MenuIcon /></IconButton>
          </HeaderMenuBox>
          <Drawer anchor="left" open={this.state.isSideBarOpen} onClose={this.toggleDrawer(false)}>
            <ResponsiveLinkBox>
              {(configJSON.HEADER_LINKS as IHeaderLink[]).map((item, index: number) => {
                return (
                  <StyledLink key={index} onClick={() => this.scrollToDiv(item.link)}>
                    {item.title}
                  </StyledLink>
                )
              })}
            </ResponsiveLinkBox>
          </Drawer>
          <LogoBox data-testid="header-logo-box" onClick={this.navigateToHome}>
            <img src={logo} />
          </LogoBox>
        </Box>
        <HeaderLinksWrapper flex={"1"} justifyContent={"space-between"}>
          {(configJSON.HEADER_LINKS as IHeaderLink[]).map((item, itemIndex: number) => {
            return (
              <Box width={"100px"} textAlign={"center"}>
                <Typography key={itemIndex}
                  className="header-secs"
                  data-test-id="header_link"
                  onClick={() => this.scrollToDiv(item.link)}
                >
                  {item.title}
                </Typography>
              </Box>
            )
          })}
        </HeaderLinksWrapper>
        <Box flex={"1"} display={"flex"} justifyContent={"end"}>
          <HeaderButton
            variant="contained"
            data-test-id="account-btn"
            onClick={this.handleAccountButtonClick}
          >
            Account
          </HeaderButton>
        </Box>
      </HaederWrapper>
    )
  }
  renderFirstSection = () => {
    const { introSectionOne } = this.state
    const main_image = getTruthyString(introSectionOne?.[0]?.attributes?.media?.url)
    const body_text = getTruthyString(introSectionOne?.[0]?.attributes?.body_text)

    const splittedWord = configJSON.ENUM.MAIN_TITLE.trim().split(/\s+/);
    const lastWord = splittedWord.pop();
    const initialWords = splittedWord.join(" ");

    return (
      <FirstSectionWrapper>
        <FirstSection>
          <LeftBoxFirstSection>
            <Box className="text_box_section">
              <MainTitleFont>{initialWords}</MainTitleFont>
              <AnimatedFont>{lastWord}</AnimatedFont>
            </Box>
            <DownLoadButton>
              <Apple /> Download the App
            </DownLoadButton>
          </LeftBoxFirstSection>

          <RightBoxFirstSection>
            <Box className="media_section_1">
              <MediaBoxFirstSection>
                {this.returnImg(main_image)}
              </MediaBoxFirstSection>
              <Box>
                <HtmlBox dangerouslySetInnerHTML={{ __html: body_text }} />
              </Box>
            </Box>
          </RightBoxFirstSection>
        </FirstSection>
      </FirstSectionWrapper>
    )
  }
  renderSecondSection = () => {
    const { introSectionTwo } = this.state

    const firstTitle = getTruthyString(introSectionTwo?.[0]?.attributes?.headline)
    const firstBodyText = getTruthyString(introSectionTwo?.[0]?.attributes?.body_text)
    const firstMedia = introSectionTwo?.[0]?.attributes?.media
    const firstTransform = ResolveCondition(this.state.sectionVisibility['portfolio-section'], "translateY(0)", "translateY(100px)")

    const secondTitle = getTruthyString(introSectionTwo?.[1]?.attributes?.headline)
    const secondBodyText = getTruthyString(introSectionTwo?.[1]?.attributes?.body_text)
    const secondMedia = introSectionTwo?.[1]?.attributes?.media
    const secondTransform = ResolveCondition(this.state.sectionVisibility['website-section'], "translateY(0)", "translateY(100px)")

    const thirdTitle = getTruthyString(introSectionTwo?.[2]?.attributes?.headline)
    const thirdBodyText = getTruthyString(introSectionTwo?.[2]?.attributes?.body_text)
    const thirdMedia = introSectionTwo?.[2]?.attributes?.media
    const thirdTransform = ResolveCondition(this.state.sectionVisibility['CV-section'], "translateY(0)", "translateY(100px)")

    return (
      <SectionWrapper>
        <PaddingLeftBox>
          <Box marginBottom={"88px"}>
            <SectionTitle>{configJSON.ENUM.TITLE_SECTION_2}</SectionTitle>
          </Box>

          <BoxSection2 className="section1-box">
            <Box display={"flex"} alignItems={"end"} maxHeight={"502px"}>
              <Box maxWidth={"200px"}>
                <TitleSection2 dangerouslySetInnerHTML={{ __html: firstTitle }} />
                <BodySection2 dangerouslySetInnerHTML={{ __html: firstBodyText }} />
                <HeaderButton sx={{ marginTop: "73px" }}
                  id="portfolio-discover"
                  variant="contained"
                >
                  Discover
                </HeaderButton>
              </Box>
            </Box>
            <CardHeightBox id="portfolio-section" flex={"1"}>
              <CardsWrapperS1>
                <StyledImageCard className="card1_s1"
                  sx={{ transform: firstTransform }}
                >
                  {this.returnImg(getTruthyString(firstMedia?.[0]?.url))}
                </StyledImageCard>
                <StyledImageCard className="card2_s1"
                  sx={{ transform: firstTransform }}
                >
                  {this.returnImg(getTruthyString(firstMedia?.[1]?.url))}
                </StyledImageCard>
                <StyledImageCard className="card3_s1"
                  sx={{ transform: firstTransform }}
                >
                  {this.returnImg(getTruthyString(firstMedia?.[2]?.url))}
                </StyledImageCard>
              </CardsWrapperS1>
            </CardHeightBox>
          </BoxSection2>

          <BoxSection2 className="section2-box">
            <CardHeightBox id="website-section" flex={"1"}>
              <CardsWrapperS2>
                <StyledImageCard className="card1_s2"
                  sx={{ transform: secondTransform }}
                >
                  {this.returnImg(getTruthyString(secondMedia?.[0]?.url))}
                </StyledImageCard>

                <StyledImageCard className="card2_s2"
                  sx={{ transform: secondTransform }}
                >
                  {this.returnImg(getTruthyString(secondMedia?.[1]?.url))}
                </StyledImageCard>

                <StyledImageCard className="card3_s2"
                  sx={{ transform: secondTransform }}
                >
                  {this.returnImg(getTruthyString(secondMedia?.[2]?.url))}
                </StyledImageCard>

                <StyledImageCard className="card4_s2"
                  sx={{ transform: secondTransform }}
                >
                  {this.returnImg(getTruthyString(secondMedia?.[3]?.url))}
                </StyledImageCard>
              </CardsWrapperS2>
            </CardHeightBox>

            <Box display={"flex"} alignItems={"end"} maxHeight={"502px"}>
              <Box maxWidth={"200px"}>
                <TitleSection2 dangerouslySetInnerHTML={{ __html: secondTitle }} />
                <BodySection2 dangerouslySetInnerHTML={{ __html: secondBodyText }} />
                <HeaderButton sx={{ marginTop: "73px" }}
                  id="website-discover"
                  variant="contained"
                >
                  Discover
                </HeaderButton>
              </Box>
            </Box>
          </BoxSection2>

          <BoxSection2 className="section3-box">
            <Box display={"flex"} alignItems={"end"} maxHeight={"502px"}>
              <Box maxWidth={"200px"}>
                <TitleSection2 dangerouslySetInnerHTML={{ __html: thirdTitle }} />
                <BodySection2 dangerouslySetInnerHTML={{ __html: thirdBodyText }} />
                <HeaderButton sx={{ marginTop: "73px" }}
                  id="CV-discover"
                  variant="contained"
                >
                  Discover
                </HeaderButton>
              </Box>
            </Box>
            <CardHeightBox id="CV-section" flex={"1"}>
              <CardsWrapperS3>
                <StyledImageCard className="card1_s3"
                  sx={{ transform: thirdTransform }}
                >
                  {this.returnImg(getTruthyString(thirdMedia?.[0]?.url))}
                </StyledImageCard>
                <StyledImageCard className="card2_s3"
                  sx={{ transform: thirdTransform }}
                >
                  {this.returnImg(getTruthyString(thirdMedia?.[1]?.url))}
                </StyledImageCard>
                <StyledImageCard className="card3_s3"
                  sx={{ transform: thirdTransform }}
                >
                  {this.returnImg(getTruthyString(thirdMedia?.[2]?.url))}

                </StyledImageCard>
              </CardsWrapperS3>
            </CardHeightBox>
          </BoxSection2>

        </PaddingLeftBox>
      </SectionWrapper>
    )
  }
  renderThirdSection = () => {
    const { howItWork } = this.state
    return (
      <SectionWrapper id="how-it-works-sec">
        <PaddingLeftBox>
          <Box marginBottom={"88px"}>
            <SectionTitle>{configJSON.ENUM.TITLE_SECTION_3}</SectionTitle>
          </Box>
          <Box display={"flex"} flexDirection={"column"} gap={"88px"}>
            {howItWork.map((records, index) => {

              const title = getTruthyString(records?.attributes?.headline)
              const bodyText = getTruthyString(records?.attributes?.body_text)
              const videoUrl = getTruthyString(records?.attributes?.media?.url)
              const isLast = index + 1 === howItWork.length
              const flexDirection = (index) % 2 === 0 ? "row" : "row-reverse"

              return (
                <BoxWrapperThirdSection key={index} sx={{ flexDirection }}>
                  <Box className="video-box-wrapper">
                    <VideoBoxSection3>
                      <ReactPlayer height={"100%"} width={"100%"} playing loop muted playsInline
                        url={videoUrl}
                        // controls
                        config={{
                          file: {
                            attributes: {
                              style: {
                                objectFit: "cover",
                                width: "100%", height: "100%",
                              },
                            },
                          },
                        }}
                      />
                    </VideoBoxSection3>
                  </Box>
                  <Box className="text-box-wrapper">
                    <TitleSection3 dangerouslySetInnerHTML={{ __html: title }} />
                    <BodySection3 dangerouslySetInnerHTML={{ __html: bodyText }} />
                    {isLast && (
                      <DownLoadButton sx={{ marginTop: "40px" }}>
                        <Apple /> Download the App
                      </DownLoadButton>
                    )}
                  </Box>
                </BoxWrapperThirdSection>
              )
            })}
          </Box>
        </PaddingLeftBox>
      </SectionWrapper>
    )
  }
  renderFourthSection = () => {
    const {ourStory} = this.state
    const title = getTruthyString(ourStory?.[0]?.attributes?.headline)
    const bodyText = getTruthyString(ourStory?.[0]?.attributes?.body_text)
    const transformStyle = ResolveCondition(this.state.sectionVisibility['WHY-section'], "translateY(0)", "translateY(70px)")
    return (
      <FourthSectionWrapper id="why-sec">
        <Box>
          <MainTitleFont>{configJSON.ENUM.TITLE_SECTION_4}</MainTitleFont>
          <PaddingLeftBox position={"relative"}>
            <WhySection id="WHY-section" >
              <Box className="first-last-box">
                <WhyTitleHtmlBox sx={{ transform: transformStyle }}
                  dangerouslySetInnerHTML={{ __html: title }}
                />
              </Box>
              <ImageLoopBox>
                <img src={imageLoop} alt="animated" />
              </ImageLoopBox>

              <Box className="first-last-box">
                <WhyBodyHtmlBox sx={{ transform: transformStyle }}
                  dangerouslySetInnerHTML={{ __html: bodyText }}
                />
              </Box>
            </WhySection>
          </PaddingLeftBox>
        </Box>
      </FourthSectionWrapper>
    )
  }
  renderFifthSection = () => {
    const { contactUsSection } = this.state
    return (
      <SectionWrapper id="contact-us-sec">
        <PaddingLeftBox>
        <ContactUsWrapper>
          <Box className="contact-us-left">
            <Box className="contact-us-body">
              <Box display={"flex"} flexDirection={"column"} rowGap={"64px"}>
                {contactUsSection.map((item, index) => {
                  const headline = item?.attributes?.headline
                  const words = headline.trim().split(/\s+/);
                  const span_text = words.pop();
                  const title = words.join(" ");
                  const bodyText = item?.attributes?.body_text
                  return (
                    <Box>
                      <StyledTitle>
                        {title}{" "}<span>{span_text}</span>
                      </StyledTitle>
                      <StyledHtmlBody dangerouslySetInnerHTML={{ __html: bodyText }} />
                    </Box>
                  )
                })}
              </Box>
            </Box>
          </Box>

          {/* Form Section */}
            <Box className="contact-us-right">
              <GetInTouchForm navigation={this.props.navigation} data-testid="get-in-touch" />
            </Box>
        </ContactUsWrapper>
        </PaddingLeftBox>
      </SectionWrapper>
    )
  }
  returnImg = (imgUrl: string) => {
    return ResolveCondition(!!imgUrl, <img src={imgUrl} /> , <></>)
  }
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <>
        <StyledWrapper>
          {this.renderHeader()}
          {this.renderFirstSection()}
          {this.renderSecondSection()}
          {this.renderThirdSection()}
          {this.renderFourthSection()}
          {this.renderFifthSection()}
          <Footer prop={this.props} />
        </StyledWrapper >
      </>
      // Customizable Area End
    );
  }

}
const HaederWrapper = styled(Box)({
  height: "128px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexWrap: 'wrap',
  padding: "0 88px",
  "@media (min-width: 1024px) and (max-width: 1439px)": {
    padding: "0 66px",
  },
  "@media (min-width: 768px) and (max-width: 1023px)": {
    padding: "0 48px",
  },
  "@media (min-width: 480px) and (max-width: 767px)": {
    padding: "0 32px",
  },
  "@media (max-width: 479px)": {
    padding: "0 24px",
  }
})

const LogoBox = styled(Box)({
  "& img": {
    cursor: "pointer",
    userSelect: "none"
  }
})
const HeaderMenuBox = styled(Box)({
  display: "none",
  "@media (max-width: 768px)": {
    display: "block"
  }
})
const HeaderLinksWrapper = styled(Box)({
  display: "flex",
  gap: "56px",
  "@media (min-width: 768px) and (max-width: 1023px)": {
    gap: "28px",
  },
  "@media (max-width: 768px)": {
    display: "none"
  }
})

const HeaderButton = styled(Button)({
  fontSize: "16px",
  width: "100%",
  maxWidth: "180px",
  height: "48px",
  padding: "10px 20px",
  boxShadow: "2.18px 2.18px 6.54px 0px #AEAEC066, -2.18px -2.18px 6.54px 0px #FFFFFF",
  borderRadius: "24px",
  backgroundColor: "#F4F4F4",
  color: "#F27C66",
  textTransform: "none",
  fontWeight: "600",
  position: "relative",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  display: "flex",
  fontFamily: "roobert-semibold",
  "@media (max-width: 479px)": {
    width: "unset",
  },
  "&:hover": {
    backgroundColor: "#F4F4F4",
    color: "#F27C66",
    boxShadow: "2.18px 2.18px 6.54px 0px #AEAEC066 inset, -2.18px -2.18px 6.54px 0px #FFFFFF inset",
  },
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    padding: "1px",
    borderRadius: "24px",
    background: "linear-gradient(122.98deg, #FECD8F 30.21%, #F27C66 72.67%)",
    WebkitMaskComposite: "xor",
    WebkitMask: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
    pointerEvents: "none",
    maskComposite: "exclude",
  },
})

const FirstSectionWrapper = styled(Box)({
  padding: "72px 88px",
  "@media (min-width: 1024px) and (max-width: 1439px)": {
    padding: "66px",
  },
  "@media (min-width: 768px) and (max-width: 1023px)": {
    padding: "48px",
  },
  "@media (min-width: 480px) and (max-width: 767px)": {
    padding: "32px",
  },
  "@media (max-width: 479px)": {
    padding: "24px",
  }
})
const FirstSection = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  columnGap: "26px",
  rowGap: "52px",
  "@media (max-width: 768px)": {
    flexWrap: "wrap"
  }
})
const LeftBoxFirstSection = styled(Box)({
  width: "100%",
  maxWidth: "730px",
  "& .text_box_section": {
    marginBottom: "196px",
    "@media (max-width: 768px)": {
      marginBottom: "52px",
    }
  }
})
const MainTitleFont = styled(Typography)({
  fontSize: "128px",
  lineHeight: "120px",
  fontFamily: "roobert-regular",
  fontWeight: "400",
  color: "#2F2F2F",
  "@media (min-width: 1024px) and (max-width: 1439px)": {
    fontSize: "80px",
    lineHeight: "normal",
  },
  "@media (min-width: 881px) and (max-width: 1023px)": {
    fontSize: "64px",
    lineHeight: "normal",
  },
  "@media (min-width: 768px) and (max-width: 880px)": {
    fontSize: "56px",
    lineHeight: "normal",
  },
  "@media (min-width: 480px) and (max-width: 767px)": {
    fontSize: "48px",
    lineHeight: "normal",
  },
  "@media (max-width: 479px)": {
    fontSize: "32px",
    lineHeight: "normal",
  }
})
const AnimatedFont = styled(Typography)({
  fontFamily: "olten",
  fontWeight: "400",
  fontSize: "128px",
  lineHeight: "120px",
  background: "linear-gradient(308.32deg, #F27C66 32.08%, #FECD8F 55.96%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  animation: "revealFromTop 1.5s ease-in-out forwards",
  display: "inline-block",
  "@keyframes revealFromTop": {
    "0%": {
      clipPath: "inset(0 0 90% 0)",
      opacity: 0.2,
    },
    "50%": {
      clipPath: "inset(0 0 50% 0)",
      opacity: 0.5,
    },
    "100%": {
      clipPath: "inset(0 0 0 0)",
      opacity: 1,
    },
  },
  "@media (min-width: 1024px) and (max-width: 1439px)": {
    fontSize: "80px",
    lineHeight: "normal",
  },
  "@media (min-width: 881px) and (max-width: 1023px)": {
    lineHeight: "normal",
    fontSize: "64px",
  },
  "@media (min-width: 768px) and (max-width: 880px)": {
    fontSize: "56px",
    lineHeight: "normal",
  },
  "@media (min-width: 480px) and (max-width: 767px)": {
    lineHeight: "normal",
    fontSize: "48px",
  },
  "@media (max-width: 479px)": {
    fontSize: "32px",
    lineHeight: "normal",
  }
})
const DownLoadButton = styled(Button)({
  maxWidth: "304px",
  width: "100%",
  height: "64px",
  padding: "10px 24px",
  color: "#2F2F2F",
  backgroundColor: "#F4F4F4",
  boxShadow: "2.18px 2.18px 6.54px 0px #AEAEC066, -2.18px -2.18px 6.54px 0px #FFFFFF",
  borderRadius: "30px",
  fontWeight: "600",
  textTransform: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  fontFamily: "roobert-semibold",
  "& .MuiSvgIcon-root": {
    width: "18px",
    height: "18px",
    color: "#2F2F2F",
    position: "absolute",
    left: "24px",
  },
  "&:hover": {
    backgroundColor: "#2F2F2F",
    color: "#fff",
    "& .MuiSvgIcon-root": {
      color: "#FFFFFF",
    },
  },

})
const RightBoxFirstSection = styled(Box)({
  display: "flex",
  maxWidth: "517px",
  width: "100%",
  "@media (max-width: 768px)": {
    maxWidth: "unset"
  }
})
const MediaBoxFirstSection = styled(Box)({
  height: "360px",
  "& img": {
    height: "100%",
    width: "100%",
    objectFit: "cover"
  }
})
const HtmlBox = styled(Box)({
  color: "#2F2F2F",
  fontFamily: "roobert-semibold",
  fontSize: "24px",
  lineHeight: "26.35px",
  fontWeight: "600",
  "@media (min-width: 480px) and (max-width: 768px)": {
    fontSize: "18px",
  },
  "@media (max-width: 479px)": {
    fontSize: "16px",
  }
})

const SectionWrapper = styled(Box)({
  background: "#F4F4F4",
  padding: "80px 88px",
  "@media (min-width: 1024px) and (max-width: 1439px)": {
    padding: "60px 66px",
  },
  "@media (min-width: 768px) and (max-width: 1023px)": {
    padding: "48px",
  },
  "@media (min-width: 480px) and (max-width: 767px)": {
    padding: "32px",
  },
  "@media (max-width: 479px)": {
    padding: "24px",
  }
})
const PaddingLeftBox = styled(Box)({
  paddingLeft: "107px",
  "@media (min-width: 1280px) and (max-width: 1439px)": {
    paddingLeft: "80px",
  },
  "@media (min-width: 1024px) and (max-width: 1279px)": {
    paddingLeft: "40px",
  },
  "@media (max-width: 1023px)": {
    paddingLeft: "0px",
  },
})
const SectionTitle = styled(Typography)({
  fontFamily: 'roobert-regular',
  background: "linear-gradient(308.32deg, #F27C66 32.08%, #FECD8F 55.96%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  display: "inline-block",
  lineHeight: "53px",
  fontWeight: "600",
  fontSize: "48px",
  "@media (min-width: 768px) and (max-width: 1023px)": {
    fontSize: "32px",
    lineHeight: "none",
  },
  "@media (max-width: 767px)": {
    fontSize: "28px",
    lineHeight: "none",
  }
})
const BoxWrapperThirdSection = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  gap: "122px",
  "& .video-box-wrapper":{
    flex: 1, 
    width: "100%",
    maxWidth: "517px",
    display: "flex",
  },
  "& .text-box-wrapper":{
    flex: 1,
    width: "100%",
    maxWidth: "517px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  "@media (max-width: 1023px)": {
    flexDirection: "column",
    gap: "48px",
    "& .video-box-wrapper": {
      maxWidth: "unset",
      justifyContent: "center"
    },
    "& .text-box-wrapper": {
      maxWidth: "unset",
    }
  }
})

const FourthSectionWrapper = styled(Box)({
  padding: "88px",
  "@media (min-width: 1024px) and (max-width: 1439px)": {
    padding: "66px",
  },
  "@media (min-width: 768px) and (max-width: 1023px)": {
    padding: "48px",
  },
  "@media (min-width: 480px) and (max-width: 767px)": {
    padding: "32px",
  },
  "@media (max-width: 479px)": {
    padding: "24px",
  }
})
const WhySection = styled(Box)({
  minHeight: "390px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "122px",
  "& .first-last-box": {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    zIndex: 2,
    width: "100%",
    maxWidth: "517px"
  },
  "@media (max-width: 1023px)": {
    margin: "48px 0",
    flexDirection: "column",
    minHeight: "unset",
    "& .first-last-box": {
      width: "100%",
      maxWidth: "unset"
    }
  }
})
const WhyTitleHtmlBox = styled(Box)({
  fontFamily: "roobert-regular",
  color: "#F27C66",
  fontWeight: "600",
  lineHeight: "52.7px",
  background: "linear-gradient(308.32deg, #F27C66 32.08%, #FECD8F 55.96%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  transition: "all 0.8s ease-out 0.2s",
  width: "100%",
  fontSize: "48px",
  "@media (min-width: 768px) and (max-width: 1023px)": {
    fontSize: "32px",
    lineHeight: "normal",
  },
  "@media (max-width: 767px)": {
    lineHeight: "normal",
    fontSize: "28px",
  }
})

const WhyBodyHtmlBox = styled(Box)({
  width: "100%",
  fontSize: "16px",
  lineHeight: "17.57px",
  textAlign: "justify",
  color: "#2F2F2F",
  transition: "all 0.8s ease-out 0.2s",
  fontFamily: "roobert-regular",
  fontWeight: "400",
  "@media (max-width: 480px)": {
    fontSize: "14px",
    lineHeight: "normal",
  }
})
const ImageLoopBox = styled(Box)({
  position: "absolute",
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
  width: "490px",
  height: "490px",
  zIndex: 1,
  background: "none",
  "& img": {
    width: "490px",
    height: "490px",
    objectFit: "cover",
    background: "none",
  },
  "@media (max-width: 768px)": {
    width: "300px",
    height: "300px",
    "& img": {
      width: "300px",
      height: "300px",
    }
  },
  "@media (max-width: 480px)": {
    width: "200px",
    height: "200px",
    "& img": {
      width: "200px",
      height: "200px",
    }
  }

})

const StyledTitle = styled(Typography)({
  marginBottom: "8px",
  fontSize: "36px",
  lineHeight: "48px",
  fontWeight: "600",
  fontFamily: "roobert-semibold",
  color: "#2F2F2F",
  "& span": {
    fontFamily: "olten",
    fontWeight: "400",
    "@media (min-width: 768px) and (max-width: 1023px)": {
    fontSize: "24px",
    lineHeight: "none",
  },
  "@media (min-width: 480px) and (max-width: 767px)": {
    lineHeight: "none",
    fontSize: "20px",
  },
  "@media (max-width: 479px)": {
    lineHeight: "none",
    fontSize: "18px",
  }
  },
  "@media (min-width: 768px) and (max-width: 1023px)": {
    fontSize: "24px",
    lineHeight: "none",
  },
  "@media (min-width: 480px) and (max-width: 767px)": {
    fontSize: "20px",
    lineHeight: "none",
  },
  "@media (max-width: 479px)": {
    fontSize: "18px",
    lineHeight: "none",
  }
})

const StyledHtmlBody = styled(Box)({
  color: "#2F2F2F",
  fontSize: "16px",
  lineHeight: "18px",
  fontFamily: "roobert-regular",
  fontWeight: '400',
  "@media (max-width: 480px)": {
    fontSize: "16px",
    lineHeight: "none",
  }
})

const ContactUsWrapper = styled(Box)({
  gap: "113px",
  display: "flex",
  justifyContent: "space-between",
  "& .contact-us-left ": {
    width: "100%",
    maxWidth: "517px"
  },
  "& .contact-us-right": {
    width: "100%",
    maxWidth: "517px"
  },
  "& .contact-us-body": {
    paddingTop: "77px"
  },
  "@media (max-width: 1023px)": {
    flexDirection: "column-reverse",
    gap: "0",
    "& .contact-us-left ": {
      maxWidth: "unset",
    },
    "& .contact-us-right": {
      maxWidth: "unset",
      display: "flex",
      justifyContent: "center",
    },
    "& .contact-us-body": {
      padding: "77px 0"
    },
  }
})

const ResponsiveLinkBox = styled(Box)({
  padding: "24px",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  gap: "24px"
})
const StyledLink = styled(Typography)({
  width: "fit-content",
  position: "relative",
  display: "inline-block",
  cursor: "pointer",
  color: "#2F2F2F",
  fontSize: "16px",
  lineHeight: "18px",
  textDecoration: "none",
  fontFamily: "roobert-regular",
  "&::after": {
    content: '""',
    position: "absolute",
    bottom: -2,
    left: 0,
    width: "100%",
    height: "2px",
    backgroundColor: "#2F2F2F",
    transform: "scaleX(0)",
    transformOrigin: "left",
    transition: "transform 0.3s ease-in-out",
  },
  "&:hover::after": {
    transform: "scaleX(1)",
  },
  "&:focus::after, &:active::after": {
    transform: "scaleX(1)",
  },
})
const StyledWrapper = styled("div")({
  background: "#F4F4F4",
  "& .header-secs": {
    textAlign: "center",
    position: "relative",
    display: "inline-block",
    cursor: "pointer",
    color: "#2F2F2F",
    fontSize: "14px",
    lineHeight: "15.37px",
    textDecoration: "none",
    fontFamily: "roobert-regular",
    "&::after": {
      content: '""',
      position: "absolute",
      bottom: -2,
      left: 0,
      width: "100%",
      height: "2px",
      backgroundColor: "#2F2F2F",
      transform: "scaleX(0)",
      transformOrigin: "left",
      transition: "transform 0.3s ease-in-out",
    },
    "&:hover::after": {
      transform: "scaleX(1)",
    },
    "&:focus::after, &:active::after": {
      transform: "scaleX(1)",
    },
  }
});

const BoxSection2 = styled(Box)({
  display: "flex",
  gap: "122px",
  "&.section2-box":{
    marginTop: "100px"
  },
  "&.section3-box":{
    marginTop: "100px"
  },
  "@media (min-width: 768px) and (max-width: 991px)": {
    gap: "80px",
  },
  "@media (max-width:768px)": {
    gap: "80px",
    "&.section1-box": {
      flexDirection: "column",
      marginTop: "50px"
    },
    "&.section2-box": {
      flexDirection: "column-reverse",
      marginTop: "50px"
    },
    "&.section3-box": {
      flexDirection: "column",
      marginTop: "50px"
    }
  }
})

const TitleSection2 = styled(Box)({
  fontWeight: "600",
  fontSize: "36px",
  lineHeight: "40px",
  fontFamily: "roobert-regular",
  color: "#2F2F2F",
  "@media (min-width: 768px) and (max-width: 1023px)": {
    fontSize: "24px",
    lineHeight: "none",
  },
  "@media (min-width: 480px) and (max-width: 767px)": {
    fontSize: "20px",
    lineHeight: "none",
  },
  "@media (max-width: 479px)": {
    fontSize: "18px",
    lineHeight: "none",
  }
})
const BodySection2 = styled(Box)({
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "18px",
  fontFamily: "roobert-regular",
  color: "#2F2F2F",
  "@media (max-width: 480px)": {
    fontSize: "14px",
  }
})
const StyledImageCard = styled(Card)({
  width: "231px",
  backgroundColor: "#fff",
  borderRadius: "24px",
  webkitBoxShadow: "12px 12px 24px 0px #2F2F2F33",
  boxShadow: "12px 12px 24px 0px #2F2F2F33",
  position: "absolute",
  padding: "16px",
  "& img":{
    height: "100%",
    width: "100%",
    objectFit: "cover",
    userSelect: "none"
  },
  "@media (min-width: 480px) and (max-width: 767px)": {
    width: "220px",
  },
  "@media (min-width: 400px) and (max-width: 479px)": {
    width: "180px",
  },
  "@media (max-width: 399px)": {
    width: "150px",
  }
})
const TitleSection3 = styled(Box)({
  fontWeight: "600",
  fontSize: "36px",
  lineHeight: "40px",
  fontFamily: "roobert-regular",
  color: "#2F2F2F",
  "@media (min-width: 768px) and (max-width: 1023px)": {
    fontSize: "24px",
    lineHeight: "none",
  },
  "@media (min-width: 480px) and (max-width: 767px)": {
    fontSize: "20px",
    lineHeight: "none",
  },
  "@media (max-width: 479px)": {
    fontSize: "18px",
    lineHeight: "none",
  }
})
const BodySection3 = styled(Box)({
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "18px",
  fontFamily: "roobert-regular",
  color: "#2F2F2F",
  "@media (max-width: 480px)": {
    fontSize: "14px",
  }
})
const VideoBoxSection3 = styled(Box)({
  maxWidth: "517px",
  width: "100%",
  height: "520px",
  backgroundColor: "#717C90",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "@media (min-width: 1024px) and (max-width: 1280px)": {
    height: "480px",
  },
  "@media (min-width: 768px) and (max-width: 1023px)": {
    maxWidth: "450px",
    height: "450px",
  },
  "@media (min-width: 480px) and (max-width: 767px)": {
    maxWidth: "400px",
    height: "400px",
  },
  "@media (max-width: 480px)": {
    height: "350px",
  }
})

const CardHeightBox = styled(Box)({
  minHeight: "602px",
  "@media (max-width: 480px)": {
    minHeight: "502px",
  }
})

const CardsWrapperS1 = styled(Box)({
  position: "relative",
  display: "flex",
  "& .card1_s1": {
    zIndex: 3,
    transition: "all 0.8s ease-out",
    height: "470px",
  },
  "& .card2_s1": {
    transition: "all 0.8s ease-out 0.2s",
    top: "22px",
    height: "436px",
    zIndex: 2,
    marginLeft: "26%",
  },
  "& .card3_s1": {
    zIndex: 1,
    transition: "all 0.8s ease-out 0.4s",
    top: "156px",
    height: "167px",
    marginLeft: "52%",
  },
  "@media (max-width: 1024px)": {
    "& .card2_s1": {
      marginLeft: "13%",
    },
    "& .card3_s1": {
      marginLeft: "26%",
    },
  },
  "@media (max-width: 767px)": {
    "& .card3_s1": {
      height: "167px",
    },
    "& .card2_s1": {
      height: "370px",
    },
    "& .card1_s1": {
      height: "420px",
    },
  },
  "@media (max-width: 480px)": {
    "& .card1_s1": {
      height: "370px",
    },
    "& .card2_s1": {
      height: "320px",
    },
    "& .card3_s1": {
      height: "167px",
      top: "100px",
    },
  }
})

const CardsWrapperS2 = styled(Box)({
  position: "relative",
  display: "flex",
  "& .card1_s2": {
    zIndex: 4,
    transition: "all 0.8s ease-out",
    height: "470px",
  },
  "& .card2_s2": {
    zIndex: 3,
    transition: "all 0.8s ease-out 0.2s",
    marginLeft: "25%",
    top: "18px",
    height: "442px",
  },
  "& .card3_s2": {
    zIndex: 2,
    transition: "all 0.8s ease-out 0.4s",
    marginLeft: "45%",
    top: "43px",
    height: "394px",
  },
  "& .card4_s2": {
    zIndex: 1,
    transition: "all 0.8s ease-out 0.6s",
    marginLeft: "65%",
    top: "75px",
    height: "336px",
  },
  "@media (min-width: 1025px) and (max-width: 1280px)": {
    "& .card2_s2": {
      marginLeft: "19%",
    },
    "& .card3_s2": {
      marginLeft: "29%",
    },
    "& .card4_s2": {
      marginLeft: "40%",
    },
  },
  "@media (max-width: 1024px)": {
    "& .card2_s2": {
      marginLeft: "13%",
    },
    "& .card3_s2": {
      marginLeft: "23%",
    },
    "& .card4_s2": {
      marginLeft: "32%",
    },
  },
  "@media (max-width: 767px)": {
    "& .card1_s2": {
      height: "420px",
    },
    "& .card2_s2": {
      height: "392px",
    },
    "& .card3_s2": {
      height: "345px",
    },
    "& .card4_s2": {
      height: "286px",
    },
  },
  "@media (max-width: 480px)": {
    "& .card1_s2": {
      height: "390px",
    },
    "& .card2_s2": {
      height: "360px",
    },
    "& .card3_s2": {
      height: "315px",
    },
    "& .card4_s2": {
      height: "250px",
    },
  }
})

const CardsWrapperS3 = styled(Box)({
  position: "relative",
  display: "flex",
  "& .card1_s3": {
    zIndex: 3,
    height: "470px",
    transition: "all 0.8s ease-out",
  },
  "& .card2_s3": {
    zIndex: 2,
    marginLeft: "26%",
    transition: "all 0.8s ease-out 0.2s",
    top: "33px",
    height: "400px",
  },
  "& .card3_s3": {
    zIndex: 1,
    transition: "all 0.8s ease-out 0.4s",
    marginLeft: "52%",
    top: "167px",
    height: "119px",
  },
  "@media (max-width: 1024px)": {
    "& .card2_s3": {
      marginLeft: "13%",
    },
    "& .card3_s3": {
      marginLeft: "26%",
    },
  },
  "@media (max-width: 767px)": {
    "& .card3_s3": {
      height: "100px",
    },
    "& .card1_s3": {
      height: "420px",
    },
    "& .card2_s3": {
      height: "350px",
    },
  },
  "@media (max-width: 480px)": {
    "& .card1_s3": {
      height: "390px",
    },
    "& .card2_s3": {
      height: "320px",
    },
    "& .card3_s3": {
      height: "90px",
    }
  }
})