import React from "react";
// Customizable Area Start
import NotificationsController, { Props, configJSON } from "./NotificationsController.web";
import Sidebar from "../../../../blocks/settings2/src/components/Sidebar.web";
import { ScreenTitleBox } from "../../../../blocks/utilities/src/ResolveCondition.web";
import { Box, styled, Typography } from '@mui/material';
import SwitchInput from '@mui/material/Switch';
// Customizable Area End

export default class Notifications extends NotificationsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            <>
                <Sidebar props={this.props} selectedScreen={"Notifications"} showSettingsSidebar>
                    <ScreenTitleBox title={configJSON.ENUM.NOTIFICATIONS_SCREEN_TITLE} />
                    <Wrapper>
                        <FlexBox>
                            <ContentBox>
                                <Box>
                                    <Box display={"flex"} justifyContent={"space-between"}>
                                        <MediumFont>{configJSON.ENUM.ACCEPT_PUSH_TITLE}</MediumFont>
                                        <CustomSwitch disableRipple />
                                    </Box>
                                    <SmallFont color={"#717C90"}>
                                        {configJSON.ENUM.ACCEPT_PUSH_NOTIFICATION}
                                    </SmallFont>
                                </Box>
                                <Box marginTop={"24px"}>
                                    <Box display={"flex"} justifyContent={"space-between"}>
                                        <MediumFont>{configJSON.ENUM.ACCEPT_EMAIL_TITLE}</MediumFont>
                                        <CustomSwitch disableRipple />
                                    </Box>
                                    <SmallFont color={"#717C90"}>
                                        {configJSON.ENUM.ACCEPT_EMAIL_NOTIFICATION}
                                    </SmallFont>
                                </Box>
                                <Box marginTop={"32px"}>
                                    <SmallFont color={"#ACACAC"}>
                                        See our <span onClick={() => this.goToScreen("TermsPolicies")} data-testid="terms-and-policy">Privacy Policy</span> for more details.
                                    </SmallFont>
                                </Box>
                            </ContentBox>
                        </FlexBox>
                    </Wrapper>

                </Sidebar>
            </>
        )
    }
}
// Customizable Area Start
const Wrapper = styled(Box)({
    userSelect: "none",
    padding: "48px 32px"
})
const FlexBox = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
})
const ContentBox = styled(Box)({
    maxWidth: "279px",
    width: "100%"
})
const MediumFont = styled(Typography)({
    fontWeight: '600',
    fontSize: "16px",
    lineHeight: "20px",
    fontFamily: "roobert-regular",
    color: "#2F2F2F"
})
const SmallFont = styled(Typography)({
    fontWeight: '600',
    fontSize: "12px",
    lineHeight: "13.18px",
    fontFamily: "roobert-regular",
    "& span": {
        textDecoration: "underline",
        cursor: "pointer"
    }
})
const CustomSwitch = styled(SwitchInput)({
    width: 42,
    height: 24,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: "3 0 3 3",
        transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            margin: "3 0 3 4",
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: '#2F2F2F',
        width: 18,
        height: 18,
    },
    '& .MuiSwitch-track': {
        borderRadius: 24,
        backgroundColor: '#F4F4F4',
        boxShadow: '2.18px 2.18px 6.54px 0px #AEAEC066 inset, -2.18px -2.18px 6.54px 0px #FFFFFF inset',
        opacity: "1 !important",
        '&:before': {
            content: '""',
            position: 'absolute',
            width: '100%',
            height: '100%',
            background: 'transparent',
            borderRadius: 'inherit',
        },
    },
    '& .Mui-checked .MuiSwitch-thumb': {
        backgroundColor: '#fff',
    },

    '& .Mui-checked + .MuiSwitch-track': {
        backgroundColor: 'transparent',
        background: 'linear-gradient(270deg, #FECD8F 0%, #F27C66 100%)',
        boxShadow: '2.18px 2.18px 6.54px 0px #DE6852 inset, -1.5px -1.5px 3px 0px #FECD8F inset',
        opacity: "1 !important",
    },

});
// Customizable Area End
