import React from "react";
// Customizable Area Start
import SetNewPasswordController, { Props, configJSON } from "./SetNewPasswordController.web";
import HeaderSimple from "../../../../blocks/termsconditions/src/components/HeaderSimple.web";
import Footer from "../../../../components/src/Footer.web";
import { CommonButton, CustomAlert, getTruthyString, ResolveCondition } from "../../../../blocks/utilities/src/ResolveCondition.web";
import { Box, Typography, styled, TextField, InputAdornment, IconButton } from '@mui/material';
import { VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import { Formik, Form } from "formik";
// Customizable Area End

export default class SetNewPassword extends SetNewPasswordController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderErrorBox = (error: string | undefined) => {
        return (
            <ErrorBox>
                <Typography>{getTruthyString(error)}</Typography>
            </ErrorBox>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            <>
                <RootWrapper>
                    <HeaderSimple thisprop={this.props} />
                    <ContentWrapper>
                        <LeftBox>
                            <ScreenTitle>Stories that{" "}<span>Matter</span></ScreenTitle>
                            <ScreenSubTitle>{configJSON.ENUM.SUBTITLE}</ScreenSubTitle>
                        </LeftBox>
                        <CenterBox>
                            <Box className="middle-box-wrapper">
                                <BoxTitleFont marginBottom={"48px"} textAlign={"center"}>{configJSON.ENUM.FORGOT_PASSWORD}</BoxTitleFont>
                                <Formik
                                    initialValues={this.initialFormValues}
                                    validationSchema={this.validationSchema}
                                    onSubmit={(values) => { this.setNewPasswordApi(values) }}
                                >
                                    {({ values, errors, handleChange }) => (
                                        <Form>
                                            <Box>
                                                <CustomField
                                                    name="password"
                                                    label="Enter new password"
                                                    value={values.password}
                                                    onChange={handleChange}
                                                    data-testid="password-field"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <StyledIconButton aria-label="toggle-password" onClick={this.togglePassword}>
                                                                    {ResolveCondition(!this.state.showPassword, <CloseEyeIcon />, <OpenEyeIcon />)}
                                                                </StyledIconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}

                                                    type={ResolveCondition(this.state.showPassword, "text", "password")}
                                                />
                                                {this.renderErrorBox(errors.password)}
                                            </Box>
                                            <Box marginTop={"16px"}>
                                                <CustomField
                                                    name="confirm_password"
                                                    label="Confirm password"
                                                    value={values.confirm_password}
                                                    onChange={handleChange}
                                                    data-testid="confirm-password-field"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <StyledIconButton aria-label="toggle-confirm-password" onClick={this.toggleConfirmPassword}>
                                                                    {ResolveCondition(!this.state.showConfirmPassword, <CloseEyeIcon />, <OpenEyeIcon />)}
                                                                </StyledIconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    type={ResolveCondition(this.state.showConfirmPassword, "text", "password")}
                                                />
                                                {this.renderErrorBox(errors.confirm_password)}
                                            </Box>
                                            <Box marginTop={"125px"}>
                                                <StyledButton
                                                    loadingStyle={loadingStyle}
                                                    variant="contained"
                                                    type="submit"
                                                    label="Create"
                                                    isLoading={this.state.apiLoading}
                                                />
                                            </Box>
                                        </Form>
                                    )}
                                </Formik>
                            </Box>
                        </CenterBox>
                        <RightBox sx={{ flex: 1 }} />

                    </ContentWrapper>
                    <Footer prop={this.props} />
                    <CustomAlert
                        open={!!this.state.alertMsg}
                        message={this.state.alertMsg}
                        handleClose={() => this.toggleAlert("")}
                    />
                </RootWrapper>
            </>
        )
    }
}
// Customizable Area Start
const RootWrapper = styled(Box)({
    background: "#F4F4F4",
});
const ContentWrapper = styled(Box)({
    display: "flex",
    minHeight: "calc(100vh - 145px)",
    gap: "50px",
    padding: "76px 88px",
    "@media (min-width: 1024px) and (max-width: 1439px)": {
        padding: "66px",
    },
    "@media (min-width: 768px) and (max-width: 1023px)": {
        padding: "48px",
    },
    "@media (min-width: 480px) and (max-width: 767px)": {
        padding: "32px",
    },
    "@media (max-width: 1080px)": {
        flexWrap: "wrap",
    },
    "@media (max-width: 479px)": {
        padding: "24px",
    },
    "@media (max-width: 600px)": {
        flexDirection: "column"
    },
})
const LeftBox = styled(Box)({
    flex: 1,
    minWidth: "279px",
    height: "100%",
    flexDirection: "column",
    display: "flex",
    padding: "72px 0",
    "@media (max-width: 671px)": {
        minWidth: "unset",
        padding: "12px 0 48px 0",
    }
})
const RightBox = styled(Box)({
    flex: 1,
    "@media (min-width: 1024px) and (max-width: 1280px)": {
        flex: 0.5,
    },
    "@media (max-width: 1023px)": {
        display: "none"
    }
})
const CenterBox = styled(Box)({
    flex: 1,
    display: "flex",
    justifyContent: "center",
    "& .middle-box-wrapper": {
        maxWidth: "279px",
        minWidth: "279px",
        "@media (max-width: 480px)": {
            width: "100%",
            minWidth: "unset",
        },
    }
})
const ScreenTitle = styled(Typography)({
    color: "#2F2F2F",
    fontWeight: "600",
    fontSize: "30px",
    lineHeight: "32px",
    fontFamily: 'roobert-regular',
    "& span": {
        background: "linear-gradient(308.32deg, #F27C66 32.08%, #FECD8F 55.96%)",
        backgroundClip: "text",
        WebkitBackgroundClip: "text",
        fontFamily: "olten",
        color: "transparent",
        fontSize: "30px",
        lineHeight: "36px",
        "@media (max-width: 480px)": {
            fontSize: "24px",
        },
    },
    "@media (max-width: 480px)": {
        fontSize: "24px",
    },
})
const ScreenSubTitle = styled(Typography)({
    color: "#2F2F2F",
    maxWidth: "240px",
    fontFamily: "roobert-regular",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "15.37px",
    marginTop: "16px",
})
const BoxTitleFont = styled(Typography)({
    color: "#2F2F2F",
    fontSize: "20px",
    lineHeight: "24px",
    fontWeight: "600",
    fontFamily: "roobert-regular"
})
const CustomField = styled(TextField)({
    boxShadow: "2.18px 2.18px 6.54px 0px #AEAEC066 inset, -2.18px -2.18px 6.54px 0px #FFFFFF inset",
    height: "48px",
    background: "#F4F4F4",
    borderRadius: "24px",
    width: "100%",
    "& .MuiOutlinedInput-root": {
        borderRadius: "24px",
        color: "#2F2F2F",
        lineHeight: "20px",
        fontSize: "20px",
        fontWeight: "600",
        fontFamily: "roobert-regular",
        "&.Mui-focused fieldset": {
            border: "none",
        },
        "& fieldset": {
            border: "none",
        },
        "&:hover fieldset": {
            border: "none",
        },
    },
    "& .MuiInputLabel-root": {
        top: -3,
        fontFamily: "roobert-regular",
        color: "#ACACAC",
        fontSize: "14px",
        fontWeight: "600",
        "&.Mui-focused": {
            opacity: 0,
        },
    },
    "& .MuiInputLabel-root-MuiInputLabel-root.Mui-focused": {
        visibility: "hidden",
    },
    "& .MuiInputLabel-shrink": {
        visibility: "hidden",
        transform: "translate(14px, -6px) scale(0.85)",
    },
    "& .MuiInputBase-input": {
        fontWeight: "600",
        boxSizing: "border-box",
        display: "flex",
        padding: "12px 16px",
        height: "48px",
        alignItems: "center",
        fontSize: "16px",
        lineHeight: "19.96px",
    },
})
const StyledIconButton = styled(IconButton)({
    padding: 0,
    marginRight: "2px",
    color: '#ACACAC'
})
const OpenEyeIcon = styled(VisibilityOutlined)({
    height: "18px",
    width: "18px",
    color: "#ACACAC"
})
const CloseEyeIcon = styled(VisibilityOffOutlined)({
    height: "18px",
    width: "18px",
    color: "#ACACAC"
})
const StyledButton = styled(CommonButton)({
    width: "100%",
    height: "48px",
    textTransform: 'none',
    backgroundColor: "#F4F4F4",
    color: "#2F2F2F",
    padding: "10px 20px",
    borderRadius: "30px",
    boxShadow: "2.18px 2.18px 6.54px 0px #AEAEC066, -2.18px -2.18px 6.54px 0px #FFFFFF",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    fontFamily: "roobert-regular",
    fontWeight: '600',
    fontSize: "16px",
    "&:hover": {
        backgroundColor: "#2F2F2F",
        color: "#fff",
        "& .MuiSvgIcon-root": {
            color: "#FFFFFF",
        },
    }
})
const ErrorBox = styled(Box)({
    padding: "8px 13px 0 16px",
    "& p": {
        color: "#EA4335",
        fontSize: "12px",
        fontWeight: "400",
        lineHeight: "13.18px",
        fontFamily: "roobert-regular"
    }
})
const loadingStyle = {
    color: "#2F2F2F",
    height: "24px",
    width: "24px"
} as React.CSSProperties
// Customizable Area End
