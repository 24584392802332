Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "settings2";
exports.labelBodyText = "settings2 Body";

exports.btnExampleTitle = "CLICK ME";

exports.ENUM = {
  QR_TEXT: "This platform is primarily designed for mobile use. Download the app to access powerful creation features",
  COPYRIGHT_TEXT: "© 2025 OffSpace, All Rights Reserved",
  SECONDRY_SIDEBAR_TITLE: "Settings & Privacy",
  NOTIFICATIONS_SCREEN_TITLE: "Notification",
  ACCEPT_PUSH_TITLE: "Push notifications",
  ACCEPT_PUSH_NOTIFICATION: "I accept to receive Push notifications",
  ACCEPT_EMAIL_TITLE: "Email notifications",
  ACCEPT_EMAIL_NOTIFICATION: "I accept to receive Email notifications",
  LOGOUT_SCREEN_TITLE: "Logout",
  LOGOUT_DESCRIPTION: "Your login info will be saved, so you won’t need to enter it again next time you log in.",
  SEE_YOU_SOON: "See you soon!",
  TERMS_POLICY_SCREEN_TITLE: "Terms & Policies",
  SETTING_REDIRECTION: "AccountSettings"
}

exports.ENDPOINTS = {
  GET_TERMS_POLICY: "bx_block_terms_and_conditions/terms_and_conditions"
}
exports.API_METHOD = {
  GET: "GET",
  POST: "POST",
  DELETE: "DELETE",
  PATCH: "PATCH",
  PUT: "PUT"
}
// Customizable Area End