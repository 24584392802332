// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import { getTruthyString } from "../../../../blocks/utilities/src/ResolveCondition.web";

export const configJSON = require("../config");

export interface Props {
    navigation: any;
    id: string;
}

interface S {
    token: string,
    blockedUsersList: IBlockUser[]
    getUsersListLoading: boolean,
    unBlockUserToBe: IBlockUser | null
}

interface SS { }
interface IBlockUser {
    id: number
    account_id: null | string
    reason: null | string
    blocker_id: number
    blockee_id: number
    admin_blacklist: boolean
    blockee_account: IBlockeeAccount
}

interface IBlockeeAccount {
    id: number
    first_name: string
    last_name: string
    email: string
    user_name: string
    gender: null | string
}
interface IUnBlockResp {
    message: string
}
// Customizable Area End

export default class BlockedUsersListController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getUserListApiCallId: string = ""
    unBlockUserApiCallId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceDataMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        ];

        this.state = {
            token: getTruthyString(localStorage.getItem("token")),
            blockedUsersList: [],
            getUsersListLoading: false,
            unBlockUserToBe: null
        }
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId && responseJson) {
                if (apiRequestCallId == this.getUserListApiCallId) {
                    this.getUserListApiResp(responseJson)
                }
                if (apiRequestCallId == this.unBlockUserApiCallId) {
                    this.unBlockUserApiResp(responseJson)
                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    getUserListApiResp = (responseJson: IBlockUser[]) => {
        this.setState({ getUsersListLoading: false })
        if (responseJson.length) {
            this.setState({
                blockedUsersList: responseJson
            })
        } else {
            this.setState({
                blockedUsersList: []
            })
        }
    }
    unBlockUserApiResp = (responseJson: IUnBlockResp) => {
        if (responseJson.message) {
            this.getUsersList()
            this.setState({ unBlockUserToBe: null })
        }
    }

    async componentDidMount() {
        this.getUsersList()
    }

    getUsersList = () => {
        this.setState({ getUsersListLoading: true })

        const header = {
            token: this.state.token
        }

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getUserListApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.ENDPOINTS.GET_BLOCK_USER_LIST);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleUnBlock = (user: IBlockUser) => {
        this.setState({
            unBlockUserToBe: user
        })

        const header = {
            token: this.state.token
        }

        const endpoint = `${configJSON.ENDPOINTS.GET_BLOCK_USER_LIST}/${user?.blockee_id}`

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.unBlockUserApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "DELETE");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End
}